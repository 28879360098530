import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchWorks from "./landingPages/components/SearchWorks";

const ConsultWorks = () => {
  const { t } = useTranslation();
  const [classTable, setClassTable] = useState("");

  return (
    <section>
      <div className={"card-body pt-0"}>
        <SearchWorks loggin={true} setClass={setClassTable} />
      </div>
    </section>
  );
};

export default ConsultWorks;
