import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import { Button, Card, Modal, Form } from "react-bootstrap";

import { Box, Flex } from "reflexbox";
import styled from "styled-components";
import { toast } from "react-toastify";
import CardItem from "../../common/CardItem.jsx";
import useAppContext from "../../data/store";

const EdicionCategoriasListItem = ({ item, isActive, doGetData }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [{ user }] = useAppContext();
  const [isAdmin, setIsAdmin] = useState(false);

  const [modalText, setModalText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseDelete = () => setShowDelete(false);

  useEffect(() => {
    const role =
      !_.isNull(localStorage.getItem("jdo-userData")) &&
      JSON.parse(localStorage.getItem("jdo-userData")).role.name;
    const isAdmin = (role && role === "Administrador") || user.isAdmin;
    setIsAdmin(isAdmin);
  }, []);

  const handleDelete = () => {
    // setShowInfo(false);
    setShowDelete(true);
  };

  const handleShowName = () => {
    setModalText(item.nombre);
    handleShow();
  };
  const handleSave = async (id) => {
    const token = localStorage.getItem("jdo-userToken");

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/categorias/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },

          method: "PUT",
          body: JSON.stringify({ nombre: modalText }),
        }
      );
      if (res.status !== 200) {
        throw Error("Forbidden");
      }

      doGetData();
    } catch (err) {
      toast.error(`${t("error")}`);
    }

    handleClose();
  };

  const handleDeleteCategoria = async (id) => {
    const token = localStorage.getItem("jdo-userToken");

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/categorias/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },

          method: "DELETE",
        }
      );
      if (res.status !== 200) {
        throw Error("Forbidden");
      }

      doGetData();
    } catch (err) {
      toast.error(`${t("error")}`);
    }

    handleCloseDelete();
  };

  return (
    <>
      <Card style={{ padding: "20px" }}>
        <div className="containers">
          <div className="row">
            <div className="col">
              <>
                <Flex width="100%" alignItems="justify-content">
                  <Box className="col" flex="1">
                    <CardItem
                      valor={item.nombre || "-"}
                      etiqueta={t("nombre")}
                    />
                  </Box>
                  <Box>
                    <Button m="1" onClick={handleShowName}>
                      {t("editar")}
                    </Button>
                    <Button
                      m="1"
                      variant="danger"
                      onClick={() => handleDelete(item.id)}
                    >
                      {t("borrar")}
                    </Button>
                  </Box>
                </Flex>
              </>
            </div>
          </div>
        </div>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("editar_categoria")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="nombre">
            <Form.Control
              name="nombre"
              type="text"
              value={modalText || ""}
              onChange={(event) => setModalText(event.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("cerrar")}
          </Button>
          <Button variant="primary" onClick={() => handleSave(item.id)}>
            {t("guardar_cambios")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>{t("borrar_categoria")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("confirmar_borrar") + " " + item.nombre}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            {t("cerrar")}
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteCategoria(item.id)}
          >
            {t("borrar")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const StyledH6 = styled.h6`
  font-size: 16px;
  width: 20%;
  height: 50px;
  padding: 5px;
  font-weight: 300 !important;
  color: black;
`;
const StyledH6Name = styled.h6`
  font-size: 16px;
  width: 20%;
  height: 50px;
  padding: 5px;
`;

const InLineH6 = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default EdicionCategoriasListItem;
