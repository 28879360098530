export const theme = {
  colors: {
    primary: "#2c3684",
    secondary: "#8d8c94",
    success: "#8ec2ec",
    danger: "#9b3914",
    light: "#e8e8eb",
    clear: "transparent",
    white: "#fff",
    black: "#000",
    grey: "#bbb",
    palegrey: "#eee",
  },
  variants: {},
  buttons: {
    primary: {
      bg: "primary",
      color: "white",
    },
    secondary: {
      bg: "secondary",
      color: "white",
    },
    danger: {
      bg: "danger",
      color: "white",
    },
    link: {
      bg: "clear",
      color: "primary",
    },
  },
};
