import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getToken } from "../../logic/logic";

import { Card } from "rebass/styled-components";
import { Flex, Box } from "reflexbox";

import Pagination from "../../common/Pagination";

import MisProvedoresListItem from "./MisProveedoresListItem";

import SearchBar from "../../common/SearchBar";

const MisProvedoresList = ({ isLogged }) => {
  const { t } = useTranslation();
  const [datos, setDatos] = useState([]);
  const [count, setCount] = useState(0);
  const [medida, setMedida] = useState(10);
  const [pagina, setPagina] = useState(0);
  const [orden, setOrden] = useState("proveedor");

  useEffect(() => {
    getData("", pagina, medida);
  }, [orden]);

  const getData = async (search, page, size) => {
    try {
      const busca = search ? search : "";
      const start = page * size;
      setPagina(page);
      setMedida(size);
      getToken();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/proveedors/me/search?_start=${start}&_limit=${size}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ busca, orden }),
        }
      );
      if (res.status !== 200) {
        throw Error("Bad request");
      }
      const data = await res.json();
      setCount(data.total);
      setDatos(data.items);
    } catch (err) {
      console.log("err :>> ", err);
    }
  };

  // ************************** TABLE PARAMS **************************

  // ************************** FUNCTIONS **************************

  const handlePaginateClick = (data) => {
    const { currentPage, pageLimit, totalPages, totalRecords } = data;
    getData("", currentPage, pageLimit);
    setMedida(pageLimit);
    setPagina(currentPage);
  };

  const handleSearch = (busca) => {
    if (busca) {
      getData(busca, pagina, medida);
    } else {
      getData("", pagina, medida);
    }
  };

  if (!datos) {
    return <div>Loading...</div>;
  }

  if (datos) {
    return (
      <>
        <SearchBar doSearch={(busca) => handleSearch(busca)} />
        <div className="containers">
          <Flex>
            <Box
              mr={2}
              mb={2}
              bg="#eee"
              p={2}
              flex="4"
              style={{ cursor: "pointer" }}
            >
              <div
                style={{ width: "100%", textAlign: "left" }}
                onClick={() => setOrden("proveedor")}
              >
                {t("proveedor")}
              </div>
            </Box>
            <Box
              mr={2}
              mb={2}
              bg="#eee"
              p={2}
              flex="2"
              style={{ cursor: "pointer" }}
            >
              <div
                style={{ width: "100%", textAlign: "left" }}
                onClick={() => setOrden("obra")}
              >
                {t("obra")}
              </div>
            </Box>
            <Box
              mr={2}
              mb={2}
              bg="#eee"
              p={2}
              flex="2"
              style={{ cursor: "pointer" }}
            >
              <div
                style={{ width: "100%", textAlign: "left" }}
                onClick={() => setOrden("categoria")}
              >
                {t("categoria")}
              </div>
            </Box>
            <Box
              mr={2}
              mb={2}
              bg="#eee"
              p={2}
              flex="1"
              style={{ cursor: "pointer" }}
            >
              <div
                style={{ width: "100%", textAlign: "left" }}
                onClick={() => setOrden("valoracion")}
              >
                {t("valoracion")}
              </div>
            </Box>
            <Box mr={2} mb={2} p={2} flex="2">
              <div style={{ width: "100%", textAlign: "left" }}>&nbsp;</div>
            </Box>
          </Flex>
        </div>

        <Card variant="sombra" width="100%">
          {datos.map((d, index) => (
            <MisProvedoresListItem key={index} item={d} />
          ))}
          {/* <Paginate count={count} size={medida} doClick={handlePaginateClick} /> */}
          <Flex justifyContent="flex-end">
            <Box my={4}>
              <Pagination
                totalRecords={count}
                pageLimit={medida}
                pageNeighbours={2}
                onPageChanged={handlePaginateClick}
              />
            </Box>
          </Flex>
        </Card>
      </>
    );
  }
};

export default MisProvedoresList;
