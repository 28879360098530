import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

import { useHistory } from "react-router-dom";

import { Row, Col, Container } from 'react-bootstrap';

import CorreoOfertaForm from './CorreoOfertaForm';

import { useParams } from 'react-router-dom';

const CorreoOferta = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const [correoOferta, setCorreoOferta] = useState(null);
  const params = useParams();
  const { id } = params;
  const statusId = id;

  const getCorreoOferta = async () => {

    const URL = process.env.REACT_APP_API_URL;
    const user =
      !_.isNull(localStorage.getItem('jdo-userData')) &&
      JSON.parse(localStorage.getItem('jdo-userData')).id;

    let byStatus = id ? `&state_work=${id}` : '';
    try {
      const res = await fetch(`${URL}/correo-para-ofertas/?users_permissions_user=${user}${byStatus}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jdo-userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      if (!res.ok) {
        toast.error(`${t('login_error')}`);
      } else {
        const response = await res.json();

        if (response.length > 0) {
          setCorreoOferta(response[0]);
          return response[0];
        } else {
          setCorreoOferta(null);
          return {
            id: 0,
            contents: `<p>Buenos días:</p> <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estamos trabajando en la ejecució de la obra {{Title}} en {{Province}} y nos disponemos a iniciar los trabajos de contratación del capítulo de {{ChapterName}}.</p> <p>Les enviamos las mediciones y epígrafes con la intención de solicitarles su mejor oferta.</p> <p>Si necesitan información adicional, como mediciones desglosadas o planos, no dude en contactar con nosotros.</p> <p>Dirección de la obra: {{Address}}</p> <p></p> <p>Muchas gracias</p> <p>Un saludo</p> <p>&nbsp;</p>`,
          }
        }

      }
    } catch (error) {
      toast.error(`${t('login_error')}`);
    }
  };

  const handleSave = async (newData) => {

    const id = correoOferta?.id ?? false;

    const user =
      !_.isNull(localStorage.getItem('jdo-userData')) &&
      JSON.parse(localStorage.getItem('jdo-userData')).id;;

    newData.users_permissions_user = user;
    newData.state_work = statusId;

    if (id && id !== 0) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/correo-para-ofertas/${correoOferta.id}`,
          {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jdo-userToken')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newData),
          }
        );

        if (res.status !== 200) {
          throw Error('Bad request');
        }
        await res.json();
        toast.info('Operación exitosa');

        setTimeout(
          () =>
            history.location.pathname.includes("estudios")
              ? history.goBack()
              : history.goBack(),
          500
        );

      } catch (err) {
        toast.error(`${t('error')}`);
      }
    } else {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/correo-para-ofertas`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jdo-userToken')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newData),
          }
        );

        if (res.status !== 200) {
          throw Error('Bad request');
        }
        await res.json();
        toast.info('Operación exitosa');

        setTimeout(
          () =>
            history.location.pathname.includes("estudios")
              ? history.goBack()
              : history.goBack(),
            500
        );

      } catch (err) {
        toast.error(`${t('error')}`);
      }
    }
  };

  return (
    <section className={"buscador add-footer"}>
      <Container>
        <Row className={"mt-5 justify-content-center"}>
          <Col xxl={"10"} xl={"10"} lg={"10"} md={"11"} sm={"6"} xs={"11"}>
            <div className="card mb-3">
              <div className="card-body">
                <div className="card-text">
                  <h5 className={"text-center"}>Correo Oferta</h5>
                  <CorreoOfertaForm getInfo={getCorreoOferta} doSave={handleSave} item={correoOferta} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CorreoOferta;
