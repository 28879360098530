import React from "react";
import { Col, Container, Modal, Row, Form } from "react-bootstrap";
import { Button } from "../../components";

export const ModalOfEInforma = (props) => {
  const { show, doClose, datos, fullscreen, categoria, title } = props;

  return (
    <Modal show={show} fullscreen="true" onHide={doClose}>
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body className="modal-dialog-scrollable">
        <Container>
          <Row>
            <Col xs={12}>
              <div className={"card p-3"}>
                <div className={"card-body"}>
                  <div>
                    {datos.length > 0 ? (
                      <>
                        {datos.map((datos) => (
                          <div
                            className="divider-custom"
                            onClick={() =>
                              window.open(
                                `/full/edit/${
                                  datos.id || datos.identificativo
                                }/${categoria}`
                              )
                            }
                            key={datos.id || datos.identificativo}
                          >
                            <div className="divider-custom-line"></div>
                            {datos.denominacion} -{" "}
                            {datos.provincia || datos.localidad}
                          </div>
                        ))}
                      </>
                    ) : (
                      "No encontrado"
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className={"card-footer pt-0"}>
          <div className={"d-flex justify-content-around"}>
            <Button text="Cancelar" className="btn-danger" onClick={doClose} />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
