import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { Box, Flex } from "reflexbox";
import { toast } from "react-toastify";
import useAppContext from "../../../data/store";
import Pagination from "../../../common/Pagination";
import { Button, Select, Input } from "../../../components";
import { getProvincias } from "../../../data/api/common";
import { SIZE_PAGE } from "../../../logic/queryStrings";
import ValoracionScore from "../../../common/ValoracionScore";
import DeleteModal from "../../../common/DeleteModal";
import { getEInformaToken } from "../../../logic/einforma";
import { getStatusWork } from "../../../components/obras/status.work.service";

const SearchWorks = ({ loggin, setClass = () => {} }) => {
  const [{ user }] = useAppContext();
  const [data, setData] = useState([]);
  const [dataEInforma, setDataEInforma] = useState([]);
  const [total, setTotal] = useState(null);
  const [classTable, setClassTable] = useState("");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(null);
  const [isLogin, setIsLogin] = useState(null);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const { register, handleSubmit, control, reset, getValues, setValue } =
    useForm();
  const { t } = useTranslation();
  const btnSearchRef = useRef();
  const [inputs, setInputs] = useState([]);

  const [provinces, setProvinces] = useState([]);
  const provinceRef = useRef();
  const pemRef = useRef();
  useEffect(() => {
    const role =
      !_.isNull(localStorage.getItem("jdo-userData")) &&
      JSON.parse(localStorage.getItem("jdo-userData")).role.name;
    const isAdmin = (role && role === "Administrador") || user.isAdmin;
    setIsLogin(role);
    setRole(isAdmin);
    let isMounted = true;
    async function getSelects() {
      const provincesList = await getProvincias();
      // aqui pone la lista de el input console.log(provincesList)
      setProvinces(provincesList[0]);
    }
    getSelects();

    return () => {
      isMounted = false;
    };
  }, []);

  const getData = async ({
    builder,
    property,
    province,
    projectManagement,
    pem,
    workName,
  }) => {
    setLoading(true);
    const status = await getStatusWork();
    const state = status.filter((element) => element.type === "en-curso")[0];
    try {
      setLoading(true);
      const token = localStorage.getItem("jdo-userToken");
      let headers =  {
        "Content-Type": "application/json",
      };

      if (token !== undefined && token !== null) {
        headers =  {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/obras/search-works`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            search: builder?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            property: property?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            province: province?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            projectManagement: projectManagement?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            pem,
            workName: workName?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            status: `${state.id}`,
            _start: 0,
            _limit: -1,
          }),
        }
      );
      if (res.status !== 200) {
        throw Error("Bad request");
      }
      const data = await res.json();
      setClassTable("con-tabla");
      setClass("con-tabla");
      setData(data.items);
      setTotal(data.total);
    } catch (err) {
      console.log("err :>> ", err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleInputs = (event) => {
    let value = event?.target.value;
    let changeValue = value?.toLowerCase();
    let name = event?.target?.name;
    setInputs({ ...inputs, [name]: changeValue });
  };
  const filter = () => {
    let dataFiltered = data;
    dataFiltered = dataFiltered?.filter((d) => d.visible === true);

    return dataFiltered;
  };
  const onSubmit = async (data) => {
    const {
      builder,
      property,
      province,
      projectManagement,
      pem,
      workName,
    } = data;
    await getData({
      builder,
      property,
      province,
      projectManagement,
      pem,
      workName,
    });
    getValues();
  };

  const handlePageChanged = async ({ currentPage }) => {
    const { builder, property, province, projectManagement, pem, workName } =
      getValues();
    await getData({
      builder,
      property,
      province,
      projectManagement,
      pem,
      workName,
      page: currentPage,
    });
  };

  const handleClose = () => setShow(false);

  const checkKeyDown = (e) => {
    const keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 13) {
      e.target.blur();
      if (btnSearchRef.current) {
        btnSearchRef.current.click();
      }
    }
  };

  const redirectDetail = (id) => isLogin && window.open(`/estudios/view/${id}`);
  let className = "";
  if (!loggin) {
    className = "middle-search";
  }

  const selectPem = [
    { label: "<1.000", value: "<1000" },
    { label: "<10.000", value: "<10000" },
    { label: "<100.000", value: "<100000" },
    { label: "<1.000.000", value: "<1000000" },
    { label: ">1.000.000", value: ">1000000" },
    { label: ">100.000", value: ">100000" },
    { label: ">10.000", value: ">10000" },
    { label: ">1.000", value: ">1000" },
  ];

  const processNumber = (value) => {
    let stringValue = value;
    stringValue = Number.parseFloat(stringValue).toFixed(2);
    stringValue = Intl.NumberFormat('en-US').format(stringValue)
    stringValue = stringValue.replaceAll('.', '-');
    stringValue = stringValue.replaceAll(',', '.');
    stringValue = stringValue.replaceAll('-', ',');
    // stringValue = stringValue.replaceAll(',', '.');
    // stringValue = stringValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return stringValue;
  }

  return (
    <>
      <section
        className={`buscador ${classTable} ${loggin === true && "false"}`}
      >
        <Container>
          <Row>
            <Col xs={12} className="content-search align-middle">
              <div className={className}>
                <div className="card p-3">
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      onKeyDown={(e) => checkKeyDown(e)}
                    >
                      <h2
                        className={
                          "card-title" + (loggin === true && " visually-hidden")
                        }
                      >
                        Buscador de empresas subcontratistas y proveedores de
                        construcción.
                      </h2>
                      <div className="">
                        <h5
                          className={"my-3"}
                          style={{ fontSize: "1.8rem", color: "#113987" }}
                        >
                          Listado de obras en curso
                        </h5>
                      </div>
                      <div className="row g-2 my-4">
                        <div className="col-md-4">
                          <Input
                            className="form-control"
                            placeholder={t("builder")}
                            register={register}
                            name={"builder"}
                            onChange={handleInputs}
                          />
                        </div>
                        <div className="col-md-4">
                          <Input
                            className="form-control"
                            placeholder={t("property")}
                            register={register}
                            name={"property"}
                            onChange={handleInputs}
                          />
                        </div>
                        <div className="col-md-4">
                          <Controller
                            control={control}
                            name="province"
                            render={({ field }) => (
                              <Select
                                {...field}
                                onChange={(e) => {
                                    setValue('province', e);
                                    handleInputs({
                                      target: {
                                        value: e?.value,
                                        name: "province",
                                      },
                                    })
                                  }
                                }
                                placeholder={t("province")}
                                options={provinces}
                                isDisabled={provinces.length === 0}
                                ref={provinceRef}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <Input
                            className="form-control"
                            placeholder={t("projectManagement")}
                            register={register}
                            name={"projectManagement"}
                            onChange={handleInputs}
                          />
                        </div>
                        <div className="col-md-4">
                          <Controller
                            control={control}
                            name="pem"
                            render={({ field }) => (
                              <Select
                                {...field}
                                style={{ fontSize: "0.8rem" }}
                                onChange={(e) => {
                                  setValue('pem', e?.value);
                                  handleInputs({
                                    target: {
                                      value: e?.value,
                                      name: "pem",
                                    },
                                  });
                                }}
                                placeholder={t("pemRange")}
                                options={selectPem}
                                isDisabled={selectPem.length === 0}
                                ref={pemRef}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <Input
                            className="form-control"
                            placeholder={t("workName")}
                            register={register}
                            name={"workName"}
                            onChange={handleInputs}
                          />
                        </div>
                      </div>
                      <div className="d-flex mt-1 justify-content-end">
                        <Button
                          text={"Limpiar"}
                          className={"btn-secondary"}
                          icon={{ prefix: "fas", iconName: "eraser" }}
                          mx={"mx-2"}
                          type="button"
                          onClick={() => {
                            reset();
                            setData([]);
                            setDataEInforma([]);
                            setTotal(null);
                            setClassTable("");
                            setClass("");
                            setInputs([]);
                            provinceRef.current.select.clearValue();
                            pemRef.current.select.clearValue();
                          }}
                          loading={loading}
                          loadingText={""}
                        />
                        <Button
                          text={"Buscar"}
                          className={"btn-primary"}
                          icon={{ prefix: "fas", iconName: "search" }}
                          type={"submit"}
                          loading={loading}
                          loadingText={"Buscando"}
                          btnRef={btnSearchRef}
                        />
                      </div>
                    </form>
                    {total === 0 && data.length === 0 ? (
                      <>
                        <Col xs={12}>
                          <b>Valor invalido</b> o <b>CIF</b>
                        </Col>
                      </>
                    ) : total > 0 ? (
                      <>
                        <h5 className={"mt-4 mb-2"}>
                          Resultado de la busqueda
                        </h5>
                        <div className="table-responsive">
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr
                                style={{
                                  background: "#84858a",
                                  color: "#fff",
                                  fontSize: "0.8rem",
                                }}
                              >
                                <th>{t("workName")}</th>
                                <th>{t("province")}</th>
                                <th>{t("builder")}</th>
                                <th>{t("property")}</th>
                                <th>{t("projectManagement")}</th>
                                <th>{t("pem")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filter().map((d) => {
                                return (
                                  <tr key={d.id}>
                                    <td onClick={() => redirectDetail(d.id)}>
                                      {d.titulo}
                                    </td>
                                    <td onClick={() => redirectDetail(d.id)}>
                                      {d.provincia}
                                    </td>
                                    <td onClick={() => redirectDetail(d.id)}>
                                      {d.constructora}
                                    </td>
                                    <td onClick={() => redirectDetail(d.id)}>
                                      {d.propiedad}
                                    </td>
                                    <td onClick={() => redirectDetail(d.id)}>
                                      {d.direccion_facultativa}
                                    </td>
                                    <td onClick={() => redirectDetail(d.id)}>
                                      {processNumber(d.pem)}
                                    </td>
                                    {isLogin && role && (
                                      <td>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <div className={"my-2 d-flex justify-content-end"}>
                          <Pagination
                            initialPage={0}
                            totalRecords={+total}
                            pageLimit={SIZE_PAGE}
                            pageNeighbours={2}
                            onPageChanged={(e) => {
                              handlePageChanged(e);
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SearchWorks;
