import React from 'react';
import Buscador from "./components/Buscador";
import Registro from "./components/Registro";
import Partners from "./components/Partners";
import Contacto from "./components/Contacto";
import Enlaces from "./components/Enlaces";

const Home = () => {
  return (
    <section className='panel-home index-1'>
      <Buscador />
      <Registro />
      <Partners />
      <Enlaces />
      <Contacto />
    </section>
  );
};

export default Home;
