import React, { useState } from 'react';
import { Button } from "../index";
import { Row, Col, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Error from "../../common/Error";

const FullProviderCommentsForm = (props) => {

  const {item, handleSave, handleShowFormComments, handleGetListComment} = props;

  let initial = {
    date: new Date().toISOString().slice(0, 10),
    message: '',
  }

  const validation = Yup.object().shape({
    date: Yup.string().typeError("valor-texto").required("obligatorio"),
    message: Yup.string().typeError("valor-texto").required("obligatorio"),
  });

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (data) => {
    handleSave(data);
    handleShowFormComments(false);
    handleGetListComment();
  }

  if (!isLoading) {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={item?.id ? item : initial}
          validationSchema={validation}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({
            setFieldValue,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            isValid,
            isSubmiting,
          }) => {
            return (
              <Form
                onSubmit={handleSubmit}
              >
                <Row className={"justify-content-center"}>
                  <Col xxl={"12"} xl={"12"} lg={"12"} md={"12"} sm={"12"} xs={"12"} className="padding-0">
                    <Form.Group controlId="date" className='margin-0'>
                      <Error touched={touched.date}
                        message={errors.date}/>
                      <Form.Control
                        name="date"
                        type="date"
                        value={values.date || ''}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className={"justify-content-center"}>
                  <Col xxl={"12"} xl={"12"} lg={"12"} md={"12"} sm={"12"} xs={"12"} className="padding-0">
                    <Form.Group controlId="message" className='margin-0'>
                      <Error touched={touched.message}
                        message={errors.message}/>
                      <Form.Control
                        name="message"
                        type="text"
                        value={values.message || ""}
                        onChange={handleChange}
                        as="textarea"
                        placeholder={t('comment')}
                        style={{ height: '100px' }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='justify-content-end' style={{padding: '0rem .4rem!important'}}>
                <Col xxl={"2"} xl={"2"} lg={"2"} md={"2"} sm={"2"} xs={"2"} style={{padding: '.2rem'}} className="display-flex align-content-center justify-content-end">
                    <Button
                      className="btn-danger padding-save-button"
                      disabled={isLoading}
                      icon={{prefix: "fas", iconName: "times"}}
                      mainClass='display-flex align-content-center'
                      onClick={() => handleShowFormComments(false)}
                      px={80}
                      text=''
                      type="button"
                      variant="primary"
                      />
                  </Col>
                  <Col xxl={"2"} xl={"2"} lg={"2"} md={"2"} sm={"2"} xs={"2"} style={{padding: '.2rem'}} className="display-flex align-content-center justify-content-end">
                    <Button
                      className="btn-primary padding-save-button"
                      disabled={isLoading}
                      icon={{prefix: "fas", iconName: "save"}}
                      mainClass='display-flex align-content-center'
                      px={80}
                      text=''
                      type="submit"
                      variant="primary"
                    />
                  </Col>
                </Row>
              </Form>
            );
          }
        }
        </Formik>
      </>
    )
  }
}

export default FullProviderCommentsForm;
