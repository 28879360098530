import logic from "./logic";

const appServices = {
  getComunidads() {
    const method = "GET";
    return logic._query(`comunidads/?_limit=-1`, method);
  },
  getProvincias(comunidad) {
    const method = "GET";
    return logic._query(
      `provincias/?comunidad_eq=${comunidad}&_limit=-1`,
      method
    );
  },
  getMunicipios(provincia) {
    const method = "GET";
    return logic._query(
      `municipios/?provincia_eq=${provincia}&_limit=-1&_sort=nombre:asc`,
      method
    );
  },
  getProveedoresCategoria(categoria) {
    const method = "GET";
    return logic._query(
      `proveedors/?categorias_eq=${categoria}&_limit=-1&_sort=nombre:asc`,
      method
    );
  },
  getProveedores() {
    const method = "GET";
    return logic._query(`proveedors/?_limit=-1&_sort=nombre:asc`, method);
  },
  getProveedoresAsync(busca) {
    const method = "GET";
    return logic._query(
      `proveedors/?_limit=-1&_sort=nombre:asc&nombre_contains=${busca}`,
      method
    );
  },
  getCategorias() {
    const method = "GET";
    return logic._query(`categorias/?_limit=-1&_sort=nombre:asc`, method);
  },
  getTipoProveedores() {
    const method = "GET";
    return logic._query(`tipo-proveedors/?_limit=-1`, method);
  },
};

export default appServices;
