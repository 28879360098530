import React from 'react';
import Contacto from "./components/Contacto";
import {Col, Container, Row} from "react-bootstrap";


const VideoTutorials = () => {

    return (
        <>
            <section className={"video-tutorials page-section"}>
                <Container>
                    <Row>
                        <Col xs={"12"} className={"text-center my-5"}>
                            <h4>Video Tutoriales</h4>
                            <h5 className={"my-2"}>Aprende en unos minutos todo sobre nuestra plataforma</h5>
                        </Col>
                        <Col xs={"12"} className={"text-center"}>
                            <iframe
                                width="80%"
                                height="500px"
                                src="https://www.youtube.com/embed/ptna9WXFXhc"
                                // src="https://youtube.com/shorts/dCSn66K7cjc?feature=share"
                                frameBorder="0"                                                            
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Contacto />
        </>
    );
};

export default VideoTutorials;
