import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import * as _ from "lodash";

import useAppContext from "../data/store";


const Privacidad = () => {
  const [{ user }] = useAppContext();
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const logged =
      !_.isNull(localStorage.getItem("jdo-userToken")) || user.logged;
    setIsLogged(logged);
  }, [user]);

  return (
    <section className={"about-us page-section legal-text"}>
      <Container>
        <Row>
          <Col xs={"12"} md={{ offset: 2, span: 8 }}>
            <h1>Condiciones de uso</h1>
            <p>
              Las  siguientes  Condiciones  Generales  regulan  el  uso  y  acceso  al  Sitio
              Web <a href={"https://wikiobra.com/"}>www.wikiobra.com</a>, cuya finalidad es ser un medio de contacto entre jefes de obra y
              empresas de construcción para establecer relaciones de colaboración entre unos y otros.
              wikiobra.com  adoptará  todas  las  medidas  a  su  alcance  para  impedir  cualquier  tipo  de
              conducta contraria a las Leyes Españolas y cooperará con las Autoridades competentes en la
              identificación de los sujetos responsables de las acciones que puedan suponer violaciones de
              las leyes aplicables al presente Sitio Web.
            </p>
            <p>
              El uso de cualquier información, incluyendo sin limitación, rankings, estudios, herramientas,
              contenidos, imágenes, ilustraciones, diseños, iconos, datos de contacto, direcciones postales,
              direcciones web,  y cualquier otro contenido que está o esté en el futuro en este sitio web
              (denominados colectivamente el “Contenido”) está sujeto a las presentes Condiciones de Uso,
              y  cualquier  utilización  de  estos constituye  la  aceptación  expresa  de  Usuarios  de  Internet,
              Usuarios Registrados de las presentes Condiciones de Uso.
            </p>
            <h3>Condiciones de uso</h3>
            <p>
              El uso como jefe de obra le permite introducir los datos de las obras de construcción en las
              que este haya participado pudiendo añadir o modificar información en la web de Wikiobra
              acerca  de  cualquier  novedad  en  cuanto  a  los  datos  de  la  obra  eximiendo  a  Wikiobra  de
              cualquier responsabilidad por el posible contenido inadecuado que pudiera publicarse. La
              aceptación de las condiciones de uso autoriza a Wikiobra a la publicación de los datos de las
              obras dadas de alta por los usuarios. <br />

              Wikiobra  no  se  compromete  a  publicar  toda  la  información  recibida,  pues,  al  margen  del
              proceso  de  validación,  quedaría  excluido  cualquier  contenido  que  contenga  mensajes
              vejatorios o inconvenientes a juicio de nuestra redacción.
            </p>
            <h3>Condiciones particulares de uso para usuarios registrados (jefe de obra) </h3>
            <p>
              El  Registro  de  Usuario  en  el  sitio  Web <a href={"https://wikiobra.com/"}>www.wikiobra.com</a> se  realiza  a  través  de  la
              cumplimentación  de  un  formulario,  obligándose  a  facilitar  datos  que  sean  veraces  y
              actualizados, así como de la aceptación expresa por el Usuario de las presentes Condiciones
              Particulares de Uso, que podrán ser modificadas por WIKIOBRA en cualquier momento.
              Para acceder, el USUARIO deberá introducir su nombre de usuario y contraseña asociada;
              claves de acceso que son <b>PERSONALES</b> e <b>INTRANSMISIBLES</b>, siendo responsable de la
              custodia  de  estas  el  <b>USUARIO</b>,  quien  deberá  mantenerlas  en  la  más  absoluta  y  estricta
              confidencialidad.
            </p>
            <p>
              El usuario autoriza expresamente a que las empresas dadas de alta en el sitio web WIKIOBRA
              puedan contactarles vía telefónica o mediante correo electrónico, a la dirección ingresada en
              la web, con el objetivo de ofrecer sus bienes o servicios.<br />
              <b>El USUARIO</b> se compromete a hacer un uso adecuado de los contenidos y servicios (como
              por ejemplo servicios de chat, foros de discusión o grupos de noticias) que WIKIOBRA ofrece
              o puede ofrecer a través de su Sitio Web, y con carácter enunciativo, pero no limitativo, a no
              emplearlos para:
            </p>
            <ul>
              <li>
                Incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público.
              </li>
              <li>
                Difundir  contenidos  o  propaganda  de  carácter  racista,  xenófobo,  pornográfico-ilegal,  de
                apología del terrorismo o atentatorio contra los derechos humanos.
              </li>
              <li>
                Provocar daños en los sistemas físicos y lógicos de <b>WIKIOBRA</b>, de sus proveedores o de
                terceras  personas,  introducir  o  difundir  en  la  red  virus  informáticos  o  cualesquiera  otros
                sistemas  físicos  o  lógicos  que  sean  susceptibles  de  provocar  los  daños  anteriormente
                mencionados.
              </li>
              <li>
                Intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y
                modificar o manipular sus mensajes.
              </li>
            </ul>
            <p>
              <b>WIKIOBRA</b> se reserva el derecho de retirar todos aquellos comentarios y aportaciones que
              vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas,
              pornográficos, que atenten contra la juventud o la infancia, el orden o la seguridad pública o
              que, a su juicio, no resultaran adecuados para su publicación. En cualquier caso, WIKIOBRA
              no será, en ningún supuesto, responsable de las opiniones vertidas por los usuarios a través
              de los foros, chats, u otras herramientas de participación.
            </p>
            <h4>Propiedad intelectual e industrial </h4>
            <p>
              Los contenidos, programación, gráficos y diseño que forman este Site Web son propiedad de
              WIKIOBRA  y  se  encuentran  debidamente  protegidos  de  conformidad  con  la  normativa
              española  e  internacional  sobre  Propiedad  Intelectual  e  Industrial;  asimismo,  WIKIOBRA.
              ostenta una autorización/licencia de uso de marca y contenidos por parte de los titulares de
              dichos contenidos. <br />

              El Usuario se obliga a usar los contenidos de forma diligente y correcta, de acuerdo con la ley,
              la moral y el orden público. WIKIOBRA desautoriza al Usuario para efectuar actividades de
              descarga  y  almacenamiento  en  sus  sistemas  informáticos,  sólo  está  autorizado  a  la
              visualización de la información.<br />

              El  Usuario  no  está  autorizado  para  proceder  a  la  distribución,  modificación,  cesión  o
              comunicación pública de la información contenida en este Sitio Web en cualquier forma y
              cualquiera que sea su finalidad.<br />

              El Usuario deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de
              protección o sistema de seguridad que estuviera instalado en el Site Web <a href={"https://wikiobra.com/"}>www.wikiobra.com</a>.
            </p>
            <h4>Enlaces</h4>
            <p>
              Las conexiones y enlaces a sitios o páginas Web de terceros se han establecido únicamente
              como una utilidad para el Usuario. WIKIOBRA no es, en ningún caso, responsable de las
              mismas o de su contenido.<br />

              Para  realizar  enlaces  con  la  página  web <a href={"https://wikiobra.com/"}>www.wikiobra.com</a>,  será  necesaria  la  previa
              autorización expresa y por escrito por parte de WIKIOBRA.
            </p>
            <h3>
              Condiciones  particulares  de  uso  para  acceder  a  los  datos  de
              empresas publicadas en wikiobra.com
            </h3>
            <h4>Acuerdo de licencia </h4>
            <p>
              Por favor, lea detenidamente este acuerdo antes de usar las bases de datos incluidas en
              nuestra web. Wikiobra sólo le concederá la licencia de uso de las bases de datos incluidas en
              la web si antes acepta las condiciones de este acuerdo. Con el uso y el acceso a las bases
              de datos el cliente manifiesta su acuerdo con estas condiciones. Si no acepta las condiciones,
              deberá abstenerse de acceder a cualquier base de datos de nuestra web.
              El presente ACUERDO DE LICENCIA DE USO es suscrito, de un lado, por WIKIOBRA y, de
              otro,  por  el  USUARIO  JEFE  DE  OBRA  (en  adelante  usuario),  entendido  como  cualquier
              persona  física  o  jurídica  que,  por  cualquier  medio,  hubiera  contratado  con  WIKIOBRA  la
              prestación de un servicio de acceso a la base de datos de WIKIOBRA o a una parte de ella
              (en adelante, la BASE DE DATOS WIKIOBRA), según las condiciones de contratación que
              ambas partes hubieran suscrito.
            </p>
            <ul>
              <li>
                <h5>Primera. - Objeto.</h5>
                <p>
                  La presente LICENCIA DE USO regula las condiciones bajo las que WIKIOBRA concede al
                  usuario un DERECHO DE USO de la BASE DE DATOS WIKIOBRA.
                  No es objeto de esta LICENCIA DE USO regular el modo de adquirir dicho DERECHO DE
                  USO, que deberá ser objeto de unas condiciones de contratación específicas, entre el usuario
                  y WIKIOBRA.
                </p>
              </li>
              <li>
                <h5>Segunda. - Acceso a los contenidos y licencia de uso.</h5>
                <p>
                  La  LICENCIA  DE  USO  que  WIKIOBRA  concede  al  usuario  se  presta  con  carácter  no
                  exclusivo,  no  transmisible,  reservándose  WIKIOBRA  expresamente  cualquier  facultad  o
                  derecho no concedido en la presente Licencia y cualquier modalidad de explotación de la
                  BASE DE DATOS WIKIOBRA no autorizada expresamente en la misma.

                  En virtud de esta LICENCIA DE USO, WIKIOBRA autoriza al usuario a:
                  <ul>
                    <li>
                      Acceder un número ilimitado de veces a la BASE DE DATOS WIKIOBRA o a parte de ella,
                      según las condiciones de contratación del servicio, a través de la cuenta de usuario registrado
                      en el sitio web de WIKIOBRA, teniendo en cuenta las limitaciones previstas en esta LICENCIA
                      DE USO.
                    </li>
                    <li>
                      En caso de que así se hubiera previsto en las condiciones de contratación del servicio, la
                      posibilidad  de  descargar  los  contenidos  de  la  BASE  DE  DATOS WIKIOBRA  a  que  tenga
                      acceso.<br />
                      En virtud del presente Acuerdo de Licencia,  WIKIOBRA autoriza al usuario el uso de las
                      Bases  de  Datos    WIKIOBRA  contenidas  en  la  web,  obligándose  éste  a  respetar  en  todo
                      momento las obligaciones establecidas por la Legislación vigente referente a Protección de
                      Datos de Carácter Personal y el Régimen de Comunicaciones Comerciales no solicitadas, sin
                      que pueda aplicar o utilizar la BASE DE DATOS  WIKIOBRA con una finalidad diferente a la
                      prevista  en  el  presente  Acuerdo  de  Licencia,  ni  tampoco  comunicarlos,  ni  para  su
                      conservación, a terceras personas.
                      En este sentido, el usuario se compromete a guardar la máxima reserva y confidencialidad
                      sobre los datos a los que tenga acceso, comprometiéndose a no divulgarlos, publicarlos, ni
                      de otra forma directa o indirecta ponerlos a disposición de terceros, ni total ni parcialmente,
                      con otros fines que los expresamente autorizados por WIKIOBRA en el presente Acuerdo de
                      Licencia
                    </li>
                  </ul>
                </p>
              </li>
              <li>
                <h5>Tercera. - Propiedad intelectual e industrial.</h5>
                <ul>
                  <li>
                    Los derechos de autor, de explotación y morales, y cualesquiera otros derechos de propiedad
                    intelectual  o  industrial  que  existieran  sobre  la  BASE  DE  DATOS    WIKIOBRA,  su
                    documentación  preparatoria  y  técnica,  y  los  manuales  de  uso,  cualquiera  que  sea  su
                    naturaleza,  así  como  cualquiera  de  los  elementos  que  la  integran  son  propiedad  de
                    WIKIOBRA  o  ésta  cuenta  con  autorización  bastante  para  su  explotación  o  se  encuentra
                    legalmente habilitada para ello, sin que en ningún momento se haya de interpretar que la
                    presente  Licencia,  pueda  ser  considerada  como  transmisión  o  cesión  de  los  referidos
                    derechos de propiedad intelectual y/o industrial, los cuales continuarán siendo propiedad de
                    sus legítimos titulares.
                  </li>
                  <li>
                    En ningún caso el usuario (incluyendo sus empleados y/o colaboradores) podrá reproducir,
                    distribuir,  comunicar  al  público,  transformar  o  de  cualquier  otra  forma  explotar,
                    independientemente de que lo haga con carácter oneroso o gratuito, la BASE DE DATOS
                    WIKIOBRA más allá de lo previsto en esta LICENCIA DE USO. Tampoco podrá transferir a
                    terceros los derechos que obtenga en virtud de esta LICENCIA DE USO
                  </li>
                  <li>
                    De modo especial, pero no limitativo, WIKIOBRA se reserva los siguientes derechos:
                    <ul>
                      <li>
                        Explotación  de  la  BASE  DE  DATOS  WIKIOBRA,  de  la  documentación  preparatoria  y
                        técnica, y de los manuales de uso de esta, y en concreto: Derechos de reproducción,
                        fijación,  transformación,  adaptación,  distribución,  comunicación  pública  y  cualquier  otra
                        facultad o modalidad de explotación de la BASE DE DATOS WIKIOBRA a nivel mundial y
                        por cualquier medio.
                      </li>
                      <li>
                        Uso  y  explotación  de  las  marcas  con  distintivo  gráfico  WIKIOBRA  de  acuerdo  con  los
                        derechos  que  le  concede  la  Legislación  Española  e  Internacional  sobre  Propiedad
                        Industrial, a nivel mundial.
                      </li>
                      <li>
                        Derechos  de  remuneración.    WIKIOBRA  hace  reserva  expresa  de  todos  los  derechos
                        llamados  “de  remuneración”  que  le  corresponden  como  titular  de  los  derechos  de
                        Propiedad  Intelectual  e  Industrial  de  la  BASE  DE  DATOS  WIKIOBRA  documentación
                        preparatoria y técnica, manuales de uso y marcas con distintivo gráfico.
                      </li>
                      <li>
                        El  desembolso  de  cualquier  pago  o  royalties,  constituyen  pagos  devengados  a
                        consecuencia de los derechos de uso concedidos en virtud de la presente licencia y no
                        supondrán la transmisión de ningún derecho sobre la BASE DE DATOS WIKIOBRA.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h5>Cuarta. - Obligaciones del usuario en la utilización de la base de datos Wikiobra. </h5>
                El usuario no podrá:
                <ul>
                  <li>
                    Facilitar ni distribuir en forma alguna ni bajo ningún medio la BASE DE DATOS WIKIOBRA, o
                    parte de esta, o su correspondiente documentación, a tercero alguno, ya sea mediante cesión,
                    subarrendamiento, sublicencia o de cualquier otra forma.
                  </li>
                  <li>
                    Copiar, transformar, adaptar, descompilar, invertir, desensamblar, modificar o manipular en
                    forma alguna, total o parcialmente, la BASE DE DATOS WIKIOBRA ni su correspondiente
                    documentación, salvo si así se autoriza expresamente en la presente Licencia.
                  </li>
                  <li>
                    Ocultar o suprimir el correspondiente distintivo de propiedad intelectual o “copyright”, cualquier
                    otro título o marca registrada, así como cualquier aviso o indicación sobre utilización de la
                    BASE DE DATOS WIKIOBRA.
                  </li>
                  <li>
                    Representar,  difundir  o  comercializar  la  BASE  DE  DATOS  WIKIOBRA,  ya  sea  de  forma
                    gratuita u onerosa, de cualquier forma y en cualquier medio.
                  </li>
                  <li>
                    Adaptar, modificar o transformar la BASE DE DATOS WIKIOBRA con vistas a la creación de
                    funcionalidades derivadas o nuevas.
                  </li>
                  <p>
                    El usuario deberá preservar las claves de acceso a su cuenta de usuario en el sitio web de
                    WIKIOBRA, a través de donde podrá acceder a la BASE DE DATOS WIKIOBRA, de manera
                    confidencial y guardando secreto sobre la misma, asumiendo los daños, perjuicios, pérdidas
                    o costes que, en caso de no hacerlo, pudieran ocasionarse, con o sin su consentimiento y/o
                    conocimiento.
                  </p>
                </ul>
              </li>
              <li>
                <h5>Quinta. – Garantías</h5>
                <ul>
                  <li>
                    Wikiobra, declara expresamente que los datos o registros que forman parte de la BASE DE
                    DATOS WIKIOBRA han sido obtenidos de Ficheros de elaboración propia de Wikiobra, así
                    como de fuentes accesibles al público y de fuentes de información de terceras empresas. La
                    recogida de datos se ha efectuado en base al interés legítimo de Wikiobra para elaborar bases
                    de datos de empresas. <br />
                    Wikiobra ha puesto sus esfuerzos en que, en la base de datos no se incluya ningún dato
                    correspondiente a personas que hayan manifestado el deseo inicial de no recibir publicidad
                    <br />
                    comercial o promocional, o posteriormente ejerciendo su derecho de oposición o cancelación
                    hasta la fecha.<br />
                    Wikiobra no garantiza la utilidad que para el usuario tendrá la BASE DE DATOS WIKIOBRA.
                  </li>
                  <li>
                    Asimismo, en cumplimiento de la normativa, si cualquiera de los datos o registros fuera objeto
                    de rectificación o cancelación en la BASE DE DATOS WIKIOBRA o si el titular de los datos
                    se dirige a Wikiobra solicitando que los datos no sean utilizados con objeto de publicidad y/o
                    prospección comercial, Wikiobra notificará, por correo electrónico y en un archivo en formato
                    texto, tal circunstancia al usuario de dentro de los 10 días hábiles siguientes a la fecha de
                    solicitud.
                  </li>
                  <li>
                    El  usuario  se  obliga  a  no  utilizar  aquellos  registros  respecto  a  los  que  Wikiobra  le  haya
                    notificado  que  se  ha  producido  el  ejercicio  del  derecho  de  cancelación  u  oposición  a  su
                    tratamiento.
                  </li>
                  <li>
                    El  usuario  utilizará  la  BASE  DE  DATOS  WIKIOBRA  bajo  su  propio  riesgo  y  ventura
                    manteniendo  indemne  a  WIKIOBRA  de  cualquier  daño,  perjuicio,  pérdida  o  coste  en  que
                    incurriera como consecuencia de usos.
                  </li>
                  <li>
                    El  usuario  responderá  de  todos  los  daños  y  perjuicios,  así  como  de  las  sanciones
                    administrativas  y  económicas  derivadas  de  su  no  cumplimiento  de  las  obligaciones
                    establecidas  en  los  apartados  anteriores,  así  como  de  cualquier  otra  sanción  por  su
                    incumplimiento  de  la  legislación  vigente  en  materia  de  Protección  de  Datos  de  Carácter
                    Personal. Wikiobra  no  será  en  ningún  caso responsable  de  la  utilización  de  la  BASE  DE
                    DATOS WIKIOBRA que haga el usuario, y éste se compromete a dejarla indemne de cualquier
                    gasto, multa, sanción, incluidos los gastos de abogados y procuradores en los que incurra
                    Wikiobra como consecuencia de un mal uso, doloso o negligente de la BASE DE DATOS
                    WIKIOBRA.
                  </li>
                  <li>
                    En caso de incumplimiento de lo dispuesto en la presente LICENCIA DE USO por el usuario,
                    queda a favor de Wikiobra el derecho al resarcimiento de los posibles daños y perjuicios,
                    además de cualquier acción judicial prevista por la legislación española en materia de tutela
                    de los derechos de propiedad intelectual e industrial.
                  </li>
                </ul>
              </li>
              <li>
                <h5>Sexta. - Confidencialidad. </h5>
                <p>
                  Cada una de las partes se compromete a mantener en forma confidencial, durante y después
                  de  la  extinción  de  Acuerdo  de  Licencia,  toda  la  información  a  la  que  hayan  accedido  de
                  cualquier forma o que les haya sido revelada por la otra parte durante la vigencia del Acuerdo
                  de Licencia.
                </p>
              </li>
              <li>
                <h5>Séptima. - Limitación de responsabilidades. </h5>
                <ul>
                  <li>
                    En ningún caso será  WIKIOBRA responsable de cualquiera daños indirectos o mediatos,
                    incluyendo, sin carácter limitativo o restrictivo, los daños y perjuicios por lucro cesante, daños
                    a la reputación de la empresa o su negocio, pérdida de datos o de uso causados por los
                    servicios suministrados por  WIKIOBRA, o por el uso, mal uso o no uso por parte del usuario
                    y  sus  empleados  y/o  colaboradores  de  las  aplicaciones,  herramientas,  Bases  de  Datos,
                    Registros, módulos aplicativos y/o equipos, objeto del presente Acuerdo de la Licencia.
                  </li>
                  <li>
                    El usuario exonera expresamente a WIKIOBRA de cualquier daño que pudiera sufrir aquel por
                    causas que, directa o indirectamente, puedan derivarse del presente Acuerdo de Licencia de
                    uso de acceso a la web o de la utilización de los registros que componen las BASES DE
                    DATOS WIKIOBRA.
                  </li>
                  <li>
                    El usuario es aconsejado expresamente por WIKIOBRA de la necesidad de adoptar medidas
                    adecuadas a fin de asegurar la integridad de la información y los datos contenidos en el
                    sistema; la realización de copias de seguridad periódicas; así como el chequeo periódico de
                    la integridad de los datos contenidos en el sistema y de los datos/soportes de copias de
                    seguridad.
                  </li>
                </ul>
              </li>
              <li>
                <h5>Octava. - Duración y resolución anticipada del acuerdo de licencia de uso.  </h5>
                <ul>
                  <li>
                    La presente LICENCIA DE USO se concede por el tiempo acordado por las partes según las
                    condiciones de contratación aceptadas anteriormente referidas.
                  </li>
                  <li>
                    Sin perjuicio de las causas generales de extinción reguladas por la Legislación Española y de
                    las  causas  previstas  en  las  antedichas  condiciones  de  contratación,  WIKIOBRA  tendrá
                    derecho a revocar o resolver el presente Acuerdo de Licencia de Uso con efecto inmediato, y
                    reclamar cualquier penalización o indemnización por daños y perjuicios a que haya lugar,
                    mediante comunicación por correo electrónico con acuse de recibo en los siguientes casos:
                    <ul>
                      <li>
                        Concesión a terceros, tanto a título gratuito como oneroso, de los derechos de utilización
                        de la BASE DE DATOS WIKIOBRA.
                      </li>
                      <li>
                        Comunicación a terceros, por el usuario y sus empleados y/o colaboradores, del contenido
                        de la BASE DE DATOS WIKIOBRA.
                      </li>
                      <li>
                        Subrogación de un tercero, a cualquier título, en el presente Acuerdo de Licencia de Uso,
                        incluso  como  consecuencia  de  adquisición  o  fusión  empresarial  y  otros  supuestos  de
                        cesión ex lege.
                      </li>
                      <li>
                        Utilización  de  la  BASE  DE  DATOS  WIKIOBRA  por  el  usuario  a  favor  de  terceros  no
                        autorizados.
                      </li>
                      <li>
                        Copia  no  autorizada  de  la  BASE  DE  DATOS  WIKIOBRA  y/o  de  la  documentación  o
                        contenido incluida en la misma.
                      </li>
                      <li>
                        Utilización  de  registros  de  la  BASE  DE  DATOS  WIKIOBRA  en  contravención  de  las
                        obligaciones  legalmente  establecidas  por  la  Ley  Orgánica  15/1999,  de  Protección  de
                        Datos de Carácter Personal, así como utilización de registros que hayan sido comunicados
                        por WIKIOBRA sobre los cuales los titulares de los datos hayan ejercitado sus derechos
                        de oposición al tratamiento para finalidades de prospección comercial.
                      </li>
                      <li>
                        Cualquier alteración o modificación por parte del usuario de los datos de la BASE DE
                        DATOS WIKIOBRA no autorizada por WIKIOBRA.
                      </li>
                    </ul>
                  </li>
                  <li>
                    En los casos señalados anteriormente, así como en caso de revocación y/o resolución del
                    presente Acuerdo de Licencia no exclusiva de uso de las BASES DE DATOS WIKIOBRA,
                    WIKIOBRA  se  reserva  el  derecho  al  resarcimiento  del  posible  daño  mayor,  además  de
                    cualquier  acción  judicial  prevista  por  la  legislación  española  en  materia  de  tutela  de  los
                    derechos de propiedad intelectual e industrial.
                  </li>
                  <li>
                    El usuario responderá personalmente de las infracciones en que pudiera incurrir en el caso
                    de que destinen y/o utilicen los datos de carácter personal contenidos en las BASES DE
                    DATOS  WIKIOBRA  en  contravención  de  la  legislación  vigente  y  el  presente  Acuerdo  de
                    Licencia, los comunique a un tercero o proceda a la distribución de estos por cualquier medio,
                    sea oneroso o gratuito, de manera pública o privada.
                  </li>
                </ul>
              </li>
              <li>
                <h5>Novena. - Legislación y jurisdicción.   </h5>
                <p>
                  WIKIOBRA y el usuario, renunciando expresamente al fuero y legislación que les pudiera
                  corresponder, acuerdan que todo litigio, controversia, discrepancia, cuestión o reclamación
                  resultante de la ejecución o interpretación del presente Acuerdo de Licencia o relacionados
                  con el mismo, directa o indirectamente, se someterán expresamente a la jurisdicción de los
                  Juzgados y Tribunales de Barcelona, España.
                </p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Privacidad;
