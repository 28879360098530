import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link, useLocation, useHistory} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import * as _ from "lodash";
import useAppContext from "../../data/store";


export default () => {
    const history = useHistory();
    const location = useLocation();
    const year = (new Date()).getFullYear();
    const [{ user }] = useAppContext();
    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        const logged =
            !_.isNull(localStorage.getItem("jdo-userToken")) || user.logged;
        setIsLogged(logged);
    }, [user]);

    if(location.pathname === '/inicio' && history.action === 'REPLACE') {
        window.location.reload();
    }


    return (
        <>
            {!isLogged && (
                <div className={"link-content"}>
                <Container>
                    <Row>
                        <Col xs={12} md={6} className={"m-auto mb-5"}>
                            <Link className={'navbar-brand'} to={"/inicio"}>
                                <div className={"contrast-logo"}>
                                    wikiobra.com
                                </div>
                            </Link>
                        </Col>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={12} md={6} className={"mb-4"}>
                                    <h6> Enlaces de interés </h6>
                                    <Link to="/privacidad">
                                        Políticas de privacidad
                                    </Link>
                                    <Link to="/condiciones">
                                        Condiciones de uso
                                    </Link>

                                </Col>
                                <Col xs={12} md={6}>
                                    <h6> Redes Sociales </h6>
                                    <Link className={'social-network'} to={{pathname: "https://www.instagram.com/wikiobra/"}}
                                          target="_blank">
                                        <FontAwesomeIcon icon={["fab", "instagram"]}/>
                                    </Link>
                                    <Link className={'social-network'} to={{pathname: "https://www.youtube.com/channel/UCQtCGsy6y14OtTLKz7eTOgQ"}}
                                          target="_blank">
                                        <FontAwesomeIcon icon={["fab", "youtube"]}/>
                                    </Link>
                                    <Link className={'social-network'} to={{pathname: "https://www.linkedin.com/company/wikiobra/"}}
                                          target="_blank">
                                        <FontAwesomeIcon icon={["fab", "linkedin-in"]}/>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>)
            }
            <div className={"copyright"}>
              &copy;Copyright {year} wikiobra.com Todos los derechos reservados
            </div>
        </>
    );
};
