import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";
import { Row, Col, Form, Button, ButtonToolbar } from "react-bootstrap";
import Error from "../../../common/Error";
import LinkedInPage from "../../../common/LinkedinButton";
import IconSearch from "../../../icons/IconSearch";
import { SearchBusinessModal } from "../../../common/Modals/SearchBusinessModal";
import { toast } from "react-toastify";

const validacion = Yup.object().shape({
  nombre: Yup.string().typeError("valor-texto").required("obligatorio"),
  empresa: Yup.string().typeError("valor-texto").required("obligatorio"),
  email_profesional: Yup.string()
    .email()
    .typeError("valor-email")
    .required("obligatorio"),
  email: Yup.string().email().typeError("valor-email").required("obligatorio"),
  password: Yup.string().typeError("valor-texto").required("obligatorio"),
});

const RegisterForm = ({ doLogin, roles, changeView, setChangeView }) => {
  const allowRoles = ["constructora", "proveedor_o_subcontratista",];

  const [initialValues, setInitialValues] = useState({
    nombre: null,
    email_profesional: null,
    empresa: null,
    email: null,
    password: null,
    linkedin: null,
    role: null,
  });

  const [showModal, setShowModal] = useState(false);
  const [changeFilter, setChangeFilter] = useState("");
  const [listProviders, setListProviders] = useState([]);

  const handleCloseModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const updateInitialValue = (values) => {
    return setInitialValues({
      ...initialValues,
      nombre: values?.nombre,
      email: values?.email,
      linkedin: values?.linkedin,
    });
  };

  const handleUpdateRoleValue = (value) => {
    return setInitialValues({
      ...initialValues,
      role: `${value}`,
    });
  };

  useEffect(() => {
  }, [initialValues]);

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState();
  const [linkedin, setLinkedin] = useState(null);

  const onSubmit = async (data, { setSubmitting }) => {
    setIsLoading(true);
    const newData = cleanForm(data);
    if (listProviders?.includes(newData.empresa)) {
      await doLogin(newData);
    }
    if (!listProviders?.includes(newData.empresa)) {
      toast.error(`${t("registro_error")}`);
    }
    setIsLoading(false);
  };

  const cleanForm = (data) => {
    const newData = { ...data };
    newData.username = data.email;
    newData.empresa = changeFilter;
    // newData.linkedin = linkedin || "";

    return newData;
  };

  const handleData = (data) => {
    setLinkedin(data.personales.vanityName);
  };

  const handleFilterChange = (event) => {
    const needle = event.target.value.toUpperCase();
    setChangeFilter(needle);
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validacion}
        validateOnMount={false}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          touched,
          errors,
          isValid,
          isSubmiting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row className={"mt-4 justify-content-center"}>
              <Col xxl={"11"} xl={"11"} lg={"11"} md={"11"} sm={"11"} xs={"11"}>
                {changeView ? (
                  <>
                    <Row className={"mw-100 mb-4"}>
                      <Col
                        xxl={"6"}
                        xl={"6"}
                        lg={"6"}
                        md={"11"}
                        sm={"11"}
                        xs={"11"}
                      >
                        <Form.Group controlId="nombre">
                          <Form.Label>Nombre (*)</Form.Label>
                          <Error
                            touched={touched.nombre}
                            message={errors.nombre}
                          />
                          <Form.Control
                            type="text"
                            placeholder=""
                            size="sm"
                            value={values.nombre || ""}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        xxl={"6"}
                        xl={"6"}
                        lg={"6"}
                        md={"11"}
                        sm={"11"}
                        xs={"11"}
                      >
                        <Form.Group controlId="email">
                          <Form.Label>
                            Email de contacto [será tu usuario de Wikiobra.com]
                            (*)
                          </Form.Label>
                          <Error
                            touched={touched.email}
                            message={errors.email}
                          />
                          <Form.Control
                            type="email"
                            placeholder=""
                            size="sm"
                            value={values.email || ""}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className={"mw-100 mb-3"}>
                      <Col
                        xxl={"6"}
                        xl={"6"}
                        lg={"6"}
                        md={"11"}
                        sm={"11"}
                        xs={"11"}
                      >
                        <Form.Group controlId="email_profesional">
                          <Form.Label>Email profesional </Form.Label>
                          <Error
                            touched={touched.email_profesional}
                            message={errors.email_profesional}
                          />
                          <Form.Control
                            type="email"
                            placeholder=""
                            size="sm"
                            value={values.email_profesional || ""}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        xxl={"6"}
                        xl={"6"}
                        lg={"6"}
                        md={"11"}
                        sm={"11"}
                        xs={"11"}
                      >
                        <Form.Group controlId="password">
                          <Form.Label>Contraseña</Form.Label>
                          <Error
                            touched={touched.password}
                            message={errors.password}
                          />
                          <Form.Control
                            type="password"
                            placeholder=""
                            size="sm"
                            value={values.password || ""}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row
                      className={"mw-100"}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Col
                        xxl={"6"}
                        xl={"6"}
                        lg={"6"}
                        md={"6"}
                        sm={"11"}
                        xs={"11"}
                      >
                        <Form.Group controlId="empresa">
                          <Form.Label>Empresa (*)</Form.Label>
                          <Error
                            touched={touched.empresa}
                            message={errors.empresa}
                          />
                          <Form.Control
                            type="text"
                            placeholder=""
                            size="sm"
                            value={changeFilter}
                            onChange={(e) => {
                              handleFilterChange(e);
                              setFieldValue('empresa', changeFilter)
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        xxl={"2"}
                        xl={"2"}
                        lg={"3"}
                        md={"4"}
                        sm={"6"}
                        xs={"4"}
                        style={{ margin: "auto 0", padding: "30px 0" }}
                      >
                        <Button
                          variant="primary"
                          style={{ marginLeft: "20px" }}
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          <IconSearch width={"15px"} height={"15px"} />
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                    <Row className={"mw-100"}>
                      <Col
                        xxl={"12"}
                        xl={"12"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xs={"12"}
                      >
                        <p style={{ fontSize: "15px" }}>
                          Al hacer click «Aceptar y unirse» aceptas las
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "#0077b5",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            Condiciones de uso
                          </a>{" "}
                          y la
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "#0077b5",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            Politica de privacidad
                          </a>{" "}
                          de Wikiobra
                        </p>
                      </Col>
                      <Col
                        xxl={"12"}
                        xl={"12"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xs={"12"}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="primary"
                          disabled={!isValid}
                          onClick={handleSubmit}
                        >
                          Aceptar y unirse
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Form.Group controlId="role">
                    <Error touched={touched.role} message={errors.role} />
                    <Col
                      xxl={"2"}
                      xl={"2"}
                      lg={"3"}
                      md={"4"}
                      sm={"4"}
                      xs={"6"}
                      style={{ margin: "0 auto" }}
                    >
                      {roles.map(
                        (rol) =>
                          allowRoles.includes(rol.type) && (
                            <Form.Check
                              key={rol.id}
                              name="role"
                              type="radio"
                              label={rol.name}
                              onChange={handleChange}
                              onClick={() => {
                                setFieldValue("role", rol.id);
                                handleUpdateRoleValue(rol.id);
                              }}
                              value={`${rol.id}`}
                              id={`role-${rol.id}`}
                              checked={values.role === `${rol.id}`}
                            />
                          )
                      )}
                      <Row className={"mt-5 justify-content-center"}>
                        <Col
                          xxl={"5"}
                          xl={"5"}
                          lg={"5"}
                          md={"5"}
                          sm={"5"}
                          xs={"5"}
                        >
                          <LinkedInPage
                            doData={handleData}
                            setChangeView={setChangeView}
                            updateInitialValue={updateInitialValue}
                          />
                          {linkedin && `Comprobado el perfil de ${linkedin}`}
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <SearchBusinessModal
        show={showModal}
        doClose={handleCloseModal}
        title={`Title`}
        needle={changeFilter}
        setChangeFilter={setChangeFilter}
        setListProvidersNew={setListProviders}
      />
    </Fragment>
  );
};

export default RegisterForm;
