import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Button, ButtonToolbar } from "react-bootstrap";
import { parse } from 'qs';
import Error from "../../common/Error";
const validacion = Yup.object().shape({
  provinceToFind: Yup.string().typeError("valor-comunidad").required("obligatorio"),
  province: Yup.string().typeError("valor-comunidad").required("obligatorio"),
});

const NormalizeDataProvinceForm = ({ location, doNormalizeDataProvince }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState();

  const initial = {
    provinceToFind: null,
    province: null,
  };


  const onSubmit = async (data, { setSubmitting }) => {
    setIsLoading(true);
    doNormalizeDataProvince(data);
    setIsLoading(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initial}
      validationSchema={validacion}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isValid,
        isSubmiting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='provinceToFind'>
            <input
              type='hidden'
              name='code'
              defaultValue={values.code}
            />
            <Form.Label>{t('provinceToFind')}</Form.Label>
            <Error touched={touched.provinceToFind} message={errors.provinceToFind} />
            <Form.Control
              name='provinceToFind'
              type='text'
              value={values.provinceToFind || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='province'>
            <Form.Label>{t('province')}</Form.Label>
            <Error
              touched={touched.province}
              message={errors.province}
            />
            <Form.Control
              name='province'
              type='text'
              value={values.province || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Barra />
          <Row>
            <Col>
              <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                <Button
                  variant='primary'
                  type='submit'
                  size='sm'
                  block
                  disabled={isLoading}
                >
                  {t('enviar')}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

const Barra = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #8c8b8b;
`;

export default NormalizeDataProvinceForm;
