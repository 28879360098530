import React from "react";
import { Box, Flex } from "reflexbox";

const Leyenda = () => (
    <div className="item-list">
        <div className={"row"}>
            <div
                className={"col col-12"}
            >
                <Flex flexDirection="column">
                    <Flex alignItems="center" my={1}>
                        <Box
                            sx={{
                                display: "inline-block",
                                color: "white",
                                bg: `red`,
                                px: 3,
                                py: 1.5,
                                borderRadius: 9999,
                                textAlign: "center",
                            }}
                        >
                            <p style={{ color: "white", marginBottom: 0 }}>DE 0,00 A 1,99</p>
                        </Box>
                        <Box ml={2}>MEJORABLE</Box>
                    </Flex>

                    <Flex alignItems="center" my={1}>
                        <Box
                            sx={{
                                display: "inline-block",
                                color: "white",
                                bg: `green`,
                                px: 3,
                                py: 1.5,
                                borderRadius: 9999,
                                textAlign: "center",
                            }}
                        >
                            <p style={{ color: "white", marginBottom: 0 }}>DE 2,00 A 2,50</p>
                        </Box>
                        <Box ml={2}>NORMAL</Box>
                    </Flex>

                    <Flex alignItems="center" my={1}>
                        <Box
                            sx={{
                                display: "inline-block",
                                color: "white",
                                bg: `blue`,
                                px: 3,
                                py: 1.5,
                                borderRadius: 9999,
                                textAlign: "center",
                            }}
                        >
                            <p style={{ color: "white", marginBottom: 0 }}>DE 2,51 A 3,00</p>
                        </Box>
                        <Box ml={2}>EXCELENTE</Box>
                    </Flex>
                </Flex>
            </div>
        </div>
    </div>

);

export default Leyenda;
