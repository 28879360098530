import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { Box, Flex } from "reflexbox";
import EmpleadosListItem from "./EmpleadosListItem";
import { useHistory } from "react-router-dom";
import { MainTitle } from "../../common/StyledComponents";

const EmpleadosList = () => {
  const history = useHistory();
  const [modal, setModal] = React.useState(false);
  const [datos, setDatos] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const token = localStorage.getItem("jdo-userToken");
    const userData = JSON.parse(localStorage.getItem("jdo-userData"));

    if (!userData.empleado) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/users/?empleado=${userData.id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          throw Error("Bad request");
        }
        const data = await res.json();
        setDatos(data);
      } catch (err) {
        console.log("err :>> ", err);
      }
    }
  };

  const handleDelete = async (item) => {
    // console.log("delete item :>> ", item);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${item}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        throw Error("Bad request");
      }
      getData();
    } catch (err) {
      console.log("err :>> ", err);
    }

  };

  // ************************** TABLE PARAMS

  // ************************** FUNCTIONS
  const toggle = () => setModal(!modal);

  if (!datos) {
    return <div>Loading...</div>;
  }

  if (datos) {
    return (
      <div className="container">
        {/* <div className="containers"> */}
        <Flex width="100%" alignItems="center">
          <Box flex="1">
            <MainTitle>Colaboradores</MainTitle>
          </Box>
          <Box>
            <Button
              variant="primary"
              block
              onClick={() => history.push("/colaboradores/new")}
            >
              Nuevo
            </Button>
          </Box>
        </Flex>
        <Card variant="sombra" width="100%">
          {datos.map((d) => (
            <EmpleadosListItem
              key={d.id}
              item={d}
              doDelete={handleDelete}
              isEmpleado={true}
            />
          ))}
        </Card>
      </div>
    );
  }
};

export default EmpleadosList;
