import { Box, Flex } from "reflexbox";
import { Button } from "rebass/styled-components";
import { Formik, Field } from "formik";
import { Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import * as Yup from "yup";
import appServices from "../../logic/services";
import AsyncSelect from "react-select/async";
import Error from "../../common/Error";
import NumberFormat from "react-number-format";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";
import { getUser } from "../../logic/logic";

import { InfoCircleFill } from "react-bootstrap-icons";

const initial = {
  codigo: "",
  comunidad: null,
  constructora: "",
  coordinador_seguridad: "",
  colaboradores: [],
  direccion_facultativa: "",
  direccion: "",
  director_ejecucion: "",
  director_obra_1: "",
  director_obra_2: "",
  empresa_coordinacion_ss: "",
  empresa_direccion_instalaciones: "",
  epigrafes_para_ofertas: "",
  estado_obra: "3",
  fecha_final: null,
  fecha_inicial: null,
  fecha_prevista_fin: null,
  municipio: "",
  pem: "",
  planos: "",
  propiedad: "",
  provincia: null,
  tecnico_instalaciones: "",
  titulo: "",
  visible: true,
  siteManager: null,
  plusCode: null,
};

const validacion = Yup.object().shape({
  titulo: Yup.string().typeError("valor-texto").required("obligatorio"),
  direccion: Yup.string().typeError("valor-texto").required("obligatorio"),
  provincia: Yup.string().typeError("valor-texto").required("obligatorio"),
  pem: Yup.string().typeError("valor-texto").required("obligatorio"),
  propiedad: Yup.string().typeError("valor-texto").required("obligatorio"),
  direccion_facultativa: Yup.string()
    .typeError("valor-texto")
    .required("obligatorio"),
});

const ObrasEstudiosEditForm = ({ item, doSave, final, changeState, edit }) => {
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const history = useHistory();
  const [provinciasDeObras, setProvinciasDeObras] = useState([]);

  const [listaProvincias, setListaProvincias] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [tipoProveedores, setTipoProveedores] = useState([]);

  const [comunidad, setComunidad] = useState(null);
  const [provincia, setProvincia] = useState(null);
  const [workState, setWorkState] = useState(null);
  const [epigraphs, setEpigraphs] = useState(null);
  const [infoOn, setInfoOn] = useState(false);

  useEffect(() => {
    const getAll = async () => {
      try {
        const categorias = await appServices.getCategorias();
        const tipoProveedores = await appServices.getTipoProveedores();
        setCategorias(categorias.json);
        setTipoProveedores(tipoProveedores.json);
      } catch (err) {
        toast.error(`${t("error")}`);
      }
    };

    getAll();

    if (item) {
      item.provincia = item.provincia
        ? { label: item.provincia, value: item.provincia }
        : null;
      item.tipo_proveedor = item.tipo_proveedor?.id || null;
      item.epigrafes_para_ofertas =
        item.documentacion_obra?.epigraphs_work || null;
      item.planos = item.documentacion_obra?.blueprints || null;
      item.siteManager = item.site_manager || null;
      item.plusCode = item.google_maps_plus_code || null;
    }
  }, [item]);

  useEffect(() => {
    let mount = true;
    if (mount) {
      getProvincias();
    }
    return () => {
      mount = false;
    };
  }, []);

  const getProvincias = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/provincias?_limit=-1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const provinciasProveedores = await res.json();

      setListaProvincias(provinciasProveedores);

      const cadaProvincia = provinciasProveedores
        .map((proveedor) => ({
          label: proveedor.nombre,
          value: proveedor.nombre,
        }))
        .filter((f) => f.value != null && f.value !== "");

      const provinciasOrden = _.sortBy(cadaProvincia, (o) => {
        const label = _.deburr(o.label);
        return label;
      });

      setProvinciasDeObras(provinciasOrden);
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  };

  const filterSelects = (values) => {
    if (values.comunidad && typeof values.comunidad !== "object") {
      setComunidad(values.comunidad);
    }
    if (values.provincia && typeof values.provincia !== "object") {
      setProvincia(values.provincia);
    }
  };

  const onSubmit = (data, { setSubmitting }) => {
    const newData = cleanForm(data);
    doSave(newData);
    toast.success(`${t("Guardado con éxito")}`);
  };

  const handleGetColaboradores = async (busca) => {
    const token = localStorage.getItem("jdo-userToken");
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users?nombre_contains=${busca}&_limit=-1`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        throw Error("Bad request");
      }
      const data = await res.json();
      const colaboradoresSelect = data.map((p) => ({
        label: `${p.nombre} de ${p.empresa || ""}`,
        value: p.id,
        colaborador: p,
      }));
      return colaboradoresSelect;
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  };

  const cleanForm = (data) => {
    const regex = /\./gi;
    const newData = { ...data };
    newData.pem = data.pem ? data.pem.toString().replace(regex, "") : "";
    newData.provincia = data.provincia.value.toUpperCase();
    newData.comunidad = data.comunidad;
    newData.google_maps_plus_code = data.plusCode;

    const provincia = listaProvincias.find(
      (p) => p.nombre === data.provincia.value
    );
    if (provincia) {
      newData.comunidad = provincia.comunidad.nombre.toUpperCase();
    }

    if (data.fecha_inicio === "") newData.fecha_inicio = null;
    if (data.fecha_final === "") newData.fecha_final = null;
    if (data.fecha_prevista_fin === "") newData.fecha_prevista_fin = null;

    // add user to work
    if (data.siteManager !== null) {
      newData.user = data.siteManager.id;
      newData.site_manager = data.siteManager.id;
    } else {
      newData.user = getUser();
      newData.site_manager = getUser();
    }

    return newData;
  };

  const handleTrack = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("jdo-userData"));
    initial.constructora = user.empresa;
  }, []);

  if (!isLoading) {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={item?.id ? item : initial}
          validationSchema={validacion}
          validate={filterSelects}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({
            setFieldValue,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            isValid,
            isSubmiting,
          }) => {
            return (
              <Form onSubmit={handleSubmit} onKeyPress={(e) => handleTrack(e)}>
                <Row>
                  {/* ******************* left */}
                  <Col>
                    {/* field */}
                    <Row>
                      <Col>
                        {/* field */}
                        <Row>
                          <Col md={12}>
                            <Form.Group controlId="titulo">
                              <Form.Label>{t("titulo")}*</Form.Label>
                              <Error
                                touched={touched.titulo}
                                message={errors.titulo}
                              />
                              <Form.Control
                                name="titulo"
                                type="text"
                                value={values.titulo || ""}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* end field */}

                    {/* field */}
                    <Row>
                      <Col>
                        {/* field */}
                        <Row>
                          {/* end field */}
                          <Col md={4}>
                            {/* field */}
                            <Form.Group controlId="fecha_inicio">
                              <Form.Label>
                                {t("fecha_presentacion_de_oferta")}
                              </Form.Label>
                              <Error
                                touched={touched.fecha_inicio}
                                message={errors.fecha_inicio}
                              />
                              <Form.Control
                                type="date"
                                name="fecha_inicio"
                                value={values.fecha_inicio || ""}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            {/* end field */}
                          </Col>
                          <Col md={4}>
                            {/* field */}
                            <Form.Group controlId="fecha_prevista_fin">
                              <Form.Label>
                                {t("fecha_final_obras_curso")}
                              </Form.Label>
                              <Error
                                touched={touched.fecha_prevista_fin}
                                message={errors.fecha_prevista_fin}
                              />
                              <Form.Control
                                type="date"
                                name="fecha_prevista_fin"
                                value={values.fecha_prevista_fin || ""}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            {/* end field */}
                          </Col>{" "}
                          <Col md={4}>
                            {/* field */}

                            {/* end field */}
                          </Col>{" "}
                        </Row>
                      </Col>
                    </Row>
                    {/* end field */}

                    {/* field */}
                    <Row>
                      <Col>
                        {/* field */}
                        <Row>
                          <Col md={12}>
                            <Form.Group controlId="direccion">
                              <Form.Label>{t("direccion")}*</Form.Label>
                              <Error
                                touched={touched.direccion}
                                message={errors.direccion}
                              />
                              <Form.Control
                                name="direccion"
                                type="text"
                                value={values.direccion || ""}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          {/* end field */}
                        </Row>
                        {/* end field */}
                      </Col>
                    </Row>
                    {/* end field */}
                    <Row>
                      <Col md={12}>
                        {/* field */}
                        <Row>
                          {/* field */}
                          <Col md={4}></Col>
                        </Row>
                        {/* end field */}
                      </Col>
                    </Row>
                  </Col>
                  {/* ******************* end left */}
                </Row>

                {/* ******************* colaboradores y otros */}

                <Row>
                  <Col md={5}>
                    {/* ******************* colaboradores */}
                    <Form.Group controlId="provincia">
                      <Form.Label>{t("provincia")}*</Form.Label>
                      <Error
                        touched={touched.provincia}
                        message={errors.provincia}
                      />
                      <Select
                        value={values.provincia}
                        isClearable
                        id="tags"
                        onChange={(option) =>
                          setFieldValue("provincia", option)
                        }
                        options={provinciasDeObras}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    {/* ******************* colaboradores */}

                    {/*
                    <Form.Group>
                      <Error touched={touched.siteManager} message={errors.siteManager} />
                      <AsyncSelect
                        placeholder="Seleccion el jefe de obra de la lista..."
                        loadOptions={handleGetColaboradores}
                        isSearchable={true}
                        isClearable={true}
                        value={
                          values.siteManager ? {
                              label: `${ values.siteManager.nombre || ""} de ${values.siteManager.empresa || ""}`,
                              value: `${values.siteManager.id}` }
                            : ""}
                        onChange={(option) => {
                          setFieldValue(
                            "siteManager",
                            option
                              ? option.colaborador
                              : null
                          );
                        }}
                      />
                    </Form.Group>
                    */}
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="municipio">
                      <Form.Label>{"Municipio"}</Form.Label>
                      <Error
                        touched={touched.municipio}
                        message={errors.municipio}
                      />
                      <Form.Control
                        name="municipio"
                        type="text"
                        value={values.municipio || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {/*
                  <Col md={7}>
                    <div
                      style={{ display: "flex", position: "relative" }}
                    >
                    <Form.Group controlId="plusCode">
                      <Form.Label>{`${t("plusCode")} (*)`}</Form.Label>
                      <Error
                        touched={touched.plusCode}
                        message={errors.plusCode}
                      />
                      <Form.Control
                        name="plusCode"
                        type="text"
                        value={values.plusCode || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                        <ButtonOfInformatio
                          width="2rem"
                          height="2rem"
                          onClick={() =>
                            setInfoOn((prevState) => !prevState)
                          }
                        />
                        <BarraInfo info={infoOn} />
                      </div>
                      <DivInfo info={infoOn}>
                        <p style={{ margin: "0" }}>
                          Copia la dirección del cuadro de arriba -&gt; ve
                          a:
                          <LinkExample>
                            https://maps.google.com/ppluscodes/
                          </LinkExample>
                          -&gt; pega la dirección -&gt; Copia el PLUSCODE que
                          has obtenido (comprueba luego en Google maps
                          que el código es el correcto)
                        </p>
                      </DivInfo>
                    </div>
                  </Col>
                  */}
                </Row>
                {/* ******************* colaboradores y otros */}
                <Row>
                  <Col md={12}>
                    {/* field */}
                    <Row>
                      {/* field */}
                      <Col md={5}>
                        <h6>Colaboradores</h6>
                        <Form.Group>
                          <Error
                            touched={touched.colaboradores}
                            message={errors.colaboradores}
                          />
                          <AsyncSelect
                            placeholder="Añadir nuevo colaborador de la lista..."
                            loadOptions={handleGetColaboradores}
                            isSearchable={true}
                            isClearable={true}
                            value={
                              values.colaboradores ? values.colaboradores : ""
                            }
                            onChange={(option) => {
                              console.log(`option`, option);
                              setFieldValue(
                                "colaboradores",
                                option
                                  ? [
                                      ...values.colaboradores,
                                      option.colaborador,
                                    ]
                                  : null
                              );
                            }}
                          />
                        </Form.Group>
                        {values.colaboradores &&
                          values.colaboradores.map((c) => (
                            <Flex mt={2} key={c.id} alignItems="center">
                              <Box flex={1}>
                                {c.nombre} de {c.empresa || ""}
                              </Box>
                              <Box>
                                <Button
                                  type="button"
                                  variant="danger"
                                  onClick={() => {
                                    const colabs = values.colaboradores.filter(
                                      (f) => {
                                        return f.id !== c.id;
                                      }
                                    );
                                    setFieldValue("colaboradores", colabs);
                                  }}
                                >
                                  x
                                </Button>
                              </Box>
                            </Flex>
                          ))}
                      </Col>
                    </Row>
                    {/* end field */}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {/* ******************* otros campos */}

                    {/* field */}
                    <Row>
                      <Col>
                        <Form.Group controlId="constructora">
                          <Form.Label>{t("constructora")}</Form.Label>
                          <Error
                            touched={touched.constructora}
                            message={errors.constructora}
                          />
                          <Form.Control
                            name="constructora"
                            type="text"
                            value={values.constructora || ""}
                            onChange={handleChange}
                            disabled={true}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* end field */}

                    {/* grupo */}
                    <Row>
                      <Col md={4}>
                        <Form.Group controlId="codigo">
                          <Form.Label>{t("codigo")}</Form.Label>
                          <Error
                            touched={touched.codigo}
                            message={errors.codigo}
                          />
                          <Form.Control
                            name="codigo"
                            type="text"
                            value={values.codigo || ""}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      {/* field */}
                      <Col md={4}>
                        <Form.Group controlId="propiedad">
                          <Form.Label>{t("propiedad")}</Form.Label>
                          <Error
                            touched={touched.propiedad}
                            message={errors.propiedad}
                          />
                          <Form.Control
                            name="propiedad"
                            type="text"
                            value={values.propiedad || ""}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      {/* field */}
                      <Col md={4}>
                        <Form.Group controlId="pem">
                          <Form.Label>{t("pem")}*</Form.Label>
                          <Error touched={touched.pem} message={errors.pem} />
                          <Field name="pem">
                            {({ field }) => (
                              <NumberFormat
                                className="form-control"
                                isNumericString={false}
                                decimalScale="0"
                                thousandSeparator="."
                                decimalSeparator=","
                                value={values.pem || ""}
                                onChange={field.onChange(field.name)}
                              />
                            )}
                          </Field>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* grupo */}

                    {/* grupo */}
                    <Row>
                      <Col md={4}>
                        <Form.Group controlId="direccion_facultativa">
                          <Form.Label>{t("direccion_facultativa")}</Form.Label>
                          <Error
                            touched={touched.direccion_facultativa}
                            message={errors.direccion_facultativa}
                          />
                          <Form.Control
                            name="direccion_facultativa"
                            type="text"
                            value={values.direccion_facultativa || ""}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* grupo */}

                    {/* ******************* otros campos */}
                  </Col>
                </Row>

                {/* ---------------------------------------------------------- */}

                <Row>
                  <Col className="card margin-top-3" md={7}>
                    <h3 className="altura" style={{ color: "#2c3684" }}>
                      Documentación de obra
                    </h3>
                    <Form.Group controlId="epigrafes_para_ofertas">
                      <Form.Label>{t("epigrafes_para_ofertas")}</Form.Label>
                      <Error
                        touched={touched.epigrafes_para_ofertas}
                        message={errors.epigrafes_para_ofertas}
                      />
                      <Form.Control
                        name="epigrafes_para_ofertas"
                        type="text"
                        value={values.epigrafes_para_ofertas || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="alturainput" controlId="planos">
                      <Form.Label>{t("planos")}</Form.Label>
                      <Error touched={touched.planos} message={errors.planos} />
                      <Form.Control
                        name="planos"
                        type="text"
                        value={values.planos || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* -------------------------------------------------------------------- */}
                {/*
                xxl={"4"} xl={"3"} lg={"4"} md={"6"} sm={"6"} xs={"11"}
                */}
                <Barra />
                <Flex justifyContent="flex-end" flexWrap="wrap">
                  <Box py={1} px={1} width={["98%", "48%", "30%"]}>
                    {edit ? (
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          changeState();
                        }}
                        width={["100%"]}
                      >
                        {t("CAMBIAR A OBRA EN CURSO")}
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box py={1} px={1} width={["98%", "24%", "15%"]}>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        final
                          ? history.push("/obras")
                          : history.push("/estudios")
                      }
                      width={["100%"]}
                    >
                      {t("cancelar")}
                    </Button>
                  </Box>
                  <Box py={1} px={1} width={["98%", "25%", "15%"]}>
                    <Button
                      px={30}
                      variant="primary"
                      type="submit"
                      disabled={isLoading}
                      width={["100%"]}
                    >
                      {t("enviar")}
                    </Button>
                  </Box>
                </Flex>
                <style jsx="true">{`
                  .cuadro {
                    padding-left: 90px;
                    margin-top: 40px;
                  }
                  .card {
                    z-index: 99;
                    border-radius: 20px;
                    margin-top: 20px;
                    border-color: #2c3684;
                  }
                  .margin-top-3 {
                    margin-top: 3rem;
                  }
                  .altura {
                    margin-top: 20px;
                  }

                  .alturainput {
                    margin-bottom: 20px;
                  }

                  .cardt {
                    color: rgb(167, 164, 164);
                    border-color: #a7a4a4 !important;
                    padding-left: 20px;
                    max-width: fit-content;
                    border-radius: 15px !important;
                    padding-right: 20px;
                  }

                  .estilo {
                    margin-top: 43px;
                  }
                `}</style>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
};

const Barra = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #8c8b8b;
`;

const ButtonOfInformatio = styled(InfoCircleFill)`
  fill: #4e00c5;
  margin-left: 5px;
  margin-top: 45px;
  cursor: pointer;
`;

const DivInfo = styled.div`
  background-color: #fbe7d5;
  padding: 6px;
  border: 2px solid #9ea8bb;
  margin-left: 2rem;
  display: ${(props) => (props.info ? "block" : "none")};
  position: absolute;
  left: 70%;
  font-size: 0.7rem;
  z-index: 10;
  width: 40%;
  top: -2rem;
  @media (max-width: 750px) {
    width: 90%;
    top: 10rem;
    left: 2rem;
  }
`;

const LinkExample = styled.p`
  font-size: 0.7rem;
  text-decoration-line: underline;
  margin: 0;
`;
const BarraInfo = styled.p`
  display: ${(props) => (props.info ? "block" : "none")};
  border-top: 2px solid rgb(158, 168, 187);
  min-width: 7rem;
  margin: 50px 0 0 0;
`;

export default ObrasEstudiosEditForm;
