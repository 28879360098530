import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MainTitle } from "../common/StyledComponents";

import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../components";
import ListProvidersBlocked from "./landingPages/components/ListProvidersBlocked";

const ProvidersBlocked = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [classTable, setClassTable] = useState("");

  return (
    <>
      <section className={`buscador ${classTable}`}>
        <Container>
          <Row>
            <Col>
              <div className={"card"}>
                <div className={"card-title p-3"}>
                  <div className={"d-flex justify-content-between"}>
                    <MainTitle>Proveedores Bloqueados</MainTitle>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <div className={"card-body pt-0"}>
            <ListProvidersBlocked loggin={true} setClass={setClassTable} />
        </div>
      </section>
    </>
  );
};

export default ProvidersBlocked;

