import { useEffect, useState } from "react";
import * as XLSX from "xlsx-js-style";
import FileSaver from "file-saver";
import { Button } from "react-bootstrap";

const ParticipatingCompaniesReport = ({
  datos,
  className,
  currentHeaders,
  doDownload,
  doShowModal,
}) => {
  const ALLOW_KEYS = [
    "workCode",
    "work",
    "providerCode",
    "providerName",
    "providerPostalDirection",
    "providerPhone",
    "providerEmail",
    "contact",
    "category",
    "complianceWithTheDeadline",
    "technicalCapacity",
    "collaboration",
    "securityAndHealth",
    "complianceWithAgreedPrices",
    "qualityOfWork",
    "economicCapacity",
    "commitmentToTheEnvironment",
    "outsourcing",
    "problemsWithTheDeliveryOfTheWorkBulletins",
    "postSaleCommitment",
    "iWouldWorkWithHimAgain",
    "score",
  ];

  const ALLOW_KEYS_HEADERS = [
    "workCode",
    "work",
    "providerCode",
    "providerName",
    "providerPostalDirection",
    "providerPhone",
    "providerEmail",
    "contact",
    "category",
  ];

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (datos.length > 0 && doDownload) {
      handleDoDownload(currentHeaders);
    }
  }, [doDownload]);

  const getFormattedDate = (date) => {
    const today = date;
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "/" + mm + "/" + yyyy;
  };

  const defaultCell = {
    v: "",
    t: "s",
    s: {
      font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
      fill: { fgColor: { rgb: "0070C0" } },
    },
  };

  const defaultCellBlank = {
    v: "",
    t: "s",
    s: {
      font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
      fill: { fgColor: { rgb: "FFFFFF" } },
    },
  };

  const firstHeaders = (array) => {
    const headers1 = {};
    const today = getFormattedDate(new Date());
    const userData = JSON.parse(localStorage.getItem("jdo-userData"));

    array.forEach((h, index) => {
      // if (h.key === "workCode") {
      if ( Math.round((array.length - 1) * 0 / 100) === index ) {
        headers1[h.label] = {
          v: "OBRA",
          t: "s",
          s: {
            font: {
              bold: true,
              color: { rgb: "FFFFFF" },
              name: "Arial",
              sz: 12,
            },
            fill: { fgColor: { rgb: "0070C0" } },
          },
        };
      }

      if ( Math.round((array.length - 1) * 10 / 100) === index ) {
      // if (h.key === "work") {
        headers1[h.label] = {
          v: datos[0]?.work,
          t: "s",
          s: {
            font: {
              bold: true,
              color: { rgb: "FFFFFF" },
              name: "Arial",
              sz: 12,
            },
            fill: { fgColor: { rgb: "0070C0" } },
          },
        };
      }

      if (Math.round((array.length - 1) * 40 / 100) === index) {
        headers1[h.label] = {
          v: "EVALUADO POR:",
          t: "s",
          s: {
            font: {
              bold: true,
              color: { rgb: "FFFFFF" },
              name: "Arial",
              sz: 12,
            },
            fill: { fgColor: { rgb: "0070C0" } },
          },
        };
      }

      if (Math.round((array.length - 1) * 50 / 100) === index) {
        headers1[h.label] = {
          v: userData?.nombre?.toUpperCase(),
          t: "s",
          s: {
            font: {
              bold: true,
              color: { rgb: "FFFFFF" },
              name: "Arial",
              sz: 12,
            },
            fill: { fgColor: { rgb: "0070C0" } },
          },
        };
      }

      if (Math.round((array.length - 1) * 80 / 100) === index) {
        headers1[h.label] = {
          v: "FECHA INFORME",
          t: "s",
          s: {
            font: {
              bold: true,
              color: { rgb: "FFFFFF" },
              name: "Arial",
              sz: 12,
            },
            fill: { fgColor: { rgb: "0070C0" } },
          },
        };
      }

      if (Math.round((array.length - 1) * 90 / 100) === index) {
        headers1[h.label] = {
          v: today,
          t: "s",
          s: {
            font: {
              bold: true,
              color: { rgb: "FFFFFF" },
              name: "Arial",
              sz: 12,
            },
            fill: { fgColor: { rgb: "0070C0" } },
          },
        };
      }

      if (!ALLOW_KEYS.includes(h.key)) {
        headers1[h.label] = defaultCell;
      }
    });

    array.forEach((h) => {
      if (!headers1[h.label]) {
        headers1[h.label] = defaultCell;
      }
    });

    return headers1;
  }

  const secondHeaders = (array) => {
    const headers2 = {};

    array.forEach((h) => {
      headers2[h.label] = defaultCellBlank;
    });

    return headers2;
  }

  const thirdHeaders = (array) => {
    const headers3 = {};

    array.forEach((h, index) => {
      if ( 0 === index) {
      // if (h.key === "workCode") {
        headers3[h.label] = {
          v: "INFORME DE EVALUACIÓN DE PROVEEDORES.",
          t: "s",
          s: {
            font: {
              bold: true,
              color: { rgb: "FFFFFF" },
              name: "Arial",
              sz: 12,
            },
            fill: { fgColor: { rgb: "002060" } },
          },
        };
      }
      // if (h.key !== "workCode") {
      if ( 0 !== index) {
        headers3[h.label] = defaultCell;
      }
    });

    return headers3;
  }

  const firstFooter = (array) => {
    const footer1 = {};

    array.forEach((h) => {
      if (h.key === "work") {
        footer1[h.label] = {
          v: "DE 0,00 A 1,99",
          t: "s",
          s: {
            font: {
              bold: true,
              color: { rgb: "FFFFFF" },
              name: "Arial",
              sz: 12,
            },
            fill: { fgColor: { rgb: "D52D3E" } },
          },
        };
      }
      if (h.key === "providerCode") {
        footer1[h.label] = {
          v: "MEJORABLE",
          t: "s",
          s: {
            font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
          },
        };
      }
      if (h.key !== "work" && h.key !== "providerCode") {
        footer1[h.label] = defaultCellBlank;
      }
    });

    return footer1;
  }

  const secondFooter = (array) => {
    const footer2 = {};

    array.forEach((h) => {
      if (h.key === "work") {
        footer2[h.label] = {
          v: "DE 2,00 A 2,50",
          t: "s",
          s: {
            font: {
              bold: true,
              color: { rgb: "FFFFFF" },
              name: "Arial",
              sz: 12,
            },
            fill: { fgColor: { rgb: "427B1C" } },
          },
        };
      }
      if (h.key === "providerCode") {
        footer2[h.label] = {
          v: "NORMAL",
          t: "s",
          s: {
            font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
          },
        };
      }
      if (h.key !== "work" && h.key !== "providerCode") {
        footer2[h.label] = defaultCellBlank;
      }
    });

    return footer2;
  }

  const thirdFooter = (array) => {
    const footer3 = {};

    array.forEach((h) => {
      if (h.key === "work") {
        footer3[h.label] = {
          v: "DE 2,51 A 3,00",
          t: "s",
          s: {
            font: {
              bold: true,
              color: { rgb: "FFFFFF" },
              name: "Arial",
              sz: 12,
            },
            fill: { fgColor: { rgb: "370BFE" } },
          },
        };
      }
      if (h.key === "providerCode") {
        footer3[h.label] = {
          v: "EXCELENTE",
          t: "s",
          s: {
            font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
          },
        };
      }
      if (h.key !== "work" && h.key !== "providerCode") {
        footer3[h.label] = defaultCellBlank;
      }
    });

    return footer3;
  }

  const processHeaders = (array) => {
    const processedHeaders = {};
    const objectsB = {};
    const objectsA = {};
    array.forEach((h) => {
      objectsB[h.key] = h.label;
      objectsA[h.label] = h.key;
      let fontSize = 12;
      if (!ALLOW_KEYS_HEADERS.includes(h.key)) {
        fontSize = 8;
      }
      processedHeaders[h.label] = {
        v: h.label,
        t: "s",
        s: {
          font: { color: { rgb: "000000" }, name: "Arial", sz: fontSize },
          fill: { fgColor: { rgb: "A5A5A5" } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
          alignment: { wrapText: true, vertical: "center" },
        },
      };
    });

    return { processedHeaders, objectsB, objectsA }

  }

  const objectProcessed = (value, fill, objectsB) => {
    const object = {};
    Object.entries(value).forEach((v) => {
      let newFill = fill;
      let color = "000000";
      let t = "s";
      let bold = false;
      if (v[0] === "score") {
        color = "FFFFFF";
        t = "n";
        bold = true;
        if (v[1] !== "" && v[1] >= 0.0 && v[1] <= 1.99) {
          newFill = "D52D3E";
        }
        if (v[1] !== "" && v[1] >= 2.0 && v[1] <= 2.5) {
          newFill = "427B1C";
        }
        if (v[1] !== "" && v[1] >= 2.51 && v[1] <= 3.0) {
          newFill = "370BFE";
        }
        if (v[1] !== "" && v[1] > 3.0) {
          newFill = "370BFE";
        }
      }
      if (!ALLOW_KEYS_HEADERS.includes(v[0])) {
        t = "n";
      }
      object[`${objectsB[v[0]]}`] = {
        v: v[1],
        t,
        s: {
          font: { bold, color: { rgb: color }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: newFill } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
        },
      };

      if (v[0] === "score") {
        object[`${objectsB[v[0]]}`] = {
          ...object[`${objectsB[v[0]]}`],
          z: "0.00",
          s: {
            ...object[`${objectsB[v[0]]}`].s,
            alignment: { horizontal: "center" },
          },
        };
      }
    });
    return object;
  };

  const handleProcessedInfo = (array) => {
    setLoading(true);

    const { processedHeaders, objectsB, objectsA } = processHeaders(array);

    const headersList = array.map((item) => item.key);

    const headers1 = firstHeaders(array);
    const headers2 = secondHeaders(array);
    const headers3 = thirdHeaders(array);

    const footer1 = firstFooter(array);
    const footer2 = secondFooter(array);
    const footer3 = thirdFooter(array);

    const processedData = datos.map((d, i) => {
      let fill = "EDEDED";
      if (i % 2 !== 0) {
        fill = "FFFFFF";
      }
      return objectProcessed(d, fill, objectsB);
    });

    let processInfo = [
      headers2,
      headers2,
      headers1,
      headers2,
      processedHeaders,
      ...processedData,
      headers2,
      footer1,
      footer2,
      footer3,
    ];

    const columnLength = {
      workCode: [],
      work: [],
      providerCode: [],
      providerName: [],
      providerPostalDirection: [],
      providerPhone: [],
      providerEmail: [],
      contact: [],
      category: [],
      complianceWithTheDeadline: [],
      technicalCapacity: [],
      collaboration: [],
      securityAndHealth: [],
      complianceWithAgreedPrices: [],
      qualityOfWork: [],
      economicCapacity: [],
      commitmentToTheEnvironment: [],
      outsourcing: [],
      problemsWithTheDeliveryOfTheWorkBulletins: [],
      postSaleCommitment: [],
      iWouldWorkWithHimAgain: [],
      score: [],
    };

    processInfo?.forEach((c, i) => {
      Object?.keys(c).forEach((o) => {
        if (columnLength[objectsA[o]] === undefined) {
          columnLength[objectsA[o]] = [];
        }
        columnLength[objectsA[o]].push(
          processInfo[i][o].v?.length ? processInfo[i][o].v?.length : 0
        );
      });
    });

    processInfo = [headers3, ...processInfo];

    const maxLength = [];
    if (headersList.includes('workCode')) {
      maxLength.push({ wch: Math.max(...columnLength.workCode) });
    }
    if (headersList.includes('work')) {
      maxLength.push({ wch: Math.max(...columnLength.work) });
    }
    maxLength.push({ wch: 13.57 });
    if (headersList.includes('providerName')) {
      maxLength.push({ wch: Math.max(...columnLength.providerName) });
    }
    if (headersList.includes('providerPostalDirection')) {
      maxLength.push({ wch: Math.max(...columnLength.providerPostalDirection) });
    }
    maxLength.push({ wch: 13.57 });
    if (headersList.includes('providerEmail')) {
      maxLength.push({ wch: Math.max(...columnLength.providerEmail) });
    }
    if (headersList.includes('contact')) {
      maxLength.push({ wch: Math.max(...columnLength.contact) });
    }
    if (headersList.includes('category')) {
      maxLength.push({ wch: Math.max(...columnLength.category) });
    }
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    maxLength.push({ wch: 10 });
    setLoading(false);

    return { processInfo, maxLength };
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";


  const exportToCSV = (csvData, fileName, wscols, maxLength, array) => {
    const { headersList, headersListString } = generateHeaders(array);

    const ws = XLSX.utils.json_to_sheet(headersList, {
      header: headersListString,
      skipHeader: true,
    });
    ws["!cols"] = wscols;
    ws["!cols"] = maxLength;
    ws["!rows"] = [
      { hpt: "" },
      { hpt: "" },
      { hpt: "" },
      { hpt: "" },
      { hpt: "" },
      { hpt: 45.75 },
    ];
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: (headersListString.length - 1) } },
      // { s: { r: 3, c: 1 }, e: { r: 3, c: 5 } },
      // { s: { r: 3, c: 6 }, e: { r: 3, c: 7 } },
      // { s: { r: 3, c: 8 }, e: { r: 3, c: 12 } },
      // { s: { r: 3, c: 13 }, e: { r: 3, c: 15 } },
      // { s: { r: 3, c: 16 }, e: { r: 3, c: 18 } },
    ];
    ws["!merges"] = merge;
    XLSX.utils.sheet_add_json(ws, csvData, {
      header: headersListString,
      skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataExcel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataExcel, fileName + fileExtension);
  };

  const handleDoDownload = (array) => {

    const { processInfo, maxLength } = handleProcessedInfo(array);

    exportToCSV(
      processInfo,
      `${datos[0]?.work?.substr(
        0,
        30
      )}-INFORME-PROVEEDORES-EVALUADOS`.replaceAll(" ", "-"),
      "",
      maxLength,
      array,
    );
  };

  const generateHeaders = (array) => {
    const headersList = [];
    const headersListString = [];
    array.forEach((h) => {
      headersList[h.key] = h.label;
      headersListString.push(h.label);
    });
    const objectsB = {};

    Object.assign(objectsB, headersList);

    return { headersList, headersListString, objectsB };
  }

  return (
    <div>
      {!loading ? (
        <div>
          <Button
            className={className}
            variant="secondary"
            onClick={() => doShowModal()}
          >
            Informe Proveedores Evaluados
          </Button>
        </div>
      ) : (
        <div>cargando...</div>
      )}
    </div>
  );
};

export default ParticipatingCompaniesReport;
