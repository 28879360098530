import React, { useEffect, useState } from "react";

import { Formik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { Button } from "rebass/styled-components";
import { Box, Flex } from "reflexbox";
import styled from "styled-components";
import * as Yup from "yup";

import Error from "../../common/Error";
import appServices from "../../logic/services";


const initial = {
  categoria: "",
  proveedor: null,
  categoriasValores: [],
};

const validacion = Yup.object().shape({
  proveedor: Yup.object().required("obligatorio"),
});

const ObrasEOfertaProveedorForm = ({ item, doSave }) => {
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const history = useHistory();

  const styles = {
    multiValueRemove: (base, state) => {
      return !isAdmin ? { ...base, display: "none" } : base;
    },
  };

  const [datos, setDatos] = useState({});
  const [proveedors, setProveedors] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("jdo-userData"));
    if (userData.role && userData.role.type === "admin") {
      setIsAdmin(true);
    }

    // const getAll = async () => {
    //   try {
    //     const categorias = await appServices.getCategorias();
    //     const categoriasSelect = categorias.json.map((c) => ({
    //       label: c.nombre,
    //       value: c.id,
    //     }));
    //     setCategorias(categoriasSelect);
    //   } catch (err) {
    //     toast.error(`${t("error")}`);
    //   }
    // };

    if (item) {
      // getAll();
      setDatos({ ...item });
    }
  }, [item]);

  useEffect(() => {
    console.log(`datos`, datos);
  }, [datos]);

  const onSubmit = (data, { setSubmitting }) => {
    const newData = cleanForm(data);
    doSave(newData);
  };

  const handleGetProveedores = async (busca) => {
    try {
      const proveedores = await appServices.getProveedoresAsync(busca);
      const data = proveedores.json
      const proveedoresSelect = data?.map((p) => ({
        label: p.nombre,
        value: p.id,
        categorias: p.categorias.map((c) => ({
          label: c.nombre,
          value: c.id,
        })),
      }));

      setProveedors(proveedoresSelect);
      return proveedoresSelect;
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  };

  const handleGetCategorias = async (busca) => {
    const token = localStorage.getItem("jdo-userToken");
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/categorias?nombre_contains=${busca}&_limit=-1`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        throw Error("Bad request");
      }
      const data = await res.json();
      const categoriasSelect = data.map((p) => ({
        label: `${p.nombre}`,
        value: p.id,
        categoria: p,
      }));
      // setProveedors(proveedoresSelect);
      return categoriasSelect;
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  };

  const cleanForm = (data) => {
    delete data.user;
    delete data.capitulos;
    delete data.comunidad;
    delete data.provincia;
    delete data.municipio;
    delete data.poblacion;
    delete data.titulo;
    delete data.constructora;
    delete data.direccion;
    delete data.cp;
    delete data.fecha_final;
    delete data.final;
    delete data.created_at;
    delete data.updated_at;
    const newData = { ...data };

    return newData;
  };

  if (!isLoading) {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...initial, ...datos }}
          validationSchema={validacion}
          validateOnMount={false}
          validateOnBlur={true}
          validateOnChange={true}
          initialTouched={{ proveedor: true }}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            isValid,
            isSubmiting,
            setFieldValue,
            setFieldTouched,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  {/* ******************* left */}
                  <Col>
                    {/* field */}
                    <Row>
                      <Col>
                        {/* field */}
                        <Row>
                          <Col md={8}>
                            <Form.Group controlId='titulo'>
                              <Form.Label>{t('titulo')}*</Form.Label>
                              <Form.Control
                                name='titulo'
                                type='text'
                                value={values.titulo || ''}
                                onChange={handleChange}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          {/* end field */}
                          <Col md={4}>
                            {/* field */}
                            <Form.Group controlId='fecha_final'>
                              <Form.Label>{t('fecha_final')}</Form.Label>
                              <Form.Control
                                type='date'
                                name='fecha_final'
                                value={values.fecha_final || ''}
                                onChange={handleChange}
                                disabled
                              />
                            </Form.Group>
                            {/* end field */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* end field */}

                    {/* field */}
                    <Row>
                      <Col>
                        <Form.Group controlId='constructora'>
                          <Form.Label>{t('constructora')}*</Form.Label>
                          <Form.Control
                            name='constructora'
                            type='text'
                            value={values.constructora || ''}
                            onChange={handleChange}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* end field */}

                    <Row>
                      <Col md={6}>
                        <Row>
                          {/* field */}
                          <Col md={12}>
                            <Form.Group controlId='proveedor'>
                              <Form.Label>{t('proveedor')}*</Form.Label>
                              <Error
                                touched={touched.proveedor}
                                message={errors.proveedor}
                              />
                              <AsyncSelect
                                placeholder='Añadir nuevo proveedor de la lista...'
                                loadOptions={handleGetProveedores}
                                isSearchable={true}
                                isClearable={true}
                                value={values.proveedor ? values.proveedor : ''}
                                onChange={(option) => {
                                  setFieldValue('proveedor', option || null);
                                  setFieldValue(
                                    'categoriasValores',
                                    option ? [...option.categorias] : null
                                  );
                                }}
                              />

                              <Form.Label style={{ marginTop: '16px' }}>
                                Categorias del proveedor:
                              </Form.Label>

                              {values.proveedor &&
                                values.proveedor.categorias &&
                                values.proveedor.categorias.map((c, index) => {
                                  return (
                                    <div key={index}>
                                      <Flex mt={2} alignItems='center'>
                                        <Box>{c.label}</Box>
                                      </Flex>
                                    </div>
                                  );
                                })}

                              <Form.Text className='text-muted'></Form.Text>
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* end field */}
                        <Row>
                          {/* field */}
                          <Col md={12}>
                            <Form.Group controlId='categoria'>
                              <Form.Label>
                                Añadir categorias adicionales:
                              </Form.Label>

                              <AsyncSelect
                                placeholder='Añadir nueva categoria de la lista...'
                                loadOptions={handleGetCategorias}
                                isSearchable={true}
                                isClearable={true}
                                value={values.categoria ? values.categoria : ''}
                                onChange={(option) => {
                                  setFieldValue(
                                    'categoriasValores',
                                    option
                                      ? [
                                          ...values.categoriasValores,
                                          {
                                            value: option.categoria.id,
                                            label: option.categoria.nombre,
                                          },
                                        ]
                                      : null
                                  );
                                }}
                              />

                              <Form.Text className='text-muted'></Form.Text>
                            </Form.Group>
                          </Col>
                          {/* end field */}
                        </Row>
                        {/* end field */}
                      </Col>

                      <Col md={6}>
                        <h6>Añadir proveedor a las categorias:</h6>
                        {values.categoriasValores &&
                          values?.categoriasValores?.map((c, index) => (
                            <Flex key={index} mt={2} alignItems='center'>
                              <Box flex={1}>{c.label}</Box>
                              <Box>
                                <Button
                                  type='button'
                                  variant='danger'
                                  onClick={() => {
                                    // values.categoriasValores =
                                    setFieldValue(
                                      'categoriasValores',
                                      values?.categoriasValores?.filter((f) => {
                                        console.log(`c`, c);
                                        console.log(`f`, f);
                                        return f.value !== c.value;
                                      })
                                    );
                                  }}
                                >
                                  x
                                </Button>
                              </Box>
                            </Flex>
                          ))}
                      </Col>
                    </Row>
                  </Col>
                  {/* ******************* end left */}
                </Row>

                <Barra />
                <Flex justifyContent='flex-end'>
                  <Box mr={2}>
                    <Button
                      variant='secondary'
                      onClick={() => history.push(`/obras/detalles/${item.id}`)}
                    >
                      {t('cancelar')}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      px={80}
                      variant='primary'
                      type='submit'
                      disabled={
                        isLoading ||
                        !isValid ||
                        values?.categoriasValores?.length === 0
                      }
                    >
                      {t('add')}
                    </Button>
                  </Box>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
};

const Barra = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #8c8b8b;
`;

export default ObrasEOfertaProveedorForm;
