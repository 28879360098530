import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Collapse } from "bootstrap";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import Button from "../button";
import useAppContext from "../../data/store";
import * as _ from "lodash";
import { ModalOfEInforma } from "../../common/Modals/ModalOfEInforma";
import { getEInformaToken } from "../../logic/einforma";
import logo from '../../img/logo.svg';


const publicMenu = (history) => (
  <div className="collapse navbar-collapse" id="collapseTarget">
    <ul className="navbar-nav m-auto my-2 my-lg-0 navbar-nav-scroll">
      <li className="nav-item">
        <LinkContainer to="/inicio">
          <Nav.Link
            active={history.location.pathname.includes("/inicio")}
            className={"nav-link"}
          >
            Inicio
          </Nav.Link>
        </LinkContainer>
      </li>
      <li className="nav-item">
        <LinkContainer to="/quienes-somos">
          <Nav.Link
            active={history.location.pathname.includes("/quienes-somos")}
            className={"nav-link"}
          >
            Quiénes somos
          </Nav.Link>
        </LinkContainer>
      </li>
      <li className="nav-item">
        <LinkContainer to="/que-hacemos">
          <Nav.Link
            active={history.location.pathname.includes("/que-hacemos")}
            className={"nav-link"}
          >
            Qué hacemos
          </Nav.Link>
        </LinkContainer>
      </li>
      <li className="nav-item">
        <LinkContainer to="/video-tutoriales">
          <Nav.Link
            active={history.location.pathname.includes("/video-tutoriales")}
            className={"nav-link"}
          >
            Vídeos tutoriales
          </Nav.Link>
        </LinkContainer>
      </li>
    </ul>
    <div className="d-flex action-buttons">
      <Button
        text={"Ingresa"}
        className={"btn-secondary"}
        icon={{ prefix: "fas", iconName: "user" }}
        mx={"mx-2"}
        onClick={() => history.push("/iniciar-sesion")}
      />
      <Button
        text={"Regístrate"}
        className={"btn-primary"}
        icon={{ prefix: "fas", iconName: "user-edit" }}
        onClick={() => history.push("/registro")}
      />
    </div>
  </div>
);

const userMenu = (history, handleLogout, isAdmin, myBusiness, setShowModal) => {
  const user = JSON.parse(localStorage.getItem("jdo-userData"));
  const typeRole = user.role.type;

  return (
    <div className="collapse navbar-collapse" id="collapseTarget">
      <ul className="navbar-nav m-auto my-2 my-lg-0 navbar-nav-scroll">
        {typeRole === "proveedor_o_subcontratista" ? (
          <>
            <li className="nav-item">
              <LinkContainer to="/full-execution">
                <Nav.Link
                  active={history.location.pathname.includes("full-execution")}
                  className={"nav-link"}
                >
                  Listado de obras de constructora
                </Nav.Link>
              </LinkContainer>
            </li>
            <li className="nav-item">
              <LinkContainer to="/full">
                <Nav.Link
                  active={history.location.pathname.includes("full")}
                  className={"nav-link"}
                >
                  Proveedores
                </Nav.Link>
              </LinkContainer>
            </li>
            { myBusiness === 'no-business' ? (
              <Button
                text={"Registrar mi empresa"}
                className={"btn-primary"}
                icon={{ prefix: "fas", iconName: "building" }}
                onClick={() => setShowModal(true)}
              />
            ) : (
              <li className="nav-item">
                <LinkContainer to={myBusiness}>
                  <Nav.Link
                    active={history.location.pathname.includes("full")}
                    className={"nav-link"}
                  >
                    Mi empresa
                  </Nav.Link>
                </LinkContainer>
              </li>
            ) }
          </>
        ) : null}
        {typeRole !== "proveedor_o_subcontratista" ? (
          <>
            <li className="nav-item">
              <LinkContainer to="/estudios">
                <Nav.Link
                  active={history.location.pathname.includes("estudios")}
                  className={"nav-link"}
                >
                  Estudios
                </Nav.Link>
              </LinkContainer>
            </li>
            <li className="nav-item">
              <LinkContainer to="/ejecucion">
                <Nav.Link
                  active={history.location.pathname.includes("ejecucion")}
                  className={"nav-link"}
                >
                  Obras en Curso
                </Nav.Link>
              </LinkContainer>
            </li>
            <li className="nav-item">
              <LinkContainer to="/obras">
                <Nav.Link
                  active={history.location.pathname.includes("obras")}
                  className={"nav-link"}
                >
                  Obras ejecutadas
                </Nav.Link>
              </LinkContainer>
            </li>
            <li className="nav-item">
              <LinkContainer to="/full">
                <Nav.Link
                  active={history.location.pathname.includes("full")}
                  className={"nav-link"}
                >
                  Proveedores
                </Nav.Link>
              </LinkContainer>
            </li>
            {typeRole === "jefe_de_compras" || typeRole === "constructora" ? (
              <li className="nav-item">
                <LinkContainer to="/full-blocked">
                  <Nav.Link
                    active={history.location.pathname.includes("full-blocked")}
                    className={"nav-link"}
                  >
                    Proveedores Bloqueados
                  </Nav.Link>
                </LinkContainer>
              </li>
            ) : null}
          </>
        ) : null}
        {(typeRole === 'admnistrador' || typeRole === 'administrador' || isAdmin) && (
          <li className="nav-item">
            <LinkContainer to="/usuarios">
              <Nav.Link
                active={history.location.pathname.includes("usuarios")}
                className={"nav-link"}
              >
                Usuarios
              </Nav.Link>
            </LinkContainer>
          </li>
        )}
      </ul>
      <div className="d-flex action-buttons">
        <Link to="/me">
          <div
            data-tip
            data-for="perfil"
            className="mx-3 rounded-circle border d-flex justify-content-center align-items-center avatar"
          >
            <div className={"text-secondary"}>
              <FontAwesomeIcon icon={["fas", "user"]} />
            </div>
          </div>
          <ReactTooltip id="perfil" place="bottom">
            <span>Mi Perfil</span>
          </ReactTooltip>
        </Link>
        <Button
          text={"Cerrar sesión"}
          className={"btn-primary"}
          icon={{ prefix: "fas", iconName: "sign-out-alt" }}
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

const NavBar = () => {
  const history = useHistory();
  const [{ user }, dispatch] = useAppContext();
  const [isLogged, setIsLogged] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [myBusiness, setMyBusiness] = useState('/full');
  const [showModal, setShowModal] = useState(false);
  const [datoseInforma, setDatosInforma] = useState([]);
  const [fullscreenModal, setFullscreenModal] = useState(true);

  const location = useLocation();
  if (location.pathname === "/inicio" && history.action === "REPLACE") {
    window.location.reload();
  }

  useEffect(() => {
    const logged =
      !_.isNull(localStorage.getItem("jdo-userToken")) || user.logged;
    setIsLogged(logged);
    const role =
      !_.isNull(localStorage.getItem("jdo-userData")) &&
      JSON.parse(localStorage.getItem("jdo-userData")).role.name;
    const isAdmin = (role && role === "Administrador") || user.isAdmin;
    setIsAdmin(isAdmin);
  }, [user]);

  useEffect(() => {
    setToggle(false);
    const myCollapse = document.getElementById("collapseTarget");
    const bsCollapse = new Collapse(myCollapse, { toggle: false });
    toggle ? bsCollapse.show() : bsCollapse.hide();
  }, [toggle, location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("jdo-userData");
    localStorage.removeItem("jdo-userToken");
    localStorage.removeItem("jdo-filtro-admin-provincia");
    localStorage.removeItem("jdo-filtro-admin-categoria");
    localStorage.removeItem("jdo-filtro-proveedor");
    localStorage.removeItem("jdo-filtro-obra-pagina");
    localStorage.removeItem("jdo-filtro-proveedor-pagina");
    dispatch({
      origin: "user",
      type: "LOGGED",
      isLogged: false,
    });
    window.location.href = "/";
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const getEInformaData = async (empresa) => {
    const getFromEmpresa = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_API_EINFORMA}/companies?companySearch=${empresa}&rows=20`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${await getEInformaToken()}`,
          },
        }
      );
      const data = await res.json();
      setDatosInforma(data.empresa);
    };

    try {
      getFromEmpresa();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSearchOurBusiness = async () => {
    try {
      const token = localStorage.getItem("jdo-userToken");
      const user = JSON.parse(localStorage.getItem("jdo-userData"));
      const search = user?.empresa;
      let headers =  {
        "Content-Type": "application/json",
      };

      if (token !== undefined && token !== null) {
        headers =  {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/proveedors/search`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            busca: search,
            filtros: {
              subcontratista: true,
              distribuidor: true,
              fabricante: true,
              calificado: false,
              conweb: false,
            },
            _limit: 10,
            _start: 0,
          }),
        }
      );
      if (res.status !== 200) {
        throw Error("Bad request");
      }
      let location = '';
      const data = await res.json();
      if (data?.items?.length > 0) {
        location = `/full/edit/${data?.items[0]?.id}`;
      } else {
        location = `no-business`;
        getEInformaData(search);
      }
      setMyBusiness(location);
    } catch (err) {
      console.log("err :>> ", err);
    }
  }

  useEffect(() => {
    handleSearchOurBusiness();
  }, []);

  const newUser = JSON.parse(localStorage.getItem("jdo-userData"));

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light px-3 fixed-top ">
      <div className="container-fluid">
        <Link className={"navbar-brand"} to={"/inicio"}>
          {/* <div className={"logo"}>wikiobra.com</div> */}
          <img src={logo} alt="Jefe de Obra" width="150" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setToggle((toggle) => !toggle)}
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        {isLogged
          ? userMenu(history, handleLogout, isAdmin, myBusiness, setShowModal)
          : publicMenu(history)}
        <ModalOfEInforma
          show={showModal}
          datos={datoseInforma}
          doClose={handleCloseModal}
          fullscreen={fullscreenModal}
          title={`"${newUser?.empresa}" no está en la base de datos de Wikiobra, pero puedes añadirla seleccionando la empresa del listado siguiente`}
        />
      </div>
    </nav>
  );
};

export default NavBar;
