import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../components";
import { MainTitle } from "../common/StyledComponents";

import ObrasList from "../components/obras/ObrasList";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

const MisEjecucion = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("jdo-userData"));
    const typeRole = user.role.type;
    if (typeRole === "proveedor_o_subcontratista") {
      window.location = '/full-execution';
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div className={"card p-3"}>
            <div className={"card-title"}>
              <div className={"d-flex justify-content-between"}>
                <MainTitle>{t("mis_obras_en_ejecucion")}</MainTitle>
                <Button  text={"Nueva Obra"} className={"btn-primary"} icon={{ prefix: "fas", iconName: "plus" }} onClick={() => history.push("ejecucion/new")}  />
              </div>
            </div>
            <div className={"card-body pt-0"}>
              <ObrasList final={false} withListado={true} />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MisEjecucion;
