export const searchBlockedProviders = async (business) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(
      `${ API_URL }/proveedores-bloqueados/?business=${business}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status !== 200) {
      throw Error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.log("err :>> ", err);
  }
}

export const createdBlockedProviders = async (items) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(
      `${ API_URL }/proveedores-bloqueados`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(items)
      }
    );
    if (res.status !== 200) {
      throw Error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.log("err :>> ", err);
  }
}

export const updatedBlockedProviders = async (id, items) => {
  const token = localStorage.getItem("jdo-userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(
      `${ API_URL }/proveedores-bloqueados/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(items)
      }
    );
    if (res.status !== 200) {
      throw Error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.log("err :>> ", err);
  }
}
