import React from "react";

import Switch from "react-switch";
import styled from "styled-components";
import {
  Etiqueta,
  Nombre,
  Valor,
} from '../../common/StyledComponents';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UsuariosListItem = ({ item, isActive, doChangeUser }) => {
  return (
    <>
      <div className="item-list">
        <div className={"row"}>
          <div
            className={"col"}
          >
            <Nombre>{item.nombre || "-"}</Nombre>
          </div>
          <div className="row">
            <div className="col-12 col-md">
              <Valor>
                <span className="table-item-title">{item.email || "-"}</span>
              </Valor>
              <Etiqueta>
                <span className="table-item-subtitle">Correo electrónico</span>
              </Etiqueta>
            </div>
            <div className="col-12 col-md">
              <Valor>
                <span className="table-item-title">
                  {item.linkedin ? (<>
                    {item.linkedin}
                    <a className={"mx-1"} href={`https://linkedin.com/in/${item.linkedin}`} target="_blank">
                      <FontAwesomeIcon icon={{ prefix: "fab", iconName: "linkedin" }} />
                    </a>
                  </>) : "Sin información"}
                </span>
              </Valor>
              <Etiqueta>
                <span className="table-item-subtitle">
                  Linkedin
                </span>
              </Etiqueta>
            </div>
            <div className="col-1">
              <Valor>
                <span className="table-item-title">
                  <Switch
                    onChange={() => doChangeUser(item.id, item.confirmed)}
                    checked={item.confirmed}
                    id="normal-switch"
                    offColor="#ff0000"
                  />
                </span>
              </Valor>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const StyledH6 = styled.h6`
  font-size: 16px;
  width: 20%;
  height: 50px;
  padding: 5px;
  font-weight: 300 !important;
  color: black;
`;
const StyledH6Name = styled.h6`
  font-size: 16px;
  width: 20%;
  height: 50px;
  padding: 5px;
`;

const InLineH6 = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default UsuariosListItem;
