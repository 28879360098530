import React from 'react';
import Contacto from "./components/Contacto";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const WeDo = () => {

    return (
        <>
            <section className={"we-do page-section"}>
                <Container>
                    <Row>
                        <Col xs={"12"} className={"text-center my-5"}>
                            <h4>Qué hacemos</h4>
                            <h5 className={"my-2"}>Cuatro grandes herramientas para hacer tu vida más fácil</h5>
                        </Col>
                        <Col xs={"12"} md={{span: 8, offset: 2}} className={""}>
                            <div className="card mb-4">
                                <div className="row g-0">
                                    <div className="col-md-2 pt-2 pb-2 icon-element color-blue">
                                        <FontAwesomeIcon icon={["fas", "search"]}/>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="card-body">
                                            <h5 className="card-title">Buscador de empresas</h5>
                                            <p className="card-text">
                                                Encuentra fácilmente empresas subcontratistas y proveedores de construcción para tu proyecto. 
                                                Disponemos de un listado que destaca a las principales empresas del sector de la construcción, 
                                                evaluadas según diversos criterios de calidad y experiencia. <br/>
                                                Utiliza nuestros filtros personalizables para identificar rápidamente a los proveedores ideales 
                                                con los que deseas ponerte en contacto, ya sea para solicitar ofertas o explorar posibles colaboraciones.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={"12"} md={{span: 8, offset: 2}} className={""}>
                            <div className="card mb-4">
                                <div className="row g-0">
                                    <div className="col-md-2 pt-2 pb-2 icon-element color-blue">
                                        <FontAwesomeIcon icon={["fas", "star-half-alt"]}/>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="card-body">
                                            <h5 className="card-title">Calificación de empresas</h5>
                                            <p className="card-text">
                                                Puedes asignar puntuaciones a las empresas con las que has colaborado. 
                                                Simplemente registra tu proyecto, especificando el capítulo del proveedor,
                                                y evalúa su rendimiento en función de varios indicadores. Ya sea que la obra 
                                                esté en curso, haya concluido o incluso si el proveedor no figura en nuestra lista, 
                                                tienes la opción de añadirlo tú mismo para evaluar su desempeño.                                                
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={"12"} md={{span: 8, offset: 2}} className={""}>
                            <div className="card mb-4">
                                <div className="row g-0">
                                    <div className="col-md-2 pt-2 pb-2 icon-element color-blue">
                                        <FontAwesomeIcon icon={["far", "paper-plane"]}/>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="card-body">
                                            <h5 className="card-title">Envío de solicitudes de oferta</h5>
                                            <p className="card-text">
                                                Facilitamos el contacto con múltiples proveedores simultáneamente, 
                                                permitiéndote solicitar ofertas para los distintos capítulos que desees 
                                                contratar. Nuestro proceso es sencillo y transparente: selecciona los proveedores 
                                                deseados y envía tu solicitud en menos de 1 minuto. La comunicación se realiza 
                                                directamente a través de tu correo electrónico, garantizando en todo momento 
                                                la confidencialidad de la información.                                                
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={"12"} md={{span: 8, offset: 2}} className={""}>
                            <div className="card mb-4">
                                <div className="row g-0">
                                    <div className="col-md-2 pt-2 pb-2 icon-element color-blue ">
                                        <FontAwesomeIcon icon={["far", "building"]}/>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="card-body">
                                            <h5 className="card-title">Modo empresa </h5>
                                            <p className="card-text">
                                                ¿Ofreces productos o servicios en el sector de la construcción?<br/>
                                                Con nuestro módulo para proveedores, puedes acceder a una base de datos siempre 
                                                actualizada de proyectos en curso dentro de tu área de cobertura. 
                                                Obtén información detallada sobre cada obra, incluyendo los datos de contacto 
                                                de los responsables de la obra, encargados de la producción, entre otros. Así, 
                                                podrás establecer contacto directo con ellos y ofrecer tus servicios de manera 
                                                efectiva.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Contacto/>
        </>
    );
};

export default WeDo;
