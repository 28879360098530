import jwt_decode from "jwt-decode";

export const URL = process.env.REACT_APP_API_URL;

const logic = {
  _userId: localStorage.getItem("jdo-userData") || null,
  _token: localStorage.getItem("jdo-userToken") || null,

  get userId() {
    return JSON.parse(this._userId);
  },

  get token() {
    return this._token;
  },

  // generic call function for all of the API requests
  _call(path, method, body, isFile = false) {
    let queryString = "";

    let theBody = body;

    const headers = {
      Authorization: getToken(),
    };

    if (!isFile) {
      headers["Content-Type"] = "application/json; charset=utf-8";
      theBody = JSON.stringify(body);
    }

    this.getToken();

    return fetch(`${URL}/${path}${queryString ? "/?" + queryString : ""}`, {
      method: method,
      headers,
      body: theBody,
    })
      .then((res) => {
        if (res.ok) {
          if (res.status === 200 || res.status === 201) {
            return res.json().then((json) => ({
              total: res.headers.get("total"),
              status: res.status,
              json,
            }));
          } else {
            return {
              response: "Response returned no content",
              status: res.status,
            };
          }
        } else {
          return res.json().then((json) => {
            return Promise.reject(json);
          });
        }
      })
      .catch((err) => {
        let errorMessage;
        if (err && err.message) {
          errorMessage = err.message;
        }
        if (err && err.error) {
          errorMessage = err.error;
        }
        if (err && err.errors) {
          const errorArray = Object.values(err.errors);
          errorMessage = errorArray.join(" ");
        }
        if (errorMessage === "Failed to Login") {
          this._loggedIn = false;
        }
        console.log("LOGIC ------------ ");
        console.log(
          `Error ${errorMessage} in ${method} request made to:
          URL: ${URL}/
          PATH: ${path}/
          QUERY: ?${queryString}
          `
        );
        console.log("LOGIC ------------ ");
        return Promise.reject({
          status: "error",
          message: errorMessage,
        });
      });
  },

  _query(path, method, body) {
    let theBody = JSON.stringify(body);
    let headers = {};
    headers["Content-Type"] = "application/json; charset=utf-8";

    return fetch(`${URL}/${path}`, {
      method: method,
      headers,
      body: theBody,
    })
      .then((res) => {
        if (res.ok) {
          if (res.status === 200 || res.status === 201) {
            return res.json().then((json) => ({
              total: res.headers.get("total"),
              status: res.status,
              json,
            }));
          } else {
            return {
              response: "Response returned no content",
              status: res.status,
            };
          }
        } else {
          return res.json().then((json) => {
            return Promise.reject(json);
          });
        }
      })
      .catch((err) => {
        let errorMessage;
        if (err && err.message) {
          errorMessage = err.message;
        }
        if (err && err.error) {
          errorMessage = err.error;
        }
        if (err && err.errors) {
          const errorArray = Object.values(err.errors);
          errorMessage = errorArray.join(" ");
        }
        if (errorMessage === "Failed to Login") {
          this._loggedIn = false;
        }
        console.log("LOGIC ------------ ");
        console.log(
          `Error ${errorMessage} in ${method} request made to:
          URL: ${URL}/
          PATH: ${path}/
          `
        );
        console.log("LOGIC ------------ ");
        return Promise.reject({
          status: "error",
          message: errorMessage,
        });
      });
  },
};

export const checkToken = () => {
  const jwt = localStorage.getItem("jdo-userToken");
  if (!jwt) {
    return false;
  } else {
    return true;
  }
};

export const getToken = () => {
  const jwt = localStorage.getItem("jdo-userToken");
  if (!jwt) {
    window.location.href = "/";
  }
  const jwtdecode = jwt_decode(jwt);
  const { exp } = jwtdecode;
  const datainseconds = new Date().getTime() / 1000;
  if (exp < datainseconds) {
    localStorage.clear("userData");
    window.location.href = "/";
  } else {
    return true;
  }
};

export const getUser = () => {
  const jwt = localStorage.getItem("jdo-userToken");
  if (!jwt) {
    return null;
  }
  const jwtdecode = jwt_decode(jwt);
  const { id } = jwtdecode;
  if (id) {
    return id;
  } else {
    return null;
  }
};

export const getValoracion = (oferta) => {
  return oferta && oferta.valoracion && oferta.valoracion.score === null
    ? "white"
    : oferta && oferta.valoracion && oferta.valoracion.score < 2.0
    ? "red"
    : oferta &&
      oferta.valoracion &&
      oferta.valoracion.score >= 2.0 &&
      oferta &&
      oferta.valoracion &&
      oferta.valoracion.score < 2.5
    ? "green"
    : oferta &&
      oferta.valoracion &&
      oferta.valoracion.score >= 2.5 &&
      oferta &&
      oferta.valoracion &&
      oferta.valoracion.score < 3
    ? "blue"
    : oferta && oferta.valoracion && oferta.valoracion.score >= 3
    ? "blue"
    : "white";
};

export const getValoracionScore = (score) => {
  return score === null
    ? "white"
    : score < 2.0
    ? "red"
    : score >= 2.0 && score < 2.5
    ? "green"
    : score >= 2.5 && score < 3
    ? "blue"
    : score >= 3
    ? "blue"
    : "white";
};

export const valoracionCompleta = (items) => {
  if (
    items &&
    items.c01 &&
    items.c02 &&
    items.c03 &&
    items.c04 &&
    items.c05 &&
    items.c06 &&
    items.c07 &&
    items.c08 &&
    items.c09 &&
    items.c10 &&
    items.c11 &&
    items.c12
  ) {
    return true;
  } else {
    return false;
  }
};

export default logic;
