import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import * as _ from "lodash";
import Pagination from "../../../common/Pagination";
import { SIZE_PAGE } from "../../../logic/queryStrings";
import ValoracionScore from "../../../common/ValoracionScore";
import { searchBlockedProviders, updatedBlockedProviders, } from "../../../services/blocked.providers.service";
import { Button, } from "../../../components";
import { Box, Flex } from "reflexbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const ListProvidersBlocked = ({ loggin, setClass = () => {} }) => {
  const [datos, setDatos] = useState([]);
  const [total, setTotal] = useState(null);
  const [classTable, setClassTable] = useState("");
  const [role, setRole] = useState(null);
  const [isLogin, setIsLogin] = useState(null);

  const redirectDetail = (id) =>
    isLogin && window.open(`/full/details/${id}`); // isLogin && history.push(`/full/${role ? "edit" : "details"}/${id}`);
  let className = '';
  if (!loggin) {
    className = 'middle-search';
  }

  useEffect(() => {
    handleLoadBlockedProviders();
  }, []);

  const handleLoadBlockedProviders = async () => {
    const jdoUserData = JSON.parse(localStorage.getItem('jdo-userData'));
    const { empresa } = jdoUserData;
    const blockedProviders = await searchBlockedProviders(empresa);
    setDatos(blockedProviders[0].proveedors);
    setTotal(blockedProviders[0].proveedors.length);
  }

  const handleUnblockedProvider = async (id) => {
    const jdoUserData = JSON.parse(localStorage.getItem('jdo-userData'));
    const { empresa } = jdoUserData;
    const blockedProviders = await searchBlockedProviders(empresa);
    const list = blockedProviders?.[0]?.proveedors?.map((p) => {
      return `${p.id}`;
    });

    if (
      blockedProviders !== null &&
      blockedProviders !== undefined &&
      blockedProviders.length > 0
      ) {
      const blockedId = blockedProviders?.[0]?.id;
      const providers = list?.filter((p) => p !== id);
      const data = {
        ...blockedProviders?.[0],
        proveedors: providers
      };
      await updatedBlockedProviders(blockedId, data);
    }
    window.location.reload();
  }

  const user = JSON.parse(localStorage.getItem("jdo-userData"));
  const typeRole = user.role.type;

  return (
    <>
      <section
        className={`buscador ${classTable} ${loggin === true && "false"}`}
      >
        <Container>
          <Row>
            <Col xs={12} className="content-search align-middle">
              {/* <div className={"middle-search"}> */}
              <div className={className}>
                <div className="card p-3">
                  <div className="card-body">
                    {total === 0 && datos.length === 0 ? (
                      <>
                        <Col xs={12}>
                          <b>Nombre</b> o <b>CIF</b> introducido no encontrado.
                          Introduce otros criterios de búsqueda
                        </Col>
                      </>
                    ) : total > 0 ? (
                      <>
                        <h5 className={"mt-4 mb-2"}>
                          Resultado de la busqueda
                        </h5>
                        <div className="table-responsive">
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Nombre</th>
                                <th>CIF</th>
                                <th>Tipo</th>
                                <th>Provincia</th>
                                <th>Ámbito</th>
                                <th>Web</th>
                                <th>Contacto</th>
                                <th>Teléfono</th>
                                <th>
                                  <span className="d-flex justify-content-center align-items-center">
                                    Calificación
                                    <FontAwesomeIcon
                                      data-tip="Calificación"
                                      data-for="calification-help"
                                      icon={{
                                        prefix: "fa",
                                        iconName: "info-circle",
                                      }}
                                    />
                                  </span>
                                  <ReactTooltip
                                    id="calification-help"
                                    getContent={function () {
                                      return (
                                        <div>
                                          <Flex flexDirection="column">
                                            <Flex alignItems="center" my={1}>
                                              <Box
                                                sx={{
                                                  display: "inline-block",
                                                  color: "white",
                                                  bg: `red`,
                                                  px: 4,
                                                  py: 2,
                                                  borderRadius: 9999,
                                                  textAlign: "center",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "white",
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  DE 0,00 A 1,99
                                                </p>
                                              </Box>
                                              <Box ml={2}>MEJORABLE</Box>
                                            </Flex>

                                            <Flex alignItems="center" my={1}>
                                              <Box
                                                sx={{
                                                  display: "inline-block",
                                                  color: "white",
                                                  bg: `green`,
                                                  px: 4,
                                                  py: 2,
                                                  borderRadius: 9999,
                                                  textAlign: "center",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "white",
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  DE 2,00 A 2,50
                                                </p>
                                              </Box>
                                              <Box ml={2}>NORMAL</Box>
                                            </Flex>

                                            <Flex alignItems="center" my={1}>
                                              <Box
                                                sx={{
                                                  display: "inline-block",
                                                  color: "white",
                                                  bg: `blue`,
                                                  px: 4,
                                                  py: 2,
                                                  borderRadius: 9999,
                                                  textAlign: "center",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    color: "white",
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  DE 2,51 A 3,00
                                                </p>
                                              </Box>
                                              <Box ml={2}>EXCELENTE</Box>
                                            </Flex>
                                          </Flex>
                                        </div>
                                      );
                                    }}
                                  />
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {datos.map((d) => (
                                <tr key={d.id}>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.nombre}
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.cif}
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.tipo_proveedor &&
                                      d.tipo_proveedor.nombre}
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.provincia}
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.ambito}
                                  </td>
                                  <td>
                                    <a href={`${d.web}`} target="_blank">
                                      {d.web
                                        ? d.web[4] === "s"
                                          ? d.web.substr(8)
                                          : d.web.substr(7)
                                        : ""}
                                    </a>
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    {d.contacto}
                                  </td>
                                  <td onClick={() => redirectDetail(d.id)}>
                                    <a href={"tel:" + d.tel1}>{d.tel1}</a>
                                  </td>
                                  <td>
                                    <ValoracionScore
                                      score={d.score}
                                      small={true}
                                    />
                                  </td>
                                  <td>
                                    {typeRole === 'jefe_de_compras' ? (
                                      <Button
                                        text={"Desbloquear"}
                                        className={
                                          "btn-danger btn-sm"
                                        }
                                        onClick={() => handleUnblockedProvider(`${d.id}`)}
                                      />
                                    ) : null }
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div className={"my-2 d-flex justify-content-end"}>
                          <Pagination
                            initialPage={0}
                            totalRecords={+total}
                            pageLimit={SIZE_PAGE}
                            pageNeighbours={2}
                            onPageChanged={(e) => {}}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ListProvidersBlocked;

