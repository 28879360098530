import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Col, Container, Row, Table } from "react-bootstrap";
import * as _ from "lodash";
import { Button } from "../../components";
import { Nombre } from "../../common/StyledComponents";
import DeleteModal from "../../common/DeleteModal";
import ObrasEstudiosOfertasAceptada from "./ObrasEstudiosOfertasAceptada";

const ObrasEstudiosOfertasListItem = ({ obra, participantes, doDelete }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [oferta, setOferta] = useState(null);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const datos_raw = [];
    for (const o of participantes) {
      let i;
      const obj = {
        key: `${i}`,
        obra: obra,
        participante: o.id,
        valoracion: (o.valoracion && o.valoracion) || null,
        proveedor: (o.proveedor && o.proveedor.nombre) || "",
        categoria: (o.categoria && o.categoria.nombre) || "",
        created_at: (o.created_at && o.created_at) || "",
      };
      datos_raw.push(obj);
      i++;
    }

    const datos_sorted = _.sortBy(datos_raw, ["created_at"]);

    setItems(datos_sorted);
  }, [participantes]);

  const handleClose = () => setShow(false);

  const handleDelete = (delete_oferta) => {
    console.log(`delete_oferta`, delete_oferta);
    setOferta(delete_oferta);
    setShow(true);
  };
  const handleConfirm = () => {
    setShow(false);
    doDelete(oferta);
  };

  return (
    <>
      <Container>
        <Row>
          <Col className={"p-0"}>
            <div className={"card p-3"}>
              <div className={"card-title"}>
                <div className={"d-flex justify-content-between"}>
                  <Nombre>{t("proveedores_para_valorar")}</Nombre>
                  <Button
                    text={"Añadir"}
                    className={"btn-primary mt-2"}
                    icon={{ prefix: "fas", iconName: "plus" }}
                    onClick={() =>
                      history.location.pathname.includes("obras")
                        ? history.push(`/obras/${obra}/proveedor`)
                        : history.push(`/ejecucion/${obra}/proveedor`)
                    }
                  />
                </div>
              </div>
              <div className={"card-body pt-0"}>
                <div className="containers table-responsive">
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Proveedor</th>
                        <th>Categoría</th>
                        <th>Calificación</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {items
                        ? items.map((item, index) => (
                            <ObrasEstudiosOfertasAceptada
                              key={index}
                              participante={item.participante}
                              proveedor={item.proveedor}
                              categoria={item.categoria}
                              valoracion={item.valoracion}
                              obra={obra}
                              doDelete={handleDelete}
                            />
                          ))
                        : null}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <DeleteModal
          show={show}
          body={`${t("estas_seguro_de_borrar")} ${
            (oferta && oferta.proveedor && oferta.proveedor.nombre) || ""
          }`}
          doClose={handleClose}
          doConfirm={handleConfirm}
        />
      </Container>
    </>
  );
};

export default ObrasEstudiosOfertasListItem;
