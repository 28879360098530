import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useAppContext from "../../data/store";
import { Row, Col, Container } from "react-bootstrap";

import LoginForm from "./components/LoginForm";
import { login } from "../../components/user/userService";

const Login = () => {
  const { t } = useTranslation();
  const [{ app }, dispatch] = useAppContext();
  const history = useHistory();

  const handleLogin = async (data) => {
    try {
      const res = await login(data);
      console.log('res', res);
      if (!res) {
        dispatch({
          origin: "error",
          type: "SET_ERROR",
          message: "login_failed",
        });
        toast.error(`${t("login_error")}`);
      } else {
        dispatch({
          origin: "user",
          type: "LOGGED",
          isLogged: res,
        });
        setTimeout(() => history.push("/ejecucion"), 500);
      }
    } catch (err) {
      toast.error(`${t("login_error")}`);
    }
  };

  return (
    <section className={"buscador add-footer panel-home"}>
      <Container>
        <Row className={"mt-5 justify-content-center"}>
          <Col xxl={"4"} xl={"3"} lg={"4"} md={"6"} sm={"6"} xs={"11"}>
            <div className="card mb-3">
              <div className="card-body">
                <h2 className="card-title text-center logo mb-3">wikiobra.com</h2>
                <div className="card-text">
                  <h5 className={"text-center"}>Iniciar sesión</h5>
                  <LoginForm doLogin={handleLogin} />
                  <div className={"text-center mt-3"}>
                    <Link to="/recuperar-contrasena">
                      ¿Olvidó su contraseña?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};


export default Login;
