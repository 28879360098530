export const getRole = async ( { isPublic } ) => {
  try {
    const URL = process.env.REACT_APP_API_URL;
    const res = await fetch(`${URL}/users-permissions/roles`, {
      method: "GET",
        headers: {
          Authorization: isPublic ? `Bearer ${localStorage.getItem('jdo-userToken')}` : '',
          "Content-Type": "application/json; charset=utf-8",
        }
      }
    );
    if (res.status !== 200) {
      throw Error("Bad request");
    }
    const data = await res.json();
    return data.roles;
  } catch (error) {
    throw Error("Bad request");
  }
}
