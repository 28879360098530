import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import * as _ from "lodash";

import useAppContext from "../data/store";


const Privacidad = () => {
  const [{ user }] = useAppContext();
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const logged =
      !_.isNull(localStorage.getItem("jdo-userToken")) || user.logged;
    setIsLogged(logged);
  }, [user]);

  return (
    <section className={"about-us page-section legal-text"}>
      <Container>
        <Row>
          <Col xs={"12"} md={{ offset: 2, span: 8 }}>
            <h1>Política de Privacidad</h1>
            <p>
              La presente Política de Privacidad regula la recogida y tratamiento de
              los datos personales que sean proporcionados por los Usuarios, cuando
              accedan, naveguen o hagan uso de las funcionalidades en el Sitio Web
              accesible desde la URL https://www.wikiobra.com (en adelante, el Sitio
              Web). La aceptación de esta Política de Privacidad significa que el
              Usuario faculta a WIKIOBRA, titular del Sitio Web, para realizar el
              tratamiento de sus datos e incluirlos en un registro de tratamientos de
              su responsabilidad, para los fines estipulados a continuación:
            </p>
            <ul>
              <li>
                {" "}
                Registro de usuario: En caso de que complete el registro de usuarios,
                sus datos se emplearán para crear la cuenta de usuario y gestionar la
                relación que de ello derive. La base jurídica del tratamiento de tales
                datos se sustenta en el consentimiento del Usuario. Podrá modificar
                los datos relativos a su perfil a través de las herramientas ofrecidas
                a tal efecto en el Sitio Web dentro de la sección "Área personal".
                Además, con objeto de mantener sus datos personales actualizados de
                modo que reflejen su situación actual, puede comunicarnos cualquier
                modificación por escrito a través de la dirección de correo
                electrónico wikiobra@wikiobra.com. En cualquier momento podrá darse de
                baja del Sitio Web. Tan solo remitiendo un email desde la cuenta de
                correo sobre la que desea realizar la baja a wikiobra@wikiobra.com
                indicando en el asunto “Baja www.wikiobra.com”. El tiempo de
                tramitación de la baja dependerá del calendario laboral, pero no
                excederá de 48 horas en la mayoría de los casos.{" "}
              </li>
              <li>
                Compra o contratación: en caso de que el Usuario introduzca sus datos
                personales en el formulario de contratación de productos o servicios
                de WIKIOBRA, le facultará para el tratamiento de sus datos con el fin
                de la gestión comercial contratada, el pago y facturación y la
                prestación de los servicios. La base jurídica del tratamiento de tales
                datos se sustenta en el consentimiento del Usuario y la correcta
                ejecución del contrato, pues su tratamiento es necesario para la
                ejecución de este.
              </li>
              <li>
                Contacto: el Usuario que introduzca sus datos en los formularios con
                el objeto de hacer consultas o sugerencias, o de otro modo facilite
                sus datos para contactar con WIKIOBRA, da su consentimiento para que
                ésta trate sus datos con el fin de tramitar la consulta y/o contactar
                con el Usuario. La base jurídica del tratamiento de tales datos se
                sustenta en el consentimiento del Usuario.
              </li>
              <p>
                Todos los campos requeridos son obligatorios y su omisión impedirá́ que
                WIKIOBRA pueda gestionar el tratamiento de los datos. Los datos
                suministrados por el Usuario deberán ser completos y exactos, ya que
                de lo contrario WIKIOBRA no podrá gestionar correctamente el alta de
                la cuenta de usuario y gestionar la relación que de ello derive.
              </p>
              <p>
                Asimismo, el Usuario puede revocar el consentimiento para el
                tratamiento, En cualquier momento podrá ejercer sus derechos de a
                acceso, rectificación, supresión, portabilidad, limitación u
                oposición, escribiendo a WIKIOBRA, a la dirección arriba indicada.
              </p>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Privacidad;
