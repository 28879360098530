import React from "react";
import { useTranslation } from "react-i18next";

import Switch from "react-switch";

import { Button, Card } from "react-bootstrap";
import { Box, Flex } from "reflexbox";
import styled from "styled-components";
import CardItem from "../../common/CardItem.jsx";
import { useHistory } from "react-router";

const UsuariosListItem = ({ item, isActive, isEmpleado = false, doChangeUser, doDelete }) => {
  const { t, lang } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Card style={{ padding: "20px" }}>
        <div className="containers">
          <div className="row">
            <div className="col">
              <CardItem valor={item.nombre || "-"} etiqueta={t("nombre")} />
            </div>
            <div className="col">
              <CardItem valor={item.email || "-"} etiqueta={t("email")} />
            </div>
            <div
              className="col"
              onClick={() => doChangeUser(item.id, item.confirmed)}
            >
              <CardItem
                valor={
                  <Switch
                    onChange={() => doChangeUser(item.id, item.confirmed)}
                    checked={item.confirmed}
                    id="normal-switch"
                    offColor="#ff0000"
                  />
                }
                etiqueta={
                  (item.confirmed && t("confirmado")) || t("no_confirmado")
                }
              />
            </div>
            <div className="col">
              <Flex>
                <Box>
                  <Button
                    m="1"
                    onClick={() => history.push(`/colaboradores/edit/${item.id}`)}
                  >
                    {t("editar")}
                  </Button>
                </Box>
                <Box>
                  <Button
                    m="1"
                    variant="danger"
                    onClick={() => doDelete(item.id)}
                  >
                    {t("borrar")}
                  </Button>
                </Box>
              </Flex>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

const StyledH6 = styled.h6`
  font-size: 16px;
  width: 20%;
  height: 50px;
  padding: 5px;
  font-weight: 300 !important;
  color: black;
`;
const StyledH6Name = styled.h6`
  font-size: 16px;
  width: 20%;
  height: 50px;
  padding: 5px;
`;

const InLineH6 = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default UsuariosListItem;
