import React from "react";
import { Route, Redirect } from "react-router";
import { checkToken } from "../logic/logic";

const ProtectedRoute = ({
  logged,
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const islogged = checkToken();


  return (
    <Route
      {...rest}
      render={(props) =>
        islogged ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );
};

export default ProtectedRoute;
