import React, { useState, useEffect } from "react";
import { Box, Flex } from "reflexbox";

import PropTypes from "prop-types";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Pagination = ({
  initialPage,
  totalRecords = null,
  pageLimit = 30,
  pageNeighbours = 0,
  onPageChanged,
}) => {
  let totalPages = Math.ceil(totalRecords / pageLimit);
  const [currentPage, setCurrentPage] = useState(null);
  const [medida, setMedida] = useState(pageLimit);

  useEffect(() => {
    gotoPage(1);
  }, [medida]);

  useEffect(() => {
    setCurrentPage(initialPage || 0);
  }, [initialPage]);

  const gotoPage = (page) => {
    const currentPage = Math.max(0, Math.min(page, totalPages));
    setCurrentPage(currentPage ? currentPage - 1 : currentPage);
  };

  useEffect(() => {
    const paginationData = {
      currentPage,
      totalPages: totalPages,
      pageLimit: medida,
      totalRecords: totalRecords,
    };
    onPageChanged(paginationData);
  }, [currentPage, medida]);

  const handleClick = (page, evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage + pageNeighbours * 2 + 1);
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  if (!totalRecords) return null;

  const pages = fetchPageNumbers();

  return (
    <>
      <Flex>
        {totalPages > 1 && (
          <Box>
            <nav aria-label="Pagination">
              <ul className="pagination">
                {pages.map((page, index) => {
                  if (page === LEFT_PAGE)
                    return (
                      <li key={index} className="page-item">
                        <a
                          className="page-link"
                          href="/"
                          aria-label="Previous"
                          onClick={handleMoveLeft}
                        >
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>
                    );

                  if (page === RIGHT_PAGE)
                    return (
                      <li key={index} className="page-item">
                        <a
                          className="page-link"
                          href="/"
                          aria-label="Next"
                          onClick={handleMoveRight}
                        >
                          <span aria-hidden="true">&raquo;</span>
                          <span className="sr-only">Next</span>
                        </a>
                      </li>
                    );

                  return (
                    <li
                      key={index}
                      className={`page-item${
                        currentPage + 1 === page ? " active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="/"
                        onClick={(e) => handleClick(page, e)}
                      >
                        {page}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </Box>
        )}
      </Flex>

      <style jsx="true">{`
        ul.pagination {
          margin-top: 0;
          margin-bottom: 0;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        }
        ul.pagination li.page-item.active a.page-link {
          color: #445565 !important;
          background-color: #e3e7eb !important;
          border-color: #ced4da !important;
        }
        ul.pagination a.page-link {
          padding: 0.75rem 1rem;
          min-width: 3.5rem;
          text-align: center;
          box-shadow: none !important;
          border-color: #ced4da !important;
          color: #6b88a4;
          font-weight: 900;
          font-size: 12px;
        }
        ul.pagination a.page-link:hover {
          background-color: #f4f4f4;
        }
        button.btn-link {
          font-family: "Roboto";
          font-size: 12px;
        }
        button.btn-link:hover,
        button.btn-link:focus,
        button.btn-link.focus {
          text-decoration: none;
        }
      `}</style>
    </>
  );
  // }
};

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
};

export default Pagination;
