import React from 'react';

const Input = ({
  className,
  type = 'type',
    placeholder,
    register,
    name,
    value,
    checked = null,
    required,
    isDisabled,
    onChange,
    onKeyDown,
    error,
}) => <>
    <input
        {...register(name, {required})}
        className={className}
        type={type}
        placeholder={placeholder}
        disabled={isDisabled}
        value={value}
        defaultChecked={checked}
        onChange={onChange}
        onKeyDown={onKeyDown}
    />
    <span className={"is-small text-danger"}>{error}</span>
</>

export default Input;
