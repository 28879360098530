import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

const CardItem = ({ valor, etiqueta }) => {
  const { t, lang } = useTranslation();

  return (
    <>
      <Valor>{valor}</Valor>
      {valor !== "-" && etiqueta === "LinkedIn" ? (
        <a href={`https://linkedin.com/in/${valor}`} target="_blank">
          Ir al perfil de linkedIn
        </a>
      ) : (
        <Etiqueta>{etiqueta}</Etiqueta>
      )}
    </>
  );
};

const Etiqueta = styled.div`
  font-size: 10px;
  padding: 0px;
  color:  ${(props) => props.theme.colors.grey};
  margin:0px
  font-weight: 200 !important;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Valor = styled.div`
  font-size: 16px;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.palegrey}`};
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-weight: 300 !important;
  color: black;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Nombre = styled.div`
  font-size: 24px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.primary};
  width: 100%;
  padding: 10px 0px;
`;

export default CardItem;
