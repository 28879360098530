import React from 'react';
import { Col, Container, Row } from "react-bootstrap";

const Contacto = () => {

  return (
    <div className={"parallax"}>
      <section className={"enlaces py-5"}>
        <Container>
          <Row>
            <Col xs={{ span: 10, offset: 1 }} className={"text-center"}>
              <h4 className={"blue"}>Búsqueda rápida</h4>
              <div className={"my-4"}>
                <h3 className={"tag-item"}><a href="/">Protecciones Colectivas</a></h3>
                <h3 className={"tag-item"}><a href="/">Casetas</a></h3>
                <h3 className={"tag-item"}><a href="/">Acero corrugado</a></h3>
                <h3 className={"tag-item"}><a href="/">Grúa torre</a></h3>
                <h3 className={"tag-item"}><a href="/">Piedra natural</a></h3>
                <h3 className={"tag-item"}><a href="/">Control de Calidad</a></h3>
                <h3 className={"tag-item"}><a href="/">Hormigón</a></h3>
                <h3 className={"tag-item"}><a href="/">Materiales de construcción</a></h3>
                <h3 className={"tag-item"}><a href="/">Encofradores</a></h3>
                <h3 className={"tag-item"}><a href="/">Pladur</a></h3>
                <h3 className={"tag-item"}><a href="/">Mamparas</a></h3>
                <h3 className={"tag-item"}><a href="/">Pintura</a></h3>
                <h3 className={"tag-item"}><a href="/">Camión Grúa</a></h3>
                <h3 className={"tag-item"}><a href="/">Metálicas</a></h3>
                <h3 className={"tag-item"}><a href="/">Micropilotes</a></h3>
                <h3 className={"tag-item"}><a href="/">Ignifugaciones</a></h3>
                <h3 className={"tag-item"}><a href="/">Azulejos y Gres</a></h3>
                <h3 className={"tag-item"}><a href="/">Grúa móvil</a></h3>
                <h3 className={"tag-item"}><a href="/">Muros Pantalla</a></h3>
                <h3 className={"tag-item"}><a href="/">Impermeabilizaciones</a></h3>
                <h3 className={"tag-item"}><a href="/">Alquiler de maquinaria</a></h3>
                <h3 className={"tag-item"}><a href="/">Ferrallas</a></h3>
                <h3 className={"tag-item"}><a href="/">Movimiento de tierras</a></h3>
                <h3 className={"tag-item"}><a href="/">Estructura metálica</a></h3>
                <h3 className={"tag-item"}><a href="/">Ascensores</a></h3>
                <h3 className={"tag-item"}><a href="/">Albañilería</a></h3>
                <h3 className={"tag-item"}><a href="/">Cerrajería</a></h3>
                <h3 className={"tag-item"}><a href="/">Ferretería</a></h3>
                <h3 className={"tag-item"}><a href="/">Pilotes</a></h3>
                <h3 className={"tag-item"}><a href="/">Anclajes</a></h3>
                <h3 className={"tag-item"}><a href="/">Carpintería de Madera</a></h3>
                <h3 className={"tag-item"}><a href="/">Carpintería de Aluminio</a></h3>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Contacto;
