import React, { useEffect, useState } from "react";
import {BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import * as _ from "lodash";
// import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import useAppContext from "./data/store";
import ProtectedRoute from "./common/ProtectedRoute";

import AppContainer from "./components/layouts/AppContainer";

import {Home, AboutUs, VideoTutorials, WeDo, Forgot, Login, Register} from "./pages/landingPages";
import {SolicitudOfertaProveedores} from "./pages/panel";
import Proveedores from "./pages/Proveedores";
import MisObras from "./pages/MisObras";
import MisEjecucion from "./pages/MisEjecucion";
import MisProveedores from "./pages/MisProveedores";
import UsuariosList from "./components/usuarios/UsuariosList";
import EdicionCategoriasList from "./components/edicionCategorias/EdicionCategoriasList";
import EdicionTipoProveedor from "./components/edicionTipoProveedor/EdicionTipoProveedorList.jsx";
import FullEdit from "./components/full/FullEdit";
import ObrasEdit from "./components/obras/ObrasEdit";
import ObrasView from "./components/obras/ObrasView";
import ObrasOfertasList from "./pages/ObrasOfertasList";
import ObrasOfertaProveedor from "./components/obras/ObrasOfertaProveedor";
import OfertasValorar from "./components/ofertas/OfertasValorar";
import ObrasProveedoresEscogidosList from "./pages/ObrasProveedoresEscogidosList";
import ObrasOfertasAddProveedor from "./pages/ObrasOfertasAddProveedor";
import ObrasEstudioOfertasAddProveedor from "./pages/ObrasEstudioOfertasAddProveedor";
import FullDetails from "./components/full/FullDetails";
import AppRoute from "./common/AppRoute";
import Privacidad from "./pages/Privacidad";
import Condiciones from "./pages/Condiciones";
import EmpleadosList from "./components/colaboradores/EmpleadosList";
import EmpleadosEdit from "./components/colaboradores/EmpleadosEdit";
import EdicionPatrocinadores from "./components/edicionPatrocinadores/EdicionPatrocinadores";
import Retrieve from "./components/user/Retrieve";
import Perfil from "./components/user/Perfil";
import DelegacionesFullEdit from "./components/delegaciones/DelegacionesFullEdit";
import MisObrasEstudios from "./pages/MisObrasEstudios";
import ObrasEstudiosEdit from "./components/obras-estudios/ObrasEstudiosEdit";
import ObrasEstudiosView from "./components/obras-estudios/ObrasEstudiosView";
import CorreoOferta from "./components/user/CorreoOferta";
import ConsultWorks from "./pages/ConsultWorks";
import GoogleApiWrapper from "./pages/Maps";
import ProvidersBlocked from "./pages/ProvidersBlocked";
import NormalizeData from "./components/user/NormalizeData";
import NormalizeDataProvince from "./components/user/NormalizeDataProvince";


const Routers = () => {
  const [{ user }] = useAppContext();
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const logged =
      !_.isNull(localStorage.getItem("jdo-userToken")) || user.logged;
    setIsLogged(logged);
  }, [user]);

  return (
    <Router>
      <Switch>
        <AppRoute exact path='/inicio' component={Home} layout={AppContainer} />
        <AppRoute
          exact
          path='/recuperar-contrasena'
          component={Forgot}
          layout={AppContainer}
        />
        <AppRoute
          exact
          path='/admin/admin'
          component={Retrieve}
          layout={AppContainer}
        />
        <AppRoute
          exact
          path='/admin/normalize-data'
          component={NormalizeData}
          layout={AppContainer}
        />
        <AppRoute
          exact
          path='/admin/normalize-data/province'
          component={NormalizeDataProvince}
          layout={AppContainer}
        />
        <AppRoute
          exact
          path='/privacidad'
          component={Privacidad}
          layout={AppContainer}
        />
        <AppRoute
            exact
            path='/condiciones'
            component={Condiciones}
            layout={AppContainer}
        />
        <AppRoute
          exact
          path='/registro'
          component={Register}
          layout={AppContainer}
        />
        <AppRoute
          exact
          path='/iniciar-sesion'
          component={Login}
          layout={AppContainer}
        />
        <AppRoute
            exact
            path='/quienes-somos'
            component={AboutUs}
            layout={AppContainer}
        />
        <AppRoute
            exact
            path='/que-hacemos'
            component={WeDo}
            layout={AppContainer}
        />
        <AppRoute
            exact
            path='/video-tutoriales'
            component={VideoTutorials}
            layout={AppContainer}
        />
        {/* <AppRoute
          exact
          path="/linkedin"
          component={LinkedInPopUp}
          layout={LoginContainer}
        /> */}
        <Route exact path='/linkedin' component={LinkedInCallback} />
        {/* comodin */}
        {/* buques routes */}
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras'
          component={MisObras}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/new'
          component={ObrasEdit}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/edit/:id'
          component={ObrasEdit}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/view/:id'
          component={ObrasView}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/detalles/:id'
          component={ObrasOfertasList}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/list/:id'
          component={ObrasProveedoresEscogidosList}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/:id/proveedor'
          component={ObrasOfertaProveedor}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/:id/participante/:participante/valorar/:valoracion'
          component={OfertasValorar}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/:id/capitulos/:capitulo/ofertas/:oferta/valorar/:valoracion'
          component={OfertasValorar}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/:id/participante/:participante/valorar'
          component={OfertasValorar}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/:id/capitulos/:capitulo/ofertas/:oferta/valorar'
          component={OfertasValorar}
          layout={AppContainer}
        />
        {/* ------------------------------- */}
         <ProtectedRoute
          logged={isLogged}
          exact
          path='/estudios'
          component={MisObrasEstudios}
          layout={AppContainer}
        />

        <ProtectedRoute
          logged={isLogged}
          exact
          path='/estudios/new'
          component={ObrasEstudiosEdit}
          layout={AppContainer}
        />

        <ProtectedRoute
          logged={isLogged}
          exact
          path='/estudios/edit/:id'
          component={ObrasEstudiosEdit}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/estudios/add/:id'
          component={ObrasEstudioOfertasAddProveedor}
          layout={AppContainer}
        />

        <ProtectedRoute
          logged={isLogged}
          exact
          path='/estudios/view/:id'
          component={ObrasEstudiosView}
          layout={AppContainer}
        />

        {/* ------------------- */}
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion'
          component={MisEjecucion}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion/new'
          component={ObrasEdit}
          layout={AppContainer}
        />{' '}
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion/edit/:id'
          component={ObrasEdit}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion/list/:id'
          component={ObrasProveedoresEscogidosList}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion/:id/proveedor'
          component={ObrasOfertaProveedor}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion/view/:id'
          component={ObrasView}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/obras/detalles/:id'
          component={ObrasOfertasList}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion/add/:id'
          component={ObrasOfertasAddProveedor}
          layout={AppContainer}
        />
        <ProtectedRoute
            logged={isLogged}
            exact
            path='/ejecucion/solicitud-proveedores/:id'
            component={SolicitudOfertaProveedores}
            layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion/detalles/:id'
          component={ObrasOfertasList}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion/:id/capitulos/:capitulo/ofertas/:oferta/valorar'
          component={OfertasValorar}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion/:id/participante/:participante/valorar'
          component={OfertasValorar}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/ejecucion/:id/participante/:participante/valorar/:valoracion'
          component={OfertasValorar}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/proveedores'
          component={MisProveedores}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/full'
          component={Proveedores}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/full-blocked'
          component={ProvidersBlocked}
          layout={AppContainer}
        />
         <ProtectedRoute
          logged={isLogged}
          exact
          path='/full-execution'
          component={ConsultWorks}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/full/new'
          component={FullEdit}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/full/edit/:id'
          component={FullEdit}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/full/edit/:id/:categoria'
          component={FullEdit}
          layout={AppContainer}
        />
        <AppRoute
          exact
          path='/full/details/:id'
          component={FullDetails}
          layout={AppContainer}
        />
        {/* admin */}
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/usuarios'
          component={UsuariosList}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/colaboradores'
          component={EmpleadosList}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/colaboradores/new'
          component={EmpleadosEdit}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/colaboradores/edit/:id'
          component={EmpleadosEdit}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/edicionCategorias'
          component={EdicionCategoriasList}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/edicionTipoProveedores'
          component={EdicionTipoProveedor}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/edicionPatrocinadores'
          component={EdicionPatrocinadores}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/me'
          component={Perfil}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/me/correo-ofertas/:id'
          component={CorreoOferta}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/delegaciones/:proveedor/new'
          component={DelegacionesFullEdit}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/delegaciones/:proveedor/edit/:id'
          component={DelegacionesFullEdit}
          layout={AppContainer}
        />
        <ProtectedRoute
          logged={isLogged}
          exact
          path='/maps'
          component={GoogleApiWrapper}
          layout={AppContainer}
        />
        {/* comodin */}
        <Redirect from='*' to='/inicio' />
      </Switch>
    </Router>
  );
};

export default Routers;
