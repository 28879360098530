import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { Button } from "../../components";
import useAppContext from "../../data/store";
import DeleteModal from "../../common/DeleteModal";
import { updateChapter } from "../../services/chapter.service";

const ObrasEstudiosCapitulosListItem = ({
  item,
  isActive,
  doDelete,
  usersDuty,
  handleSetCategory
}) => {
  const { t } = useTranslation();
  const [{ app }, dispatch] = useAppContext();
  const [showInfo, setShowInfo] = useState(false);
  const [show, setShow] = useState(false);
  const [dutyManager, setDutyManager] = useState();

  const handleShowInfo = () => {
    if (!showInfo) {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
  };

  const handleDelete = () => {
    setShowInfo(false);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleConfirm = () => {
    dispatch({
      origin: "app",
      type: "SET_CAPITULO",
      capitulo: null,
    });
    dispatch({
      origin: "app",
      type: "SET_OFERTA",
      oferta: null,
    });
    setShow(false);
    doDelete(item.id);
  };

  const handleCategoria = () => {
    dispatch({
      origin: "app",
      type: "SET_CAPITULO",
      capitulo: item,
    });
    handleSetCategory(item?.categoria)
  };

  const handleUpdateDutyManagerChapter = async (user) => {
    const wordId = item?.id;
    const items = { duty_manager: "" + user.value };
    setDutyManager(user);
    await updateChapter({ id: wordId, items });
  };

  useEffect(() => {
    const currentUserDuty = item?.duty_manager;
    const user = currentUserDuty
      ? { label: currentUserDuty?.nombre, value: currentUserDuty?.id }
      : usersDuty[0];
    setDutyManager(user);
  }, []);

  const getDutyManager = () => {
    const value = dutyManager?.value || null;
    if (value) {
      const filtrar = usersDuty?.find((user) => user.value === value);
      return filtrar?.label;
    }
    return;
  };
  return (
    <>
      <div
        style={{
          cursor: "pointer",
          backgroundColor:
            app && app.capitulo?.id === item.id ? "#ADD8E6" : "white",
          borderBottom: "1px solid #ADD8E6",
          padding: "5px",
        }}
        onClick={handleCategoria}
      >
        <div className="containers">
          <div className={"row"}>
            <div className={"col"} onClick={() => handleShowInfo()}>
              <span
                className={
                  "category-name d-flex flex-row align-items-center justify-content-between"
                }
              >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {item && item.categoria && item.categoria.nombre}
                  <span style={{ fontSize: "10px", width: "100%" }}>
                    {getDutyManager()}
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  <DropdownButton
                    variant="outline-secondary"
                    id="dutyManagerDropdown"
                    className="customDropDown"
                    title={""}
                  >
                    <Dropdown.Item href="#">
                      {t("responsible").toUpperCase()}
                    </Dropdown.Item>
                    {usersDuty?.map((u, i) => {
                      const value = dutyManager?.value;
                      return (
                        <Dropdown.Item
                          key={`${value}-duty-manager-${i}`}
                          href="#"
                          onClick={() => handleUpdateDutyManagerChapter(u)}
                          className={value === u.value ? `background-gray` : ``}
                        >
                          {u.label}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Button
                    text={""}
                    className={"btn-outline-danger mx-1 btn-sm min-47"}
                    icon={{ prefix: "fas", iconName: "trash" }}
                    onClick={() => handleDelete(item.id)}
                  />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={show}
        body={`${t("estas_seguro_de_borrar")} ${t("capitulo")}`}
        doClose={handleClose}
        doConfirm={handleConfirm}
      />
    </>
  );
};

export default ObrasEstudiosCapitulosListItem;
