import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.scss";

const Button = ({
  className = "btn-primary",
  text = "Guardar",
  type = "button",
  icon = null,
  mx = "",
  my = "",
  mainClass = "",
  onClick = null,
  disabled = false,
  loadingText = "Cargando",
  loading = false,
  btnRef = null,
  data_for,
  styleCon = null,
}) => {
  return (
    <div className={mx + " " + my + " " + mainClass} style={styleCon}>
      <button
        className={"btn " + className}
        type={type}
        onClick={onClick}
        disabled={disabled || loading}
        ref={btnRef}
        data-tip
        data-for={data_for}
      >
        {loading ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span className={"mx-1"}>{loadingText}</span>
          </>
        ) : (
          <>
            {icon && (
              <div className={"mx-2 d-inline"}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )}
            {text}
          </>
        )}
      </button>
    </div>
  );
};

export default Button;
