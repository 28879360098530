import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

import { getProviderByID } from "../../services/provider.service";

const FullProviderDelegations = (props) => {
  const { providerID } = props;

  const [delegations, setDelegations] = useState([]);

  const { t } = useTranslation();

  const [recipients, setRecipients] = useState();
  const [allEmailsDelegations, setAllEmailsDelegations] = useState();

  useEffect(() => {
    handleGetProviderByID(providerID);
  }, []);

  const handleGetProviderByID = async (ID) => {
    const response = await getProviderByID(ID)
      .then((d) => d)
      .catch((e) => console.log(e));
    const { delegacions } = response;
    setDelegations(delegacions);
  };

  const handleChangeRecipients = async (email) => {
    let emails = JSON.parse(localStorage.getItem("emails"));
    if (!emails.includes(email)) {
      emails.push(email);
    } else {
      emails = emails.filter((e) => (e !== email ? e : null));
    }
    localStorage.setItem("emails", JSON.stringify(emails));
    setRecipients(emails);
  };

  return (
    <>
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th>{t("province")}</th>
            <th>{t("contact")}</th>
            <th>{t("phone")}</th>
            <th>{t("email")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {delegations
            ? delegations.length > 0
              ? delegations?.map((d, i) => {
                  return (
                    <tr key={`${d?.id}-${i}`}>
                      <td className={"align-middle"}>{d.provincia}</td>
                      <td className={"align-middle"}>{d.contacto}</td>
                      <td className={"align-middle"}>
                        <a href={"tel:" + d.telefono}>{d.telefono}</a>
                      </td>
                      <td className={"align-middle"}>
                        <a href={"mailto:" + d.email}>{d.email}</a>
                      </td>
                      <td className={"align-middle"}>
                        <Form.Check
                          type={"checkbox"}
                          id={`default-checkbox`}
                          label={``}
                          className="width-1-2 height-1-2"
                          checked={localStorage
                            .getItem("emails")
                            ?.includes(d.email)}
                          onClick={() => handleChangeRecipients(d.email)}
                          onChange={() => {}}
                        />
                      </td>
                    </tr>
                  );
                })
              : null
            : null}
        </tbody>
      </table>
    </>
  );
};

export default FullProviderDelegations;
