import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import useAppContext from '../../data/store';
import { Row, Col, Card } from 'react-bootstrap';

import NormalizeDataProvinceForm from './NormalizeDataProvinceForm';

const NormalizeDataProvince = ({location}) => {
  const { t } = useTranslation();
  const [{ app }, dispatch] = useAppContext();
  const history = useHistory();

  const handelNormalizeDataProvinceCommunity = async (data) => {
    const URL = process.env.REACT_APP_API_URL;

    try {
      const res = await fetch(
        //TODO call api to normalize data
        `${URL}/proveedors/normalize-province`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jdo-userToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (res.status !== 200) {
        throw Error('Bad request');
      }
      await res.json();

    } catch (err) {
      toast.error(`${t('login_error')}`);
    }
  };

  return (
    <NormalizeDataProvinceDiv>
      <Row noGutters>
        <Col md={12}>
          <Card id='login-card'>
            <Card.Title>
              <h1 style={{ padding: '0 1.25rem' }}>Normalizar Datos</h1>
            </Card.Title>
            <Card.Body>
              <h5>Normalizar Datos de provedores</h5>
              <NormalizeDataProvinceForm doNormalizeDataProvince={handelNormalizeDataProvinceCommunity} location={location} />
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  paddingTop: '16px',
                }}
              >
                <Link style={{ fontSize: '11px' }} to='/iniciar-sesion'>
                  Volver
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row noGutters>
        {/*        <Col
          md={12}
          style={{ marginTop: "50px", textAlign: "center", color: "grey" }}
        >
          <img src="img/xtremislogo.png" alt="barra" /> <br />
          <small>Desarrollado por Xtremis.</small> <br />
          <small>
            <a
              href="http://xtremis.es"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "grey" }}
            >
              xtremis.es
            </a>
          </small>
          <br />
          <small>(v1.0)</small>
        </Col>*/}
      </Row>
    </NormalizeDataProvinceDiv>
  );
};

const NormalizeDataProvinceDiv = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default NormalizeDataProvince;
