import React, { Fragment, useState } from "react";

import { useLinkedIn } from "react-linkedin-login-oauth2";

import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import { authLinkedIn } from '../services/auth.service';
//import IconLinkedIn from '../icons/IconLinkedIn';

const LinkedInButton = ({ doData, setChangeView, updateInitialValue }) => {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSuccess = () => {
    setChangeView(prevState => !prevState);
  }

  const handleFailure = (error) => {
    setCode("");
    setErrorMessage(error.errorMessage);
  };

  const getNamesData = async (code) => {
    const response = await authLinkedIn(code);
    updateInitialValue({nombre: response?.fullName, email: response?.email, linkedin: response?.linkedin});
  };
  // test
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: async (code) => {
      await getNamesData(code);
      handleSuccess();
    },
    onError: (error) => {
      handleFailure(error);
    },
    scope: "r_emailaddress r_basicprofile",
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "20px",
      }}
    >
      <img
        src={linkedin}
        alt="Log in with Linked In"
        style={{ maxWidth: "180px" }}
        onClick={linkedInLogin}
      />
    </div>
  );
};

export default LinkedInButton;
