import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MainTitle } from "../common/StyledComponents";

import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Buscador from "./landingPages/components/Buscador";
import { Button } from "../components";

const Proveedores = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [classTable, setClassTable] = useState("");

  return (
    <>
      <section className={`buscador ${classTable}`}>
        <Container>
          <Row>
            <Col>
              <div className={"card"}>
                <div className={"card-title p-3"}>
                  <div className={"d-flex justify-content-between"}>
                    <MainTitle>{t("proveedores")}</MainTitle>
                    <Button
                      text={t("nuevo_proveedor")}
                      className={"btn-primary"}
                      icon={{ prefix: "fas", iconName: "plus" }}
                      onClick={() => {
                        localStorage.setItem('fullOldValues', JSON.stringify({}));
                        localStorage.setItem('delegationsTemp', JSON.stringify([]))
                        history.push("full/new")
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <div className={"card-body pt-0"}>
            <Buscador loggin={true} setClass={setClassTable} />
        </div>
      </section>
    </>
  );
};

export default Proveedores;
