import { useEffect, useState } from "react";
import * as XLSX from "xlsx-js-style";
import FileSaver from "file-saver";
import { Button } from "react-bootstrap";
import { getDetailsWorkById } from "../../services/works.service";

const TableContent = ({ datos, allDataToUsersDuty, className }) => {
  const [loading, setLoading] = useState(true);
  const [loadingComment, setLoadingComment] = useState(true);
  const [headersListString, setHeadersListString] = useState("");
  const [headersList, setHeadersList] = useState([]);
  const [processInfo, setProcessInfo] = useState([]);
  const [maxLength, setMaxLength] = useState([]);

  const addDutyManager = () => {
    if (allDataToUsersDuty) {
      for (let i = 0; i < datos.capitulos.length; i++) {
        if (allDataToUsersDuty.capitulos[i].duty_manager) {
          const dutyManager = allDataToUsersDuty.colaboradores.find(
            (element) =>
              element.id === allDataToUsersDuty.capitulos[i].duty_manager
          );
          datos.capitulos[i].duty_manager = dutyManager?.nombre;
        } else {
          const user = allDataToUsersDuty.user;
          const collaborators = allDataToUsersDuty.colaboradores;
          const allUsers = [user, ...collaborators];
          datos.capitulos[i].duty_manager = allUsers[0]?.nombre;
        }
      }
      setLoading(false);
    } else {
      setTimeout(() => {
        addDutyManager();
      }, 1000);
    }
  };

  useEffect(() => {
    addDutyManager();
  }, [allDataToUsersDuty]);

  useEffect(() => {
    handleDesestructureData();
  }, [datos, allDataToUsersDuty]);

  const handleDesestructureData = async () => {
    function padTo2Digits(num) {
      return num?.toString()?.padStart(2, "0");
    }
    function formatDate(value) {
      if (value === null) {
        return "";
      }
      if (value === "null") {
        return "";
      }
      let date = new Date(value);
      return [
        padTo2Digits(date?.getDate()),
        padTo2Digits(date?.getMonth() + 1),
        date?.getFullYear(),
      ].join("/");
    }

    let work = null;

    const getFormattedDate = (date) => {
      const today = date;
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1;
      let dd = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      return dd + '/' + mm + '/' + yyyy;
    }

    if (datos?.capitulos[0]?.categoria?.nombre !== undefined) {
      work = datos;
    } else {
      const response = await getDetailsWorkById(datos?.id);
      work = response;
    }

    const chapter = work?.capitulos?.map((c) => {
      return {
        name: c?.categoria?.nombre,
        dutyManager: c?.duty_manager,
        providers: c?.ofertas?.map((o) => {
          return {
            name: o?.proveedor?.nombre || "",
            acceptance: formatDate(o?.aceptacion) || "",
            realization: formatDate(o?.realizacion) || "",
            reception: formatDate(o?.recepcion) || "",
            hired: formatDate(o?.contratado) || "",
            comment:
              o?.comentarios?.length > 0
                ? o?.comentarios[o?.comentarios?.length - 1].texto
                : "",
            date:
              o?.comentarios?.length > 0
                ? formatDate(o?.comentarios[o?.comentarios?.length - 1].fecha)
                : "",
          };
        }),
      };
    });

    const handleProcessStatus = (data, fill) => {
      if (data?.hired !== "") {
        return {
          v: "CONTRATADO",
          t: "s",
          s: {
            font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
            fill: { fgColor: { rgb: "A9D08E" } },
            border: {
              top: { style: "thin", color: "000000" },
              bottom: { style: "thin", color: "000000" },
              left: { style: "thin", color: "000000" },
              right: { style: "thin", color: "000000" },
            },
          },
        };
      }
      if (data?.realization !== "" && data?.reception !== "") {
        return {
          v: "RECIBIDA",
          t: "s",
          s: {
            font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
            fill: { fgColor: { rgb: "A9D08E" } },
            border: {
              top: { style: "thin", color: "000000" },
              bottom: { style: "thin", color: "000000" },
              left: { style: "thin", color: "000000" },
              right: { style: "thin", color: "000000" },
            },
          },
        };
      }
      if (
        data?.realization !== "" &&
        data?.reception !== "" &&
        data?.hired === ""
      ) {
        return {
          v: "DECLINADA",
          t: "s",
          s: {
            font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
            fill: { fgColor: { rgb: "FF9933" } },
            border: {
              top: { style: "thin", color: "000000" },
              bottom: { style: "thin", color: "000000" },
              left: { style: "thin", color: "000000" },
              right: { style: "thin", color: "000000" },
            },
          },
        };
      }
      if (data?.realization !== "") {
        return {
          v: "PENDIENTE",
          t: "s",
          s: {
            font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
            fill: { fgColor: { rgb: fill } },
            border: {
              top: { style: "thin", color: "000000" },
              bottom: { style: "thin", color: "000000" },
              left: { style: "thin", color: "000000" },
              right: { style: "thin", color: "000000" },
            },
          },
        };
      }
      return {
        v: "",
        t: "s",
        s: {
          font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: fill } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
        },
      };
    };

    const chapterProcess = chapter?.map((c) => {
      return c?.providers?.map((p, i) => {
        const fill = i % 2 === 0 ? "EDEDED" : "FFFFFF";
        return {
          category: {
            v: c?.name,
            t: "s",
            s: {
              font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
              fill: { fgColor: { rgb: fill } },
              border: {
                top: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
              },
            },
          },
          dutyManager: {
            v: c?.dutyManager,
            t: "s",
            s: {
              font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
              fill: { fgColor: { rgb: fill } },
              border: {
                top: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
              },
            },
          },
          provider: {
            v: p?.name,
            t: "s",
            s: {
              font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
              fill: { fgColor: { rgb: fill } },
              border: {
                top: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
              },
            },
          },
          acceptance: {
            v: p?.acceptance,
            t: "s",
            s: {
              font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
              fill: { fgColor: { rgb: fill } },
              border: {
                top: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
              },
            },
          },
          realization: {
            v: p?.realization,
            t: "s",
            s: {
              font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
              fill: { fgColor: { rgb: fill } },
              border: {
                top: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
              },
            },
          },
          status: handleProcessStatus(p, fill),
          reception: {
            v: p?.reception,
            t: "s",
            s: {
              font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
              fill: { fgColor: { rgb: fill } },
              border: {
                top: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
              },
            },
          },
          hired: {
            v: p?.hired,
            t: "s",
            s: {
              font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
              fill: { fgColor: { rgb: fill } },
              border: {
                top: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
              },
            },
          },
          comment: {
            v: p?.comment,
            t: "s",
            s: {
              font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
              fill: { fgColor: { rgb: fill } },
              border: {
                top: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
              },
            },
          },
          date: {
            v: p?.date,
            t: "s",
            s: {
              font: { color: { rgb: "000000" }, name: "Arial", sz: 12 },
              fill: { fgColor: { rgb: fill } },
              border: {
                top: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
              },
            },
          },
        };
      });
    });

    const today = getFormattedDate(new Date());

    const second = {
      category: {
        v: "OBRA",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "0070C0" } },
        },
      },
      dutyManager: {
        v: datos?.titulo,
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "0070C0" } },
        },
      },
      provider: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "0070C0" } },
        },
      },
      realization: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "0070C0" } },
        },
      },
      status: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "0070C0" } },
        },
      },
      reception: {
        v: "FECHA INFORME",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "0070C0" } },
        },
      },
      comment: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "0070C0" } },
        },
      },
      date: {
        v: today,
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "0070C0" } },
        },
      },
    };

    const fourth = {
      category: {
        v: "INFORME DE SEGUIMIENTO DE OFERTAS.",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "002060" } },
        },
      },
      dutyManager: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "002060" } },
        },
      },
      provider: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "002060" } },
        },
      },
      realization: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "002060" } },
        },
      },
      status: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "002060" } },
        },
      },
      reception: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "002060" } },
        },
      },
      comment: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "002060" } },
        },
      },
      date: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "002060" } },
        },
      },
    };

    const third = {
      category: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "FFFFFF" } },
        },
      },
      dutyManager: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "FFFFFF" } },
        },
      },
      provider: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "FFFFFF" } },
        },
      },
      realization: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "FFFFFF" } },
        },
      },
      status: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "FFFFFF" } },
        },
      },
      reception: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "FFFFFF" } },
        },
      },
      comment: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "FFFFFF" } },
        },
      },
      date: {
        v: "",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "FFFFFF" } },
        },
      },
    };

    const first = {
      category: {
        v: "CAPITULO",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "A5A5A5" } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
        },
      },
      dutyManager: {
        v: "RESPONSABLE",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "A5A5A5" } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
        },
      },
      provider: {
        v: "PROVEEDOR",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "A5A5A5" } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
        },
      },
      realization: {
        v: "FECHA DE ENVÍO SDO",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "A5A5A5" } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
        },
      },
      status: {
        v: "ESTADO",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "A5A5A5" } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
        },
      },
      reception: {
        v: "FECHA RECEPCIÓN DE OFERTA",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "A5A5A5" } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
        },
      },
      comment: {
        v: "COMENTARIO",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "A5A5A5" } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
        },
      },
      date: {
        v: "FECHA ÚLTIMO COMENTARIO",
        t: "s",
        s: {
          font: { bold: true, color: { rgb: "000000" }, name: "Arial", sz: 12 },
          fill: { fgColor: { rgb: "A5A5A5" } },
          border: {
            top: { style: "thin", color: "000000" },
            bottom: { style: "thin", color: "000000" },
            left: { style: "thin", color: "000000" },
            right: { style: "thin", color: "000000" },
          },
        },
      },
    };

    const chapterList = [];
    const columnLength = {
      category: [],
      dutyManager: [],
      provider: [],
      realization: [],
      status: [17],
      reception: [],
      date: [],
      comment: []
    };
    chapterList.push(fourth);
    chapterList.push(third);
    chapterList.push(third);
    chapterList.push(second);
    chapterList.push(third);
    chapterList.push(first);
    chapterProcess?.forEach((c) => {
      c?.forEach((e) => {
        chapterList.push(e);
      });
    });

    chapterList.forEach((c, i) => {
      Object.keys(c).forEach((o) => {
        if ( columnLength[o] === undefined ) {
          columnLength[o] = [];
        }
        columnLength[o].push(chapterList[i][o].v?.length);
      });
    });

    const maxLength = [];
    maxLength.push({ wch: Math.max(...columnLength.category) });
    maxLength.push({ wch: Math.max(...columnLength.dutyManager) });
    maxLength.push({ wch: Math.max(...columnLength.provider) });
    maxLength.push({ wch: Math.max(...columnLength.realization) });
    maxLength.push({ wch: Math.max(...columnLength.status) });
    maxLength.push({ wch: Math.max(...columnLength.reception) });
    maxLength.push({ wch: Math.max(...columnLength.date) });
    maxLength.push({ wch: Math.max(...columnLength.comment) });
    setMaxLength(maxLength);

    const headers = [
      { key: "category", label: "CAPÍTULO" },
      { key: "dutyManager", label: "RESPONSABLE" },
      { key: "provider", label: "PROVEEDOR" },
      { key: "realization", label: "FECHA DE ENVÍO SDO" },
      { key: "status", label: "ESTADO" },
      { key: "reception", label: "FECHA RECEPCIÓN DE OFERTA" },
      { key: "date", label: "FECHA ÚLTIMO COMENTARIO" },
      { key: "comment", label: "COMENTARIO" },
    ];

    const headersList = [];
    const headersListString = [];
    headers.forEach((h) => {
      headersList[h.key] = h.label;
      headersListString.push(h.label);
    });

    setHeadersListString(headersListString);
    setHeadersList(headersList);
    const headersObjects = {};

    Object.assign(headersObjects, headersList);
    const processInfo = [];

    if (chapterList !== undefined) {
      Object.entries(chapterList)?.forEach((value) => {
        const processData = [];
        const processDataObject = {};
        Object.entries(value[1]).forEach(([keyV, valueV]) => {
          if (headersList[keyV] !== undefined) {
            processData[headersList[keyV]] = valueV;
          }
        });
        Object.assign(processDataObject, processData);
        processInfo.push(processDataObject);
      });
    }
    setProcessInfo(processInfo);
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName, wscols, maxLength) => {
    const ws = XLSX.utils.json_to_sheet(headersList, {
      header: headersListString,
      skipHeader: true,
    });
    ws["!cols"] = wscols;

    ws["!cols"] = maxLength;
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
      { s: { r: 3, c: 1 }, e: { r: 3, c: 4 } },
      { s: { r: 3, c: 6 }, e: { r: 3, c: 7 } },
    ];
    ws["!merges"] = merge;
    XLSX.utils.sheet_add_json(ws, csvData, {
      header: headersListString,
      skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataExcel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataExcel, fileName + fileExtension);
  };

  return (
    <div>
      {!loading && allDataToUsersDuty ? (
        <div>
          <Button
            className={className}
            variant="secondary"
            onClick={() =>
              exportToCSV(
                processInfo,
                `${datos?.titulo.substr(0, 30)}-SDO`.replaceAll(" ", "-"),
                "",
                maxLength,
              )
            }
          >
            Informe SDO
          </Button>
        </div>
      ) : (
        <div>cargando...</div>
      )}
    </div>
  );
};

export default TableContent;
