import React, { useState, useEffect } from "react";
import * as _ from "lodash";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form } from "react-bootstrap";
import { Button } from "../../components";
import appServices from "../../logic/services";
import { toast } from "react-toastify";
import Select from "react-select";
import Error from "../../common/Error";
import { useParams } from "react-router-dom";

const ambitos = ["LOCAL", "REGIONAL", "NACIONAL"];

let initial = {
  nombre: "",
  cif: "",
  direccion: "",
  contacto: "",
  web: "",
  email_contacto: "",
  email: "",
  tel1: "",
  tel2: "",
  comunidad: "",
  provincia: "",
  municipio: "",
  tipo_proveedor: "",
  ambito: "",
  categoriasValores: [],
  tags: "",
};

const validacion = Yup.object().shape({
  nombre: Yup.string().required("obligatorio"),
  cif: Yup.string().required("obligatorio"),
  ambito: Yup.string().required("obligatorio"),
  tel1: Yup.string().required("obligatorio"),
  email: Yup.string("")
    .email(
      "El correo es incorrecto. No pueden haber espacios al inicio y final"
    )
    .required("obligatorio"),
  categoriasValores: Yup.array().min(1, "obligatorio").required("obligatorio"),
  provincia: Yup.string().required("obligatorio"),
});

const FullEditForm = ({ item, doSave, doCancel }) => {
  const { t } = useTranslation();
  const [isLoading] = useState(false);

  const history = useHistory();

  const [data, setData] = useState([]);

  const params = useParams();
  const { categoria } = params;

  const updateState = () => {
    if (data.provincia?.label === "Gerona") {
      setData({
        ...data,
        provincia: { value: "Girona", label: "Girona" },
      });
    }
    if (data.provincia?.label === "Lérida") {
      setData({
        ...data,
        provincia: { value: "Lleida", label: "Lleida" },
      });
    }
    if (data.provincia?.label === "Araba") {
      setData({
        ...data,
        provincia: { value: "Álava", label: "Álava" },
      });
    }
    if (data.provincia?.label === "Bizkaia") {
      setData({
        ...data,
        provincia: { value: "Vizcaya", label: "Vizcaya" },
      });
    }
    if (data.provincia?.label === "La Coruña") {
      setData({
        ...data,
        provincia: { value: "A Coruña", label: "A Coruña" },
      });
    }
    if (data.provincia?.label === "Palmas") {
      setData({
        ...data,
        provincia: { value: "Las Palmas", label: "Las Palmas" },
      });
    }
    if (data.provincia?.label === "Orense") {
      setData({
        ...data,
        provincia: { value: "Ourense", label: "Ourense" },
      });
    }
    if (data.provincia?.label === "Tenerife") {
      setData({
        ...data,
        provincia: {
          value: "Santa Cruz de Tenerife",
          label: "Santa Cruz de Tenerife",
        },
      });
    }
  };

  const addCategory = () => {
    if (categoria) {
      const findCategory = categoriasLista.find(
        (item) => item.value === +categoria
      );
      setData({
        ...data,
        categoriasValores: [findCategory],
      });
    }
  };

  const updateweb = () => {
    let { web } = data;
    if (web === undefined || web == null) {
    } else if (!web?.startsWith("http")) {
      setData({
        ...data,
        web: "http://" + web,
      });
    }
  };

  useEffect(() => {
    updateState();
  }, []);

  useEffect(() => {
    updateweb();
  }, []);

  const [categoriasLista, setCategoriasLista] = useState([]);
  const [tipoProveedores, setTipoProveedores] = useState([]);

  const [listaProvincias, setListaProvincias] = useState([]);
  const [provinciasDeProveedor, setProvinciasDeProveedor] = useState([]);

  useEffect(() => {
    addCategory();
  }, [categoriasLista]);

  useEffect(() => {
    const getAll = async () => {
      try {
        const categorias = await appServices.getCategorias();
        const opcionesCategorias = categorias.json.map((c) => ({
          value: c.id,
          label: c.nombre,
        }));

        const tipoProveedores = await appServices.getTipoProveedores();
        setCategoriasLista(opcionesCategorias);
        setTipoProveedores(tipoProveedores.json);
        //
      } catch (err) {
        toast.error(`${t("error")}`);
      }
    };

    if (item) {
      item.tipo_proveedor = item.tipo_proveedor?.id || 3;
      item.ambito = (item.ambito && item.ambito.toUpperCase()) || "REGIONAL";
      item.provincia = item.provincia
        ? {
          value: item.provincia,
          label: item.provincia,
        }
        : null;
      setData({ ...initial, ...item });
      getAll();
    }
  }, [item]);

  useEffect(() => {}, [data]);

  useEffect(() => {
    let mount = true;

    if (mount) {
      getProvincias();
    }

    return () => {
      mount = false;
    };
  }, []);

  const getProvincias = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/provincias?_limit=-1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const provinciasProveedores = await res.json();

      setListaProvincias(provinciasProveedores);
      const cadaProvincia = provinciasProveedores
        .map((proveedor) => ({
          label: proveedor.nombre,
          value: proveedor.nombre,
        }))
        .filter((f) => f.value != null && f.value !== "");

      const provinciasOrden = _.sortBy(cadaProvincia, (o) => {
        const label = _.deburr(o.label);
        return label;
      });

      setProvinciasDeProveedor(provinciasOrden);

    } catch (err) {
      toast.error(`${t("error")}`);
    }
  };

  const onSubmit = (datos) => {
    const newData = cleanForm(datos);
    localStorage.setItem('fullOldValues', JSON.stringify({}));
    doSave(newData);
  };

  const cleanForm = (datos) => {
    const newData = { ...datos };
    delete newData.municipio;
    if ( datos?.provincia?.value === undefined || typeof datos?.provincia?.value  === 'number') {
      newData.provincia = datos?.provincia?.label?.toUpperCase() || null;
    } else {
      newData.provincia = datos?.provincia?.value?.toUpperCase() || null;
    }
    newData.comunidad = null;
    const provincia = listaProvincias.find(
      (p) => datos.provincia.value === undefined  || typeof datos?.provincia?.value  === 'number'
        ? p.nombre.toUpperCase() === datos.provincia.label.toUpperCase()
        : p.nombre.toUpperCase() === datos.provincia.value.toUpperCase()
    );
    if (provincia) {
      newData.comunidad = provincia.comunidad.nombre.toUpperCase();
    }

    if (newData.web !== null
      && newData.web !== undefined
      && newData.web !== ''
      && newData.web.length > 5
      && (!newData.web.includes('http') || !newData.web.includes('http'))) {
        newData.web = 'http://' + newData.web
    }

    return newData;
  };

  const handleOldValue = (name, value) => {
    initial[name] = value.target.value;
    localStorage.setItem('fullOldValues', JSON.stringify(initial));
  }

  useEffect(() => {
    const newData = JSON.parse(localStorage.getItem('fullOldValues'));
    if (newData !== null) {
      initial = newData;
    }
  }, []);

  if (!isLoading) {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={data || initial}
          validationSchema={validacion}
          onSubmit={onSubmit}
          validateOnMount={false}
          validateOnBlur={true}
          validateOnChange={false}
        >
          {({
            setFieldValue,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={"12"} lg={"6"}>
                    <Form.Group controlId="nombre">
                      <Form.Label>{t("nombre")}*</Form.Label>
                      <Error touched={touched.nombre} message={errors.nombre} />
                      <Form.Control
                        name="nombre"
                        type="text"
                        value={values.nombre || ""}
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('nombre', event)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"6"}>
                    <Form.Group controlId="email">
                      <Form.Label>{t("email")}*</Form.Label>
                      <Error touched={touched.email} message={errors.email} />
                      <Form.Control
                        name="email"
                        type="text"
                        value={values.email || ""}
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('email', event)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"6"}>
                    <Form.Group controlId="cif">
                      <Form.Label>{t("cif")}*</Form.Label>
                      <Error touched={touched.cif} message={errors.cif} />
                      <Form.Control
                        name="cif"
                        type="text"
                        value={values.cif || ""}
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('cif', event)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"6"}>
                    <Form.Group controlId="contacto">
                      <Form.Label>{t("contacto")}</Form.Label>
                      <Error
                        touched={touched.contacto}
                        message={errors.contacto}
                      />
                      <Form.Control
                        name="contacto"
                        type="text"
                        value={values.contacto || ""}
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('contacto', event)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"6"}>
                    <Form.Group controlId="direccion">
                      <Form.Label>{t("direccion")}</Form.Label>
                      <Error
                        touched={touched.direccion}
                        message={errors.direccion}
                      />
                      <Form.Control
                        name="direccion"
                        type="text"
                        value={values.direccion || ""}
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('direccion', event)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"3"}>
                    <Form.Group controlId="web">
                      <Form.Label>{t("web")}</Form.Label>
                      <Error touched={touched.web} message={errors.web} />
                      <Form.Control
                        name="web"
                        type="text"
                        value={values.web || ""}
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('web', event)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"3"}>
                    <Form.Group controlId="email_contacto">
                      <Form.Label>{t("email_contacto")}</Form.Label>
                      <Error
                        touched={touched.email_contacto}
                        message={errors.email_contacto}
                      />
                      <Form.Control
                        name="email_contacto"
                        type="text"
                        value={values.email_contacto || ""}
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('email_contacto', event)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"6"}>
                    <Form.Group controlId="provincia">
                      <Form.Label>{t("provincia")}*</Form.Label>
                      <Error
                        touched={touched.provincia}
                        message={errors.provincia}
                      />
                      <Select
                        isClearable
                        value={values.provincia}
                        id="tags"
                        onChange={(option) => {
                          setFieldValue("provincia", option, false);
                          handleOldValue('provincia', {target: {value: option?.value}});
                        }}
                        options={provinciasDeProveedor}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"3"}>
                    <Form.Group controlId="tel1">
                      <Form.Label>{t("tel1")}*</Form.Label>
                      <Error touched={touched.tel1} message={errors.tel1} />
                      <Form.Control
                        name="tel1"
                        type="text"
                        value={values.tel1 || ""}
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('tel1', event)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"3"}>
                    <Form.Group controlId="tel2">
                      <Form.Label>{t("tel2")}</Form.Label>
                      <Error touched={touched.tel2} message={errors.tel2} />
                      <Form.Control
                        name="tel2"
                        type="text"
                        value={values.tel2 || ""}
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('tel2', event)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"3"}>
                    <Form.Group controlId="tipoProveedor">
                      <Form.Label>{t("tipo_proveedor")}*</Form.Label>
                      <Error
                        touched={touched.tipo_proveedor}
                        message={errors.tipo_proveedor}
                      />
                      <Form.Control
                        name="tipo_proveedor"
                        as="select"
                        value={
                          values.tipo_proveedor ? values.tipo_proveedor : ""
                        }
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('tipo_proveedor', event)
                        }}
                      >
                        <option value="">Escoger...</option>
                        {tipoProveedores &&
                          tipoProveedores.map((opcion) => (
                            <option key={opcion.id} value={opcion.id}>
                              {opcion.nombre}
                            </option>
                          ))}
                      </Form.Control>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"3"}>
                    <Form.Group controlId="ambito">
                      <Form.Label>{t("ambito")}*</Form.Label>
                      <Error touched={touched.ambito} message={errors.ambito} />
                      <Form.Control
                        name="ambito"
                        as="select"
                        value={values.ambito ? values.ambito : ""}
                        onChange={(e) => {
                          // console.log(`option`, e.target.value);
                          setFieldValue("ambito", e.target.value || "");
                          handleOldValue('ambito', e);
                        }}
                      >
                        <option value="">Escoger...</option>
                        {ambitos &&
                          ambitos.map((opcion) => (
                            <option key={opcion} value={opcion}>
                              {opcion}
                            </option>
                          ))}
                      </Form.Control>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"6"}>
                    <Form.Group controlId="categorias">
                      <Form.Label>{t("categorias")}*</Form.Label>
                      <Error
                        touched={touched.categoriasValores}
                        message={errors.categoriasValores}
                      />{" "}
                      <Select
                        isMulti
                        name="categorias"
                        options={categoriasLista}
                        value={values.categoriasValores}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(option) => {
                          setFieldValue(
                            "categoriasValores",
                            option ? [...option] : []
                          );
                          handleOldValue('categoriasValores', option ? {target: { value: [ ...option ]}} : {target: { value: []}})
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={"12"} lg={"12"}>
                    <Form.Group controlId="tags">
                      <Form.Label>{t("tags")}</Form.Label>
                      <Error touched={touched.tags} message={errors.tags} />
                      <Form.Control
                        name="tags"
                        type="text"
                        value={values.tags || ""}
                        onChange={(event) => {
                          handleChange(event);
                          handleOldValue('tags', event)
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <div className={"d-flex justify-content-end"}>
                  <p>
                    Verifica los datos del proveedor que vas a crear en{" "}
                    <a
                      href="https://infocif.es"
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "underline" }}
                    >
                      Infocif
                    </a>
                  </p>
                </div>
                <div className={"d-flex justify-content-end mt-1"}>
                  <Button
                    text={t("cancelar")}
                    className={"btn-secondary mx-1"}
                    loading={isLoading}
                    onClick={
                      doCancel
                        ? () => doCancel()
                        : () => {
                          localStorage.setItem('fullOldValues', JSON.stringify({}));
                          localStorage.setItem('delegationsTemp', JSON.stringify([]));
                          history.push("/full", {
                            return: Number(
                              localStorage.getItem(
                                "jdo-filtro-proveedor-pagina"
                              )
                            ),
                          })
                        }
                    }
                  />
                  <Button
                    text={"Guardar"}
                    className={"btn-primary"}
                    loading={isLoading}
                    type={"submit"}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
};

export default FullEditForm;
