import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "reflexbox";

const RegisterResult = ({ data }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div>
        Comprueba tu correo donde recibirás un mensaje para activar tu cuenta de forma inmediata..
      </div>
      <div style={{ height: "40px" }}></div>
      <table width="100%">
        <tbody>
          <tr>
            <td>{t("nombre")}</td>
            <td>{data.nombre}</td>
          </tr>
          <tr>
            <td>{t("empresa")}</td>
            <td>{data.empresa}</td>
          </tr>
          <tr>
            <td>{t("email")}</td>
            <td>{data.email}</td>
          </tr>
          <tr>
            <td>{t("email_profesional")}</td>
            <td>{data.email_profesional}</td>
          </tr>
          <tr>
            <td>{t("linkedin")}</td>
            <td>{data.linkedin}</td>
          </tr>
        </tbody>
      </table>
      <div style={{ height: "40px" }}></div>
      <Flex justifyContent="center">
        <Box
          px={4}
          py={2}
          my={2}
          bg="danger"
          sx={{
            borderRadius: "5px",
            ":hover": { opacity: "0.8", cursor: "pointer" },
          }}
          onClick={() => history.push("/home")}
        >
          {t("home")}
        </Box>
      </Flex>
    </>
  );
};

export default RegisterResult;
