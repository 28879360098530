import React, { useState, useEffect } from "react";
import * as _ from "lodash";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form } from "react-bootstrap";
import { Button } from "../../components";
import { toast } from "react-toastify";
import Select from "react-select";

import Error from "../../common/Error";

const initial = {
  provincia: "",
  contacto: "",
  telefono: "",
  email: "",
  tel_oficina: "",
};

const validacion = Yup.object().shape({
  contacto: Yup.string().required("obligatorio"),
  telefono: Yup.string().required("obligatorio"),
  email: Yup.string("").email(
    "El correo es incorrecto. No pueden haber espacios al inicio y final"
  ),
});

const DelegacionesFullEditForm = ({ proveedor, item, doSave, isLoading }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [listaProvincias, setListaProvincias] = useState([]);
  const [provinciasDeProveedor, setProvinciasDeProveedor] = useState([]);

  useEffect(() => {
    if (item) {
      item.comunidad = item.comunidad
        ? {
            value: item.comunidad,
            label: item.comunidad,
          }
        : null;
      item.provincia = item.provincia
        ? {
            value: item.provincia,
            label: item.provincia,
          }
        : null;

      setData({ ...initial, ...item });
    }
  }, [item]);

  useEffect(() => {
    let mount = true;

    if (mount) {
      getProvincias();
    }

    return () => {
      mount = false;
    };
  }, []);

  const getProvincias = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/provincias?_limit=-1`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const provinciasProveedores = await res.json();
      setListaProvincias(provinciasProveedores);
      const cadaProvincia = provinciasProveedores
        .map((proveedor) => ({
          label: proveedor.nombre,
          value: proveedor.nombre,
        }))
        .filter((f) => f.value != null && f.value !== "");

      const provinciasOrden = _.sortBy(cadaProvincia, (o) => {
        const label = _.deburr(o.label);
        return label;
      });

      setProvinciasDeProveedor(provinciasOrden);
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  };

  const onSubmit = async (datos) => {
    const newData = await cleanForm(datos);
    doSave(newData);
    history.goBack();
  };

  const cleanForm = (datos) => {
    const newData = { ...datos };
    const delegationsTemp = JSON.parse(localStorage.getItem('delegationsTemp'));
    let delegationsLength = 1;
    if (delegationsTemp !== null) {
      delegationsLength =  delegationsTemp.length + 1;
    }
    if (proveedor === null) {
      newData.proveedor = `dt-${delegationsLength}`
      newData.id = `dt-${delegationsLength}`
    } else {
      newData.proveedor = proveedor.id;
    }
    newData.provincia = datos.provincia.value.toUpperCase() || null;
    newData.comunidad = null;
    const provincia = listaProvincias.find(
      (p) => p.nombre === datos.provincia.value
    );
    if (provincia) {
      newData.comunidad = provincia.comunidad.nombre.toUpperCase();
    }

    return newData;
  };

  // if (!isLoading) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={data || initial}
        validationSchema={validacion}
        onSubmit={onSubmit}
        validateOnMount={false}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({
          setFieldValue,
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={"12"} lg={"6"}>
                  <Form.Group controlId="contacto">
                    <Form.Label>{t("contacto")}*</Form.Label>
                    <Error
                      touched={touched.contacto}
                      message={errors.contacto}
                    />
                    <Form.Control
                      name="contacto"
                      type="text"
                      value={values.contacto || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={"12"} lg={"6"}>
                  <Form.Group controlId="tel_oficina">
                    <Form.Label>{t("Teléfono de la oficina")}</Form.Label>
                    <Error
                      touched={touched.tel_oficina}
                      message={errors.tel_oficina}
                    />
                    <Form.Control
                      name="tel_oficina"
                      type="text"
                      value={values.tel_oficina || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={"12"} lg={"6"}>
                  <Form.Group controlId="provincia">
                    <Form.Label>{t("provincia")}*</Form.Label>
                    <Error
                      touched={touched.provincia}
                      message={errors.provincia}
                    />

                    <Select
                      isClearable
                      value={values.provincia}
                      id="tags"
                      onChange={(option) => {
                        setFieldValue("provincia", option);
                      }}
                      options={provinciasDeProveedor}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={"12"} lg={"6"}>
                  <Form.Group controlId="telefono">
                    <Form.Label>{t("Teléfono de contacto")}*</Form.Label>
                    <Error
                      touched={touched.telefono}
                      message={errors.telefono}
                    />
                    <Form.Control
                      name="telefono"
                      type="text"
                      value={values.telefono || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={"12"} lg={"6"}>
                  <Form.Group controlId="email">
                    <Form.Label>{t("email")}</Form.Label>
                    <Error touched={touched.email} message={errors.email} />
                    <Form.Control
                      name="email"
                      type="text"
                      value={values.email || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <hr />

              <div className={"d-flex justify-content-end mt-1"}>
                <Button
                  text={t("Volver al proveedor")}
                  className={"btn-secondary mx-1"}
                  loading={isLoading}
                  onClick={() => history.goBack()}
                />
                <Button
                  text={"Guardar"}
                  className={"btn-primary"}
                  loading={isLoading}
                  type={"submit"}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
  // }
};

export default DelegacionesFullEditForm;
