import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { getAllCategorias, getProvincias } from "../../../data/api/common";
import { Button, Input, Select } from "../../../components";
import ValoracionScore from "../../../common/ValoracionScore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";


const data = [
  {
    "id": 2734,
    "nombre": "AFRON MAQUINARIA CATALUÑA, S.L.",
    "cif": "B83141192",
    "direccion": "Avda. Can Bordoll, 63, Nave 5",
    "cp": null,
    "email": "barcelona@afronsa.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "937266020",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": "BARCELONA",
    "tipo_proveedor": {
      "id": 1,
      "nombre": "Distribuidor",
      "created_at": "2020-06-10T06:49:02.000Z",
      "updated_at": "2020-06-10T06:49:02.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "NACIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-06-19T23:05:43.933Z",
    "web": "https://www.mateco.es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 343,
    "nombre": "AGGREKO IBERIA",
    "cif": "A62737291",
    "direccion": null,
    "cp": null,
    "email": "albert.mille@aggreko.es",
    "contacto": "Albert Millé",
    "email_contacto": null,
    "tel1": "902221101",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": "BARCELONA",
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "NACIONAL",
    "created_at": "2020-08-06T13:39:17.530Z",
    "updated_at": "2021-04-28T19:36:18.588Z",
    "web": "https://www.aggreko.com/es-es",
    "tags": null,
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3033,
    "nombre": "ALCO GRUPO EMPRESARIAL, S.L.",
    "cif": "B64278757",
    "direccion": "CL/TORN,18-22",
    "cp": null,
    "email": "pgarcia@alcogrupo.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "902474447",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": "BARCELONA",
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "NACIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-08-13T15:46:33.316Z",
    "web": "https://www.alcogrupo.es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": 2.2,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      },
      {
        "id": 333,
        "nombre": "ALQUILER DE MONTACARGAS",
        "created_at": "2021-03-23T12:07:19.068Z",
        "updated_at": "2021-03-23T12:07:19.068Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": [
      {
        "id": 174,
        "capitulo": null,
        "realizacion": null,
        "recepcion": null,
        "aceptacion": "2021-03-23",
        "proveedor": 3033,
        "valoracion": {
          "id": 62,
          "oferta": 174,
          "c01": 2,
          "c02": 2,
          "c03": 2,
          "c04": 2,
          "c05": 2,
          "c06": 2,
          "c07": 2,
          "c08": 3,
          "c09": 1,
          "c10": 3,
          "c11": 2,
          "c12": 2,
          "score": 2.36,
          "created_at": "2021-03-23T23:12:20.284Z",
          "updated_at": "2021-03-23T23:12:20.298Z",
          "created_by": null,
          "updated_by": null,
          "participante": null
        },
        "created_at": "2021-03-23T22:39:39.702Z",
        "updated_at": "2021-05-24T20:06:13.535Z",
        "esValorable": true,
        "created_by": null,
        "updated_by": null
      }
    ]
  },
  {
    "id": 3034,
    "nombre": "ALCO RENTAL SERVICIES SL ",
    "cif": "B66971276",
    "direccion": "CL/TORN,18-22 P.I CAN ROCA PARC 12",
    "cp": null,
    "email": "info@alcogrupo.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "902474447",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Nacional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.alcogrupo.es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3037,
    "nombre": "COEXU, S.A.",
    "cif": "A58112442",
    "direccion": "CL/DEL PLA,4",
    "cp": null,
    "email": "coexu@coexu.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "938456100",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.coexu.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3038,
    "nombre": "COEXU, S.L.",
    "cif": "B58112442",
    "direccion": "CR/DEL PLA,4",
    "cp": null,
    "email": "moicanorent@moicano.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "938456100",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.coexu.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 2726,
    "nombre": "COSTA DEL MARESME RENT, SL",
    "cif": "B64256522",
    "direccion": "CL/EDISON,5-A",
    "cp": null,
    "email": "comercial@costarent.es",
    "contacto": "",
    "email_contacto": null,
    "tel1": "680689361",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 1,
      "nombre": "Distribuidor",
      "created_at": "2020-06-10T06:49:02.000Z",
      "updated_at": "2020-06-10T06:49:02.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.costarent.es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      },
      {
        "id": 154,
        "nombre": "ALQUILER DE ANDAMIO TUBULAR",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2021-03-03T21:18:33.511Z",
        "created_by": null,
        "updated_by": null
      },
      {
        "id": 170,
        "nombre": "CASETAS DE OBRA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      },
      {
        "id": 200,
        "nombre": "FERRETERÍA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      },
      {
        "id": 334,
        "nombre": "ALQUILER DE SANITARIOS QUÍMICOS",
        "created_at": "2021-03-23T12:07:19.068Z",
        "updated_at": "2021-03-23T12:07:19.068Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": [
      {
        "id": 768,
        "capitulo": 235,
        "realizacion": null,
        "recepcion": null,
        "aceptacion": null,
        "proveedor": 2726,
        "valoracion": null,
        "created_at": "2021-06-17T07:27:26.376Z",
        "updated_at": "2021-06-17T07:27:26.392Z",
        "esValorable": false,
        "created_by": null,
        "updated_by": null
      }
    ]
  },
  {
    "id": 2735,
    "nombre": "DINA RENT, SL",
    "cif": "B58932096",
    "direccion": "AV ST JULIA 206 NAU 5 POL IND CONGOST",
    "cp": null,
    "email": "dina-rent@dina-rent.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "938466970",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 1,
      "nombre": "Distribuidor",
      "created_at": "2020-06-10T06:49:02.000Z",
      "updated_at": "2020-06-10T06:49:02.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "REGIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.dina-rent.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 2736,
    "nombre": "DIVISION ALQUILER MAQUINARIA, S.A",
    "cif": "A08992380",
    "direccion": "C/ATLETISMO.Nave E. Pol. Ind. Can Rosés",
    "cp": null,
    "email": "meridam@gmail.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "935880120",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 1,
      "nombre": "Distribuidor",
      "created_at": "2020-06-10T06:49:02.000Z",
      "updated_at": "2020-06-10T06:49:02.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "REGIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.divisionalquilermaquinaria.es/",
    "tags": "ALQUILER DE CARRETILLAS SOLAMENTE.",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3039,
    "nombre": "EDO MAQUINARIA SLU",
    "cif": "B60630738",
    "direccion": "CL/MARIE CURIE,22 - POLIGON SESROVIRES",
    "cp": null,
    "email": "info@edo.cat",
    "contacto": "",
    "email_contacto": null,
    "tel1": "937741354",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "REGIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "http://www.edo.cat/esp/index.cfm",
    "tags": "ALQUILER DE PEQUEÑA MAQUINARIA",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3040,
    "nombre": "ELECTRA MOLINS,SA",
    "cif": "A08283780",
    "direccion": "PG/LES FULLES-CAMÍ CAN UBACH,21",
    "cp": null,
    "email": "em@electramolins.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "932892540",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "REGIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://electramolins.com/",
    "tags": "ALQUILER DE GRUPOS ELECTRÓGENOS",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      },
      {
        "id": 443,
        "nombre": "GRUPOS ELECTRÓGENOS",
        "created_at": "2021-03-23T12:07:19.068Z",
        "updated_at": "2021-03-23T12:07:19.068Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3041,
    "nombre": "EURO-PLATAFORMAS N.T.E., S.L.U.",
    "cif": "B62815071",
    "direccion": "CL/JAUME I,2",
    "cp": null,
    "email": "info@europlataformas.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "937231398",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "REGIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "http://www.europlataformas.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3035,
    "nombre": "GERMANS HOMS LLOGUER DE MAQUINARIA 1852 SL",
    "cif": "B65258188",
    "direccion": "C/JOSEP TRUETA,1-3",
    "cp": null,
    "email": "mheras@homs1852.com",
    "contacto": "Marta Heras",
    "email_contacto": "info@homs1852.com",
    "tel1": "902237239",
    "tel2": "661 30 09 81",
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": "Cabrera de Mar",
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "NACIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-08-10T06:16:04.017Z",
    "web": "https://homs1852.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      },
      {
        "id": 170,
        "nombre": "CASETAS DE OBRA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3043,
    "nombre": "IBERMICAR, S.L.",
    "cif": "B61254959",
    "direccion": "CL/MIQUEL SERVET,20 ",
    "cp": null,
    "email": "bsalvatierra@ibermicar.es",
    "contacto": "",
    "email_contacto": null,
    "tel1": "936626611",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://ibermicar.es/es/",
    "tags": "CARRETILLAS, ",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3044,
    "nombre": "JOY SOLLUCIONS DAVARIES TELESCOPIQUES SL",
    "cif": "B65256224",
    "direccion": "CL/APARTAT DE CORREUS,175",
    "cp": null,
    "email": "comercial@joysat.cat",
    "contacto": "",
    "email_contacto": null,
    "tel1": "902104417",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://joysat.eu/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3045,
    "nombre": "KILOUTOU ESPAÑA, S.A.",
    "cif": "A60383379",
    "direccion": "CL/PLÀ DE MATABOUS,34 P.I. PLA D'EN COLL",
    "cp": null,
    "email": "administracion@kiloutou.es",
    "contacto": "",
    "email_contacto": null,
    "tel1": "935725066",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.kiloutou.es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3046,
    "nombre": "KOMPE ELEVACION, S.L.",
    "cif": "B65155293",
    "direccion": "CRTA. N-II, KM 551,5",
    "cp": null,
    "email": "efren.garcia@gmail.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "902934567",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.kompe.es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3047,
    "nombre": "LIFTISA SL ",
    "cif": "B65629495",
    "direccion": "CL/VERNEDA DEL CONGOST,6 P.I. EL CIRCUIT",
    "cp": null,
    "email": "info@liftisa.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "935686773",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.liftisa.com/",
    "tags": "PLATAFORMAS ELEVADORAS",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 2730,
    "nombre": "LLOGUER DE MAQUINARIA PONS DE VALL, S.L.",
    "cif": "B61084281",
    "direccion": "Apartat de Carreus, 137",
    "cp": null,
    "email": "administracio@ponsdevall.cat",
    "contacto": "",
    "email_contacto": null,
    "tel1": "937597486",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 1,
      "nombre": "Distribuidor",
      "created_at": "2020-06-10T06:49:02.000Z",
      "updated_at": "2020-06-10T06:49:02.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3050,
    "nombre": "MAQUINARIA AUXILIAR DONATO, S.A.",
    "cif": "A58535659",
    "direccion": "CL/DEL VAPOR,38",
    "cp": null,
    "email": "INFO@MAQUINARIADONATO.COM",
    "contacto": "",
    "email_contacto": null,
    "tel1": "935651320",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "http://www.maquinariadonato.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3052,
    "nombre": "MAQUINAS Y MAQUINAS S.L.",
    "cif": "B64534001",
    "direccion": "Pº/VALLDAURA,111",
    "cp": null,
    "email": "eixample@maquinas-maquinas.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "934279091",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.maquinas-maquinas.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 2731,
    "nombre": "MARTI CANUDAS, S.A.",
    "cif": "A08885279",
    "direccion": "CL/APARTAT CORREUS 143",
    "cp": null,
    "email": "info@marticanudas.net",
    "contacto": "",
    "email_contacto": null,
    "tel1": "936530160",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 1,
      "nombre": "Distribuidor",
      "created_at": "2020-06-10T06:49:02.000Z",
      "updated_at": "2020-06-10T06:49:02.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.marticanudas.net/es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3054,
    "nombre": "MAUSA RENT, S.A.U.",
    "cif": "A58494253",
    "direccion": "CL/TIRSO DE MOLINA,2",
    "cp": null,
    "email": "rent@mausa.es",
    "contacto": "",
    "email_contacto": null,
    "tel1": "933774050",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.mausa.es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3056,
    "nombre": "MOICANO RENT, S.L.",
    "cif": "B63210900",
    "direccion": "CL/DEL PLA,4",
    "cp": null,
    "email": "moicanorent@moicano.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "938456100",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.coexu.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3057,
    "nombre": "MONTERO ALQUILER, S.A.",
    "cif": "A48558803",
    "direccion": "CL/MIGUEL SERVET,6-8",
    "cp": null,
    "email": "barcelona@montero.es",
    "contacto": "",
    "email_contacto": null,
    "tel1": "936646980",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.montero.es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3058,
    "nombre": "PLATAFORMAS AEREAS ALTEX, S.L.",
    "cif": "B60595113",
    "direccion": "CL/INDUSTRIA,150-A",
    "cp": null,
    "email": "info@altexsl.es",
    "contacto": "",
    "email_contacto": null,
    "tel1": "902400029",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.altexsl.es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3059,
    "nombre": "PLATAFORMES PENEDES, S.L.",
    "cif": "B64097306",
    "direccion": "AV/TARRAGONA,144-146",
    "cp": null,
    "email": "info@mercaeleva.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "938974725",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.plataformaspenedes.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3060,
    "nombre": "RAMAQ VALLES, S.L.",
    "cif": "B61383444",
    "direccion": "CL/COLON,564",
    "cp": null,
    "email": "INFO@RAMAQ.ES",
    "contacto": "",
    "email_contacto": null,
    "tel1": "937312960",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": "TERRASSA",
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "REGIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-05-17T17:15:29.536Z",
    "web": "https://www.instagram.com/ramaqvalles/?hl=es",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3061,
    "nombre": "RENTEL ALQUILER DE MAQUINARIA, S.L",
    "cif": "B64051147",
    "direccion": "AV/COMTE DE LLOBREGAT,40-42",
    "cp": null,
    "email": "comercial@rentelalquiler.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "902111255",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": "Martorell",
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "REGIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-05-17T17:19:36.379Z",
    "web": "https://www.rentelalquiler.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3063,
    "nombre": "TIMERSA LOGISTICA, S.A.",
    "cif": "A08871642",
    "direccion": "AV/VERGE DE MONTSERRAT,8",
    "cp": null,
    "email": "timersa@timersa.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "934791740",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://timersa.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3031,
    "nombre": "TORRES SERVICIOS TECNICOS SL",
    "cif": "B59951319",
    "direccion": "CL/LLIMONERS,7 NAVE 2",
    "cp": null,
    "email": "info@tstservicios.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "902227222",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.tstservicios.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      },
      {
        "id": 330,
        "nombre": "ALQUILER DE GRUPOS ELECTRÓGENOS INSONORIZADOS",
        "created_at": "2021-03-23T12:07:19.068Z",
        "updated_at": "2021-03-23T12:07:19.068Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 2733,
    "nombre": "UNIC - CATALONIA UNIC, S.L.",
    "cif": "B61506663",
    "direccion": "CL/ALEMANYA 55, P.I. LES COMES",
    "cp": null,
    "email": "nuria@catunic.com",
    "contacto": "Nuria Grau",
    "email_contacto": null,
    "tel1": "938056060",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": "BARCELONA",
    "tipo_proveedor": {
      "id": 1,
      "nombre": "Distribuidor",
      "created_at": "2020-06-10T06:49:02.000Z",
      "updated_at": "2020-06-10T06:49:02.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "REGIONAL",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-06-27T19:28:15.867Z",
    "web": "https://www.catunic.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": 2.5,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      },
      {
        "id": 275,
        "nombre": "PUERTAS METÁLICAS O CORTAFUEGOS",
        "created_at": "2020-12-10T10:53:23.801Z",
        "updated_at": "2021-11-07T21:56:03.745Z",
        "created_by": null,
        "updated_by": null
      },
      {
        "id": 333,
        "nombre": "ALQUILER DE MONTACARGAS",
        "created_at": "2021-03-23T12:07:19.068Z",
        "updated_at": "2021-03-23T12:07:19.068Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": [
      {
        "id": 196,
        "capitulo": null,
        "realizacion": null,
        "recepcion": null,
        "aceptacion": "2021-04-03",
        "proveedor": 2733,
        "valoracion": {
          "id": 67,
          "oferta": 196,
          "c01": 2,
          "c02": 4,
          "c03": 3,
          "c04": 2,
          "c05": 2,
          "c06": 2,
          "c07": 2,
          "c08": 3,
          "c09": 1,
          "c10": 1,
          "c11": 2,
          "c12": 2,
          "score": 2.5,
          "created_at": "2021-04-03T12:31:43.548Z",
          "updated_at": "2021-04-03T12:31:43.562Z",
          "created_by": null,
          "updated_by": null,
          "participante": null
        },
        "created_at": "2021-04-03T12:30:56.992Z",
        "updated_at": "2021-05-24T20:07:27.203Z",
        "esValorable": true,
        "created_by": null,
        "updated_by": null
      }
    ]
  },
  {
    "id": 3065,
    "nombre": "UNIVERSAL PLATAFORMAS AEREAS SL",
    "cif": "B62336375",
    "direccion": "CL/ENERGIA,73 ",
    "cp": null,
    "email": "natalia@universalplataformas.com",
    "contacto": "",
    "email_contacto": null,
    "tel1": "902466000",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://universalplataformas.com/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  },
  {
    "id": 3066,
    "nombre": "UPING ACCES SL",
    "cif": "B65156887",
    "direccion": "cl/TAGOMAGO,29  - POL IND. CAN CANALS",
    "cp": null,
    "email": "cristina.galvan@uping.es",
    "contacto": "",
    "email_contacto": null,
    "tel1": "937480526",
    "tel2": null,
    "comunidad": "CATALUÑA",
    "provincia": "BARCELONA",
    "municipio": null,
    "tipo_proveedor": {
      "id": 3,
      "nombre": "Subcontratista",
      "created_at": "2020-06-10T06:49:27.000Z",
      "updated_at": "2020-06-10T06:49:27.000Z",
      "created_by": null,
      "updated_by": null
    },
    "ambito": "Regional",
    "created_at": "2021-03-23T12:11:02.541Z",
    "updated_at": "2021-03-23T12:11:02.541Z",
    "web": "https://www.uping.es/",
    "tags": "",
    "created_by": null,
    "updated_by": null,
    "score": null,
    "categorias": [
      {
        "id": 150,
        "nombre": "ALQUILER DE MAQUINARIA",
        "created_at": "2020-10-19T15:08:57.944Z",
        "updated_at": "2020-10-19T15:08:57.944Z",
        "created_by": null,
        "updated_by": null
      }
    ],
    "ofertas": []
  }
];

export default () => {
  const params = useParams();
  const [provincias, setProvincias] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [obra, setObra] = useState([]);
  const { register, handleSubmit, control, reset } = useForm();
  const categoriaRef = useRef();

  useEffect(() => {
    async function getSelects() {
      const provinciasList = await getProvincias();
      setProvincias(provinciasList[1]);
      const categoriasList = await getAllCategorias();
      setCategorias(categoriasList);
    }
    getSelects();
  }, []);



  useEffect(() => {
    const id = params.id;
    const getData = async (obra) => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/capitulos?obra=${obra}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          throw Error("Bad request");
        }
        const data = await res.json();
        console.log(data[0].obra);
        setObra(data[0].obra);

      } catch (err) {
        console.log("err :>> ", err);
      }
    };
    getData(id);
  }, []);

  const onSubmit = async () => {
    console.log("onSubmit");
  }

  return (
    <section className="solicitud-oferta-proveedores">
      <Container>
        <Row>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/obras">Obras</a></li>
              <li className="breadcrumb-item"><a href="/ejecucion">Obras en curso</a></li>
              <li className="breadcrumb-item active" aria-current="page">
                Solicitud de Oferta a Proveedores
              </li>
            </ol>
          </nav>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <div className="card">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button className="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne" aria-expanded="false"
                        aria-controls="flush-collapseOne">
                        <h4>{obra['titulo']}</h4>
                      </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <Row>
                          <Col>
                            <h6 className={"text-secondary"}>Constructora</h6>
                            <p>{obra['constructora']}</p>
                          </Col>
                          <Col>
                            <h6 className={"text-secondary"}>Comunidad</h6>
                            <p>{obra['comunidad'] || '-'}</p>
                          </Col>
                          <Col>
                            <h6 className={"text-secondary"}>Provincia</h6>
                            <p>{obra['provincia'] || '-'}</p>
                          </Col>
                          <Col>
                            <h6 className={"text-secondary"}>Municipio</h6>
                            <p>{obra['municipio'] || '-'}</p>
                          </Col>
                          <Col>
                            <h6 className={"text-secondary"}>Año Final</h6>
                            <p>{obra['fecha_prevista_fin'] || '-'}</p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button className="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo" aria-expanded="false"
                        aria-controls="flush-collapseTwo">
                        Criterios de búsqueda
                      </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <div className="">
                          <div className="mt-3">
                            <div className="form-check form-check-inline p-0">
                              <Input
                                type={"checkbox"}
                                name={"type"}
                                value={"calificado"}
                                register={register}
                              />
                              <label className="form-check-label mx-1"
                                htmlFor="inlineCheckbox2">Excluir nacionales fuera de
                                la comunidad</label>
                            </div>
                            <div className="form-check form-check-inline p-0">
                              <Input
                                type={"checkbox"}
                                name={"type"}
                                value={"calificado"}
                                register={register}
                              />
                              <label className="form-check-label mx-1"
                                htmlFor="inlineCheckbox2">Calificado</label>
                            </div>
                            <div className="form-check form-check-inline p-0">
                              <Input
                                type={"checkbox"}
                                name={"type"}
                                value={"conweb"}
                                register={register}
                              />
                              <label className="form-check-label mx-1"
                                htmlFor="inlineCheckbox2">Con
                                web</label>
                            </div>
                            <div className="form-check form-check-inline p-0">
                              <Input
                                type={"checkbox"}
                                name={"type"}
                                value={"subcontratista"}
                                register={register}
                              />
                              <label className="form-check-label mx-1"
                                htmlFor="inlineCheckbox1">Subcontratista</label>
                            </div>
                            <div className="form-check form-check-inline p-0">
                              <Input
                                type={"checkbox"}
                                name={"type"}
                                value={"distribuidor"}
                                register={register}
                              />
                              <label className="form-check-label mx-1"
                                htmlFor="inlineCheckbox2">Distribuidor</label>
                            </div>
                            <div className="form-check form-check-inline p-0">
                              <Input
                                type={"checkbox"}
                                name={"type"}
                                value={"fabricante"}
                                register={register}
                              />
                              <label className="form-check-label mx-1"
                                htmlFor="inlineCheckbox1">Fabricante</label>
                            </div>
                          </div>
                          <div className="mt-3 col-md-12">
                            <Controller
                              name="categoria"
                              control={control}
                              render={({ field }) =>
                                <Select
                                  {...field}
                                  placeholder={"Categorías"}
                                  options={categorias}
                                  isDisabled={categorias.length === 0}
                                  isMulti
                                  ref={categoriaRef}
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item ubicacion">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button className="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree" aria-expanded="false"
                        aria-controls="flush-collapseThree">
                        Ubicación
                      </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        {provincias && provincias.map((item) =>
                          <div className="form-check form-check-inline p-0">
                            <Input
                              type={"checkbox"}
                              name={"provincias"}
                              value={item.id}
                              register={register}
                            />
                            <label className="form-check-label mx-1"
                              htmlFor="inlineCheckbox1">{item.nombre}</label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="card mt-4 p-4">
                <h5>Proveedores disponibles</h5>
                <div className="table-responsive">
                  <Table
                    striped
                    bordered
                    hover
                    size='sm'
                  >
                    <thead>
                      <tr>
                        <th>
                          <Input
                            type={"checkbox"}
                            name={"proveedores"}
                            register={register}
                          />
                        </th>
                        <th>Nombre</th>
                        <th>Ámbito</th>
                        <th>Web</th>
                        <th>Calificación</th>
                        <th>Tipo</th>
                        <th>Categoría</th>
                        <th>Provincia</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((d) => (
                        <tr key={d.id}>
                          <td>
                            <Input
                              type={"checkbox"}
                              name={"proveedores"}
                              value={d.id}
                              register={register}
                            />
                          </td>
                          <td>
                            {d.nombre}
                          </td>
                          <td>{d.ambito}</td>
                          <td>
                            <a href={`${d.web}`} target='_blank' rel="noopener noreferrer">
                              {d.web ? d.web[4] === 's' ? d.web.substr(8) : d.web.substr(7) : ''}
                            </a>
                          </td>
                          <td>
                            <ValoracionScore score={d.score} small={true} />
                          </td>
                          <td>{d.tipo_proveedor && d.tipo_proveedor.nombre}</td>
                          <td>
                            {d.categorias.map((c) => (
                              <div key={c.id} className={"category-item"}>
                                {c.nombre ? c.nombre : '-'}
                              </div>
                            ))}
                          </td>
                          <td>{d.provincia}</td>
                          <td><Link className={''}>
                            <FontAwesomeIcon icon={["fas", "angle-right"]} />
                          </Link></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex mt-3 justify-content-end">
                  <Button
                    text={"Limpiar"}
                    className={"btn-secondary"}
                    icon={{ prefix: "fas", iconName: "eraser" }}
                    mx={"mx-2"}
                    type="button"
                    onClick={() => {
                      reset();
                      categoriaRef.current.select.clearValue();
                    }}
                  />
                  <Button
                    text={"Envíar Solicitud"}
                    className={"btn-primary"}
                    icon={{ prefix: "far", iconName: "paper-plane" }}
                    type={'submit'}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </section>
  );
}
