export const errorReducer = (state, action) => {
  if (action.origin === "error") {
    switch (action.type) {
      case "clearError":
        return "";

      case "SET_ERROR":
        return action.message;

      default:
        return state;
    }
  }
  return state;
};
