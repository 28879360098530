import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Button, ButtonToolbar } from "react-bootstrap";
import { parse } from 'qs';
import Error from "../../common/Error";
const validacion = Yup.object().shape({
  communityToFind: Yup.string().typeError("valor-comunidad").required("obligatorio"),
  community: Yup.string().typeError("valor-comunidad").required("obligatorio"),
});

const NormalizeDataForm = ({ location, doNormalizeData }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState();

  const initial = {
    communityToFind: null,
    community: null,
  };


  const onSubmit = async (data, { setSubmitting }) => {
    setIsLoading(true);
    doNormalizeData(data);
    setIsLoading(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initial}
      validationSchema={validacion}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isValid,
        isSubmiting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='communityToFind'>
            <input
              type='hidden'
              name='code'
              defaultValue={values.code}
            />
            <Form.Label>{t('communityToFind')}</Form.Label>
            <Error touched={touched.communityToFind} message={errors.communityToFind} />
            <Form.Control
              name='communityToFind'
              type='text'
              value={values.communityToFind || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId='community'>
            <Form.Label>{t('community')}</Form.Label>
            <Error
              touched={touched.community}
              message={errors.community}
            />
            <Form.Control
              name='community'
              type='text'
              value={values.community || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Barra />
          <Row>
            <Col>
              <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                <Button
                  variant='primary'
                  type='submit'
                  size='sm'
                  block
                  disabled={isLoading}
                >
                  {t('enviar')}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

const Barra = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #8c8b8b;
`;

export default NormalizeDataForm;
