import React from "react";
import { Box } from "reflexbox";
import { getValoracion } from "../logic/logic";

const Valoracion = ({ oferta }) => (
  <Box
    flex={1}
    sx={{
      display: "inline-block",
      color: "white",
      bg: `${getValoracion(oferta)}`,
      px: 2,
      py: 2,
      borderRadius: 9999,
      textAlign: "center",
    }}
  >
    <h5 style={{ color: "white", marginBottom: 0 }}>
      {(oferta &&
        oferta.valoracion &&
        oferta.valoracion.score &&
        oferta.valoracion.score.toFixed(2)) ||
        "-"}
    </h5>
  </Box>
);

export default Valoracion;
