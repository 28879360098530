export const getOffersByProvider = async ({providerID}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('jdo-userToken');
  try {
    const res = await fetch( `${API_URL}/ofertas/?proveedor=${providerID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status !== 200) {
      throw Error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    throw err;
  }
}

export const updateOfferByID = async ({ID, data}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('jdo-userToken');
  try {
    const res = await fetch( `${API_URL}/ofertas/${ID}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      }
    );
    if (res.status !== 200) {
      throw Error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    throw err;
  }
}

export const getOfferByID = async ({ID}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('jdo-userToken');
  try {
    const res = await fetch( `${API_URL}/ofertas/${ID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      }
    );
    if (res.status !== 200) {
      throw Error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    throw err;
  }
}
