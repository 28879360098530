//After wrapping our App.js component with <StateProvider> component, we need configure an initialState and a way to access and manipulate it.
// mainReducer is passed to the reducer prop in <StateProvider> and is accessed when we use the useStateValue hook.

import { appReducer } from "./appReducer";
import { userReducer } from "./userReducer";
import { errorReducer } from "./errorReducer";

// the router object is passed down through prop drilling. Router -> App -> StateProvider
export const initialState = {
  app: { appName: "wikiobra" },
  user: { logged: false },
  error: "",
};

export const mainReducer = ({ app, user, error }, action) => ({
  app: appReducer(app, action),
  user: userReducer(user, action),
  error: errorReducer(error, action),
});
