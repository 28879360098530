import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

import { Col, Container, Row } from "react-bootstrap";

import Pagination from "../../common/Pagination";
import UsuariosListItem from "./UsuariosListItem";
import {toast} from "react-toastify";

import {useHistory} from "react-router-dom";

import {MainTitle} from "../../common/StyledComponents";

const UsuariosList = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [modal, setModal] = React.useState(false);
    const [datos, setDatos] = useState([]);
    const [count, setCount] = useState(0);
    const [medida, setMedida] = useState(10);
    const [pagina, setPagina] = useState(0);

    useEffect(() => {
        getData("", 0, 10);
        getCount();
    }, []);

    const getData = async (search, page, size) => {
        const token = localStorage.getItem("jdo-userToken");
        try {
            const busca = search ? search : "";
            const start = page * size;
            setPagina(page);
            setMedida(size);

            const res = await fetch(
                `${process.env.REACT_APP_API_URL}/users/search?_start=${start}&_limit=${size}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({busca}),
                }
            );
            if (res.status !== 200) {
                throw Error("Bad request");
            }
            const data = await res.json();
            // setCount(data.total);
            setDatos(data.items);
        } catch (err) {
            console.log("err :>> ", err);
        }
    };

    const getCount = async () => {
        const token = localStorage.getItem("jdo-userToken");
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/users/count`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status !== 200) {
                throw Error("Forbidden");
            }
            const data = await res.text();
            setCount(data);
        } catch (err) {
            toast.error(`${t("login_error")}`);
            history.push("/home");
        }
    };

    const handlePaginateClick = (data) => {
        const {currentPage, pageLimit, totalPages, totalRecords} = data;
        getData("", currentPage, pageLimit);
        getCount();
    };

    const handleChangeUser = async (userId, confirmed) => {
        const token = localStorage.getItem("jdo-userToken");
        setModal(true);

        if (confirmed != true) {
            try {
                const res = await fetch(
                    `${process.env.REACT_APP_API_URL}/users/${userId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "content-type": "application/json",
                        },

                        method: "PUT",
                        body: JSON.stringify({confirmed: true}),
                    }
                );
                if (res.status !== 200) {
                    throw Error("Forbidden");
                }

                getData("", 0, 10);
            } catch (err) {
                toast.error(`${t("error")}`);
            }
        } else {
            try {
                const res = await fetch(
                    `${process.env.REACT_APP_API_URL}/users/${userId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "content-type": "application/json",
                        },
                        method: "PUT",
                        body: JSON.stringify({confirmed: false}),
                    }
                );

                if (res.status !== 200) {
                    throw Error("Forbidden");
                }
                getData("", 0, 10);
            } catch (err) {
                toast.error(`${t("error")}`);
            }
        }
    };

    if (!datos) {
        return <div>Loading...</div>;
    }

    if (datos) {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className={"card p-3"}>
                            <div className={"card-title"}>
                                <div className={"d-flex justify-content-between"}>
                                    <MainTitle>Usuarios</MainTitle>
                                </div>
                            </div>
                            <div className={"card-body pt-0"}>

                                {datos.map((d) => (
                                    <UsuariosListItem
                                        key={d.id}
                                        item={d}
                                        doChangeUser={handleChangeUser}
                                    />
                                ))}

                                <div className={"m-2 d-flex justify-content-end"}>
                                    <Pagination
                                        totalRecords={+count}
                                        pageLimit={medida}
                                        pageNeighbours={2}
                                        onPageChanged={handlePaginateClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default UsuariosList;
