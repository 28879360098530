import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Form } from 'react-bootstrap';
import Button from "../../components/button";

import Error from '../../common/Error';

import IconSearch from "../../icons/IconSearch";

import { SearchBusinessModal } from "../../common/Modals/SearchBusinessModal";

import { toast } from "react-toastify";

import { getEInformaToken } from "../../logic/einforma";

import { searchByName } from '../../services/provider.service';

const initial = {
  nombre: '',
  email_profesional: '',
  email: '',
  role: '',
  empresa: '',
};

const validacion = Yup.object().shape({
  nombre: Yup.string().required('obligatorio'),
  email: Yup.string('')
    .email(
      'El correo es incorrecto. No pueden haber espacios al inicio y final'
    )
    .required('obligatorio'),
  email_profesional: Yup.string('')
    .email(
      'El correo es incorrecto. No pueden haber espacios al inicio y final'
    )
    .required('obligatorio'),
});

const PerfiForm = ({ item, doSave, role }) => {

  const allowRoles = ['constructora', 'proveedor_o_subcontratista', ]

  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [changeFilter, setChangeFilter] = useState("");
  const [datoseInforma, setDatosInforma] = useState([]);

  const handleCloseModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const [data, setData] = useState([]);
  const [listProviders, setListProviders] = useState([]);
  const [listProvidersString, setListProvidersString] = useState([]);

  useEffect(() => {
    if (item) {
      getAll();
    }
  }, [ item ]);

  const getAll = async () => {
    setLoading(true);
    item.role = '' + item.role.id ?? '';
    setChangeFilter(item?.empresa);
    await handleSearchProviderWhileLoading(item?.empresa);
    setData({ ...initial, ...item });
    setLoading(false);
  };

  const onSubmit = async (datos) => {
    setLoading(true);
    const newData = cleanForm(datos);
    await handleSearchProviderWhileLoading(newData.empresa);
    if (listProvidersString?.includes(newData.empresa)) {
      doSave(newData);
    }
    if (!listProvidersString?.includes(newData.empresa)) {
      toast.error(`${t("registro_error")}`);
    }
    setLoading(false);
  };

  const handleSearchProviderWhileLoading = async (needle) => {
    let providers = null;
    const providersByName = await searchByName(needle);
    if (providers === null || providers === undefined || providers.length === 0) {
      providers = providersByName?.filter((r) => {
        return r?.nombre?.includes(needle) ?? r;
      })[0];
      if (typeof providers === 'object' && !Array.isArray(providers)) {
        providers = [providers];
      }
    }
    if (providers === null || providers === undefined || providers.length === 0) {
      if (needle.length > 3) {
        setDatosInforma([]);
        await getEInformaData(needle);
      }
    }
    const listP = [];
    if (providers !== null && providers !== undefined && providers.length > 0) {
      providers?.forEach((d) => listP.push(d?.nombre));
    }
    datoseInforma?.forEach((d) => listP.push(d?.denominacion));
    setListProvidersString(listP);
  };

  const getEInformaData = async (empresa) => {
    const getFromEmpresa = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_API_EINFORMA}/companies?companySearch=${empresa}&rows=20`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${await getEInformaToken()}`,
          },
        }
      );
      const data = await res.json();
      setDatosInforma(data.empresa);
    };

    try {
      getFromEmpresa();
    } catch (error) {
      console.log("error", error);
    }
  };

  const cleanForm = (datos) => {
    const newData = {};

    newData.nombre = datos.nombre;
    newData.email = datos.email;
    newData.email_profesional = datos.email_profesional;
    newData.role = datos.role;
    newData.empresa = changeFilter;

    return newData;
  };

  const handleFilterChange = (event) => {
    const needle = event.target.value.toUpperCase();
    setChangeFilter(needle);
  };

  if (!isLoading) {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={data || initial}
          validationSchema={validacion}
          onSubmit={onSubmit}
          validateOnMount={false}
          validateOnBlur={true}
          validateOnChange={false}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setValues
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <Form.Group controlId='nombre'>
                      <Form.Label>{t('nombre')}*</Form.Label>
                      <Error
                        touched={touched.nombre}
                        message={errors.nombre}
                      />
                      <Form.Control
                        name='nombre'
                        type='text'
                        value={values.nombre || ''}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group controlId='email'>
                      <Form.Label>{t('email')}*</Form.Label>
                      <Error touched={touched.email} message={errors.email} />
                      <Form.Control
                        name='email'
                        type='text'
                        value={values.email || ''}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group controlId='email_profesional'>
                      <Form.Label>{t('email_profesional')}*</Form.Label>
                      <Error
                        touched={touched.email_profesional}
                        message={errors.email_profesional}
                      />
                      <Form.Control
                        name='email_profesional'
                        type='text'
                        value={values.email_profesional || ''}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Col
                      xxl={"9"}
                      xl={"9"}
                      lg={"9"}
                      md={"9"}
                      sm={"9"}
                      xs={"9"}
                    >
                      <Form.Group controlId="empresa">
                        <Form.Label>Empresa (*)</Form.Label>
                        <Error
                          touched={touched.empresa}
                          message={errors.empresa}
                        />
                        <Form.Control
                          type="text"
                          name='empresa'
                          value={changeFilter || ''}
                          onChange={(e) => {
                            handleFilterChange(e)
                            handleChange(e)
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col
                      xxl={"3"}
                      xl={"3"}
                      lg={"3"}
                      md={"3"}
                      sm={"3"}
                      xs={"3"}
                    >
                      <Button
                        variant="primary"
                        style={{ marginLeft: "20px" }}
                        onClick={() => {
                          setShowModal(true);
                        }}
                        text={"Buscar"}
                      >
                        <IconSearch width={"15px"} height={"12px"} />
                        Buscar
                      </Button>
                    </Col>
                  </Col>
                  <Col xs={12}>
                    <Form.Group controlId='role'>
                      <Error
                        touched={touched.role}
                        message={errors.role}
                      />
                      {role.map((rol) => (
                        allowRoles.includes( rol.type ) &&
                        <>
                          <Form.Check
                            key={ rol.id }
                            name="role"
                            type="radio"
                            label={ rol.name }
                            onChange={ handleChange }
                            value={ `${rol.id}` }
                            id={ `role-${rol.id}` }
                            checked={ values.role === `${rol.id}` }
                          />
                        </>)
                      )}
                    </Form.Group>
                  </Col>
                  <Col className={"text-end"}>
                    <Button
                      text={"Guardar"}
                      className={"btn-primary"}
                      type={"submit"}
                      disabled={isLoading || loading}
                      my={"my-3"}
                    />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
        <SearchBusinessModal
          show={showModal}
          doClose={handleCloseModal}
          title={`Title`}
          needle={changeFilter}
          setChangeFilter={setChangeFilter}
          setListProvidersNew={setListProviders}
        />
      </>
    );
  }
};

export default PerfiForm;
