import React from "react";
import { useTranslation } from "react-i18next";
import { MainTitle } from "../common/StyledComponents";
import MisProvedoresList from "../components/misproveedores/MisProveedoresList";
import { Box, Flex } from "reflexbox";

const MisProveedores = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="container">
        <Flex width="100%" alignItems="center">
          <Box flex="1">
            <MainTitle>{t("mis_proveedores")}</MainTitle>
          </Box>
          <Box alignSelf="flex-end"></Box>
        </Flex>
        <MisProvedoresList final={true} />
      </div>
    </>
  );
};

export default MisProveedores;
