import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button } from "../components";
import { MainTitle } from "../common/StyledComponents";


import { useHistory } from "react-router-dom";
import ObrasEstudiosList from "../components/obras-estudios/ObrasEstudiosList";

const MisObrasEstudios = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Container>
      <Row className={"justify-content-center"}>
        <Col xxl={"10"} xl={"10"} lg={"10"} md={"11"} sm={"10"} xs={"11"}>
          <div className={"card p-3"}>
            <div className={"card-title"}>
              <div className={"d-flex justify-content-between"}>
                <MainTitle>{t("mis_obras_estudios")}</MainTitle>
                <Button
                  text={"Nuevo Estudio"}
                  className={"btn-primary"}
                  icon={{ prefix: "fas", iconName: "plus" }}
                  onClick={() => history.push("estudios/new")}
                />
              </div>
            </div>
            <div className={"card-body pt-0"}>
              <ObrasEstudiosList final={true} />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MisObrasEstudios;
