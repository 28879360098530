import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import useAppContext from "../../data/store";
import { Row, Col, Container } from "react-bootstrap";

import RegisterForm from "./components/RegisterForm";
import RegisterResult from "./components/RegisterResult";
import {
  register,
  sendEmailConfirmation,
} from "../../components/user/userService";

import { getRole } from "../../services/roles.service";

const RegisterPage = () => {
  const [{ app }] = useAppContext();
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [roles, setRoles] = React.useState([]);
  const [changeView, setChangeView] = useState(false);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    const roles = await getRole(false);
    setRoles(roles);
  };

  const handelLogin = async (datos) => {
    try {
      const res = await register(datos);
      await sendEmailConfirmation(datos);
      setData(res);
      toast.success(`${t("registro_ok")}`);
    } catch (error) {
      toast.error(`${t("registro_error")}`);
    }
  };

  return (
    <section className={"buscador add-footer panel-home"}>
      <Container style={{ minHeight: "100vh" }}>
        <Row className={"mt-5"}>
          <Col xs={"12"} md={{ span: 10, offset: 1 }}>
            <div
              className="card mb-3"
              style={{ minheight: "80vh", minwidth: "70vw" }}
            >
              <div className="card-body">
                <h2
                  className="card-title text-center logo mb-3"
                  style={{ color: "#1348ad" }}
                >{`${t("register")}`}</h2>
                <div className="card-text">
                  <h2
                    className={"text-center"}
                    style={{ fontSize: "12px", color: "#7e7e80" }}
                  >
                    {changeView
                      ? t("revise los datos proporcionados por Linkedin.")
                      : t("selectTypeOfProfile")}
                  </h2>
                  {data ? (
                    <div>
                      <RegisterResult data={data} />
                    </div>
                  ) : (
                    <Row className={"justify-content-center"}>
                      <Col
                        xxl={"11"}
                        xl={"11"}
                        lg={"11"}
                        md={"11"}
                        sm={"11"}
                        xs={"11"}
                      >
                        <RegisterForm
                          doLogin={handelLogin}
                          roles={roles}
                          changeView={changeView}
                          setChangeView={setChangeView}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RegisterPage;
