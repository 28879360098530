import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import ValoracionScore from "../../common/ValoracionScore";
import {Button} from "../../components";

const ObrasEstudiosOfertasAceptada = ({
  key,
  obra,
  categoria,
  participante,
  proveedor,
  valoracion,
  doDelete,
}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const score = (valoracion && valoracion.score) ? valoracion.score : null;
    return (
        <tr key={key}>
            <td>{proveedor}</td>
            <td>{categoria}</td>
            <td>
                <ValoracionScore
                    score={score}
                    small={true}
                />
            </td>
            <td>
                <div className={"d-flex"}>
                    <Button
                        text={""}
                        className={"btn-outline-primary btn-sm"}
                        icon={{prefix: "fas", iconName: "star"}}
                        mx={"mx-2"}
                        type="button"
                        onClick={() => {
                            history.location.pathname.includes("obras")
                                ? history.push(
                                    `/obras/${obra}/participante/${participante}/valorar/${
                                        valoracion?.id || ""
                                    }`
                                )
                                : history.push(
                                    `/ejecucion/${obra}/participante/${participante}/valorar/${
                                        valoracion?.id || ""
                                    }`
                                )
                        }
                        }
                    />
                    <Button
                        text={""}
                        className={"btn-outline-danger btn-sm"}
                        icon={{prefix: "fas", iconName: "trash"}}
                        onClick={() => doDelete(participante)}
                    />
                </div>
            </td>
        </tr>
    );
};

export default ObrasEstudiosOfertasAceptada;
