import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

const Contacto = () => {

    return (
        <section className={"contact py-5 index-2"}>
            <Container>
                <Row>
                    <Col xs={{ span: 10, offset: 1 }} className={"text-center"}>
                        <h4 className={"light"}>Contáctanos</h4>
                        <p className={"my-4"}>
                            <span>
                                Para cualquier información o consulta, puedes contactarnos directamente a la siguiente dirección de correo electrónico.
                            </span>
                        </p>
                        <h5>wikiobra@wikiobra.com</h5>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Contacto;
