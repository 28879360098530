import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const Error = ({ touched, message }) => {
  const { t } = useTranslation();

  // if (touched && message) {
  //   return (
  //     <ErrorMsg message={true}>
  //       {message !== true ? t(message) : t("obigatorio")}
  //     </ErrorMsg>
  //   );
  // }

    if (message) {
      return (
        <ErrorMsg message={true}>
          {t(message)}
        </ErrorMsg>
      );
    }
  return null;
};

const ErrorMsg = styled.div((props) => ({
  display: props.message ? "block" : "none",
  height: "18px",
  fontSize: "10px",
  backgroundColor: "red",
  color: "white",
  padding: "2px",
  marginBottom: "2px",
  borderRadius: "4px",
}));

export default Error;
