import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form} from "react-bootstrap";

import Button from "../../../components/button";
import Error from "../../../common/Error";

const initial = {
  email: null,
};

const validacion = Yup.object().shape({
  email: Yup.string().email().typeError("valor-email").required("obligatorio"),
});

const LoginForm = ({ doForgot }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState();

  const onSubmit = async (data) => {
    setIsLoading(true);
    doForgot(data);
    setIsLoading(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initial}
      validationSchema={validacion}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='email'>
            <Form.Label>{t('email')}</Form.Label>
            <Error touched={touched.email} message={errors.email} />
            <Form.Control
              name='email'
              type='text'
              value={values.email || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <Row>
            <Col className={"text-end"}>
              <Button
                  text={"Recupera contraseña"}
                  className={"btn-primary"}
                  type={"submit"}
                  disabled={isLoading}
                  my={"my-3"}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
