import React, { useState } from "react";
import { Card } from "react-bootstrap";

import EmpleadosEditForm from "./EmpleadosEditForm";
import { useParams } from "react-router-dom";

const EmployeeEdit = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  React.useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    const token = localStorage.getItem("jdo-userToken");

    if (id) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status !== 200) {
          throw Error("Bad request");
        }
        const data = await res.json();
        setData(data);
      } catch (err) {
        console.log("err :>> ", err);
      }
    }
  };

  return (
    <div className="container">
      <Card style={{ marginTop: "30px", padding: "16px" }}>
        <Card.Title>
          <h1>Colaborador</h1>
        </Card.Title>
        <Card.Body>
          <EmpleadosEditForm item={data} />
        </Card.Body>
      </Card>
    </div>
  );
};


export default EmployeeEdit;
