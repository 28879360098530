import React from "react";

import FullProfesionalDetail from "./FullProfesionalDetail";

const FullProveedorDetails = ({ row }) => {
  return (
    <>
      <table className="table table-striped table-sm">
        <tbody>
          <FullProfesionalDetail name="cif" value={row.cif} />
          <FullProfesionalDetail name="web" value={row.web} type={"link"} />
          <FullProfesionalDetail
            name="email"
            value={row.email}
            type={"email"}
            data={row}
          />
          <FullProfesionalDetail name="contacto" value={row.contacto} />
          <FullProfesionalDetail
            name="email_contacto"
            value={row.email_contacto || "-"}
            type={"email"}
            data={row}
          />
          <FullProfesionalDetail name="tel1" value={row.tel1} type={"tel"} />
          <FullProfesionalDetail name="tel2" value={row.tel2} type={"tel"} />
          <FullProfesionalDetail name="provincia" value={row.provincia} />
          <FullProfesionalDetail name="comunidad" value={row.comunidad} />
          <FullProfesionalDetail name="tipo" value={row.tipo_proveedor} />
        </tbody>
      </table>
    </>
  );
};

export default FullProveedorDetails;
