import React, { useEffect, useState } from "react";

import { Col, Container, Modal, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  getProviderByID,
  updateProvider,
} from "../../services/provider.service";
import { Button } from "../../components";
import styled from "styled-components";

import { Formik } from "formik";
import Error from "../../common/Error";
import Select from "react-select";
import appServices from "../../logic/services";
import { toast } from "react-toastify";
import { NodePlusFill } from "react-bootstrap-icons";

const ChangeScopeModal = (props) => {
  const { show, providerID, doClose, message, typeInternalModal, category } = props;

  const { t } = useTranslation();

  const [internalModal, setInternalModal] = useState(false);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (providerID !== null) {
      handleGetProviderByID(providerID);
    }
  }, [providerID]);

  const handleGetProviderByID = async (ID) => {
    if (typeof ID === "undefined") {
      return;
    }
    const response = await getProviderByID(ID);
    const data = processData(response);
    setProvider(data);
  };

  const processData = (data) => {
    delete data.ofertas;
    delete data.users;
    if (data.categorias) {
      const tm = data.categorias.map((i) => ({
        value: i.id,
        label: i.nombre,
      }));
      data.categoriasValores = [...tm];
      delete data.categorias;
    }
    return data;
  };

  const handleAddCategoryToProvider = () => {
    handleSave(provider);
  }

  const processSaveData = (data) => {
    if (
      data.ambito === null
      || data.ambito === 'null'
      || data.ambito === undefined
      || data.ambito === 'undefined'
      || data.ambito === '') {
      data.ambito = 'REGIONAL';
    } else {
      data.ambito = data?.ambito?.toUpperCase();
    }
    if (data?.categoriasValores) {
      const categorias = data?.categoriasValores?.map((c) => c.value);
      data.categorias = [...categorias, category?.id];
    } else {
      data.categorias = null;
    }
    return data;
  };

  const handleSave = async (data) => {
    const localProvider = processSaveData(data);
    const response = await updateProvider({
      id: providerID,
      data: localProvider,
    });
    if (response?.id !== null) {
      doClose();
    }
  };

  const handleShowButton = () => {
    if (typeInternalModal === 'excludingFilter') {
      return (
        <Button
          text="Aceptar"
          onClick={doClose}
        />
      );
    }
    if (typeInternalModal === 'notHaveCategory') {
      return (
        <Button
          text="Anadir"
          onClick={() => handleAddCategoryToProvider()}
        />
      );
    }
    if (typeInternalModal !== 'excludingFilter') {
      return (
        <Button
          text="Anadir"
          onClick={() => setInternalModal(true)}
        />
      );
    }
  }

  return (
    <Modal show={show} onHide={doClose}>
      <Modal.Header closeButton>Resultados</Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <div className={"card-body pt-0"}>
                <div className={"d-flex justify-content-between"}>
                  <ParagraphStyle>{message}</ParagraphStyle>
                </div>
              </div>
              <div className={"card-footer pt-0"}>
                <div className={"d-flex justify-content-around"}>
                  {handleShowButton()}
                  <Button
                    text="Cancelar"
                    className="btn-danger"
                    onClick={doClose}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <RenderInternalModal
        show={internalModal}
        doClose={setInternalModal}
        providerID={providerID}
        parentDoClose={doClose}
        typeScopeModal={typeInternalModal}
      ></RenderInternalModal>
    </Modal>
  );
};

export default ChangeScopeModal;

export const RenderInternalModal = (props) => {
  const { show, doClose, message, providerID, parentDoClose, typeScopeModal } =
    props;
  const [provider, setProvider] = useState(null);
  const [categoriasLista, setCategoriasLista] = useState([]);
  const ambitos = ["LOCAL", "REGIONAL", "NACIONAL"];
  const { t } = useTranslation();

  const initial = {
    ambito: "",
    categoriasValores: [],
  };

  useEffect(() => {
    if (providerID !== null) {
      handleGetProviderByID(providerID);
      handleGetAllCategoriesForProviders();
    }
  }, [providerID]);

  const handleGetAllCategoriesForProviders = async () => {
    try {
      const categorias = await appServices.getCategorias();
      const opcionesCategorias = categorias.json.map((c) => ({
        value: c.id,
        label: c.nombre,
      }));
      setCategoriasLista(opcionesCategorias);
    } catch (err) {
      toast.error(`${t("error")}`);
    }
  };

  const handleGetProviderByID = async (ID) => {
    if (typeof ID === "undefined") {
      return;
    }
    const response = await getProviderByID(ID);
    const data = processData(response);
    setProvider(data);
  };

  const processData = (data) => {
    delete data.ofertas;
    delete data.users;
    if (data.categorias) {
      const tm = data.categorias.map((i) => ({
        value: i.id,
        label: i.nombre,
      }));
      data.categoriasValores = [...tm];
      delete data.categorias;
    }
    return data;
  };

  const processSaveData = (data) => {
    if (
      data.ambito === null
      || data.ambito === 'null'
      || data.ambito === undefined
      || data.ambito === 'undefined'
      || data.ambito === '') {
      data.ambito = 'REGIONAL';
    } else {
      data.ambito = data?.ambito?.toUpperCase();
    }
    if (data.categoriasValores) {
      const categorias = data.categoriasValores.map((c) => c.value);
      data.categorias = categorias;
    } else {
      data.categorias = null;
    }
    return data;
  };

  const handleSave = async (data) => {
    const localProvider = processSaveData(data);
    const response = await updateProvider({
      id: providerID,
      data: localProvider,
    });
    if (response?.id !== null) {
      doClose();
      parentDoClose();
    }
  };

  return (
    <Modal show={show} onHide={() => doClose(false)}>
      <Formik
        enableReinitialize={true}
        initialValues={provider || initial}
        onSubmit={handleSave}
        validateOnMount={false}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({
          setFieldValue,
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>Cambiar Ambito</Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col>
                      <div className={"card-body pt-0"}>
                        <div className={"d-flex justify-content-center"}>
                          <Row className={"mb-5 justify-content-center"}>
                            {typeScopeModal === "notFound" ?? ""}
                            {typeScopeModal === "notHaveCategory" && (
                              <Col
                                xxl={"12"}
                                xl={"12"}
                                lg={"12"}
                                md={"12"}
                                sm={"12"}
                                xs={"12"}
                              >
                                <Form.Group controlId="categorias">
                                  <Form.Label>{t("categorias")}*</Form.Label>
                                  <Error
                                    touched={touched.categoriasValores}
                                    message={errors.categoriasValores}
                                  />{" "}
                                  <Select
                                    isMulti
                                    name="categorias"
                                    options={categoriasLista}
                                    value={values.categoriasValores}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(option) => {
                                      setFieldValue(
                                        "categoriasValores",
                                        option ? [...option] : []
                                      );
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                            )}
                            {typeScopeModal === "outsideCommunity" && (
                              <Col
                                xxl={"12"}
                                xl={"12"}
                                lg={"12"}
                                md={"12"}
                                sm={"12"}
                                xs={"12"}
                              >
                                <Form.Group controlId="ambito">
                                  <Form.Label>{t("ambito")}*</Form.Label>
                                  <Error
                                    touched={touched.ambito}
                                    message={errors.ambito}
                                  />
                                  <Form.Control
                                    name="ambito"
                                    as="select"
                                    value={values.ambito ? values.ambito : ""}
                                    onChange={(e) => {
                                      // console.log(`option`, e.target.value);
                                      setFieldValue(
                                        "ambito",
                                        e.target.value || ""
                                      );
                                    }}
                                  >
                                    <option value="">Escoger...</option>
                                    {ambitos &&
                                      ambitos.map((opcion) => (
                                        <option key={opcion} value={opcion}>
                                          {opcion}
                                        </option>
                                      ))}
                                  </Form.Control>
                                  <Form.Text className="text-muted"></Form.Text>
                                </Form.Group>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </div>
                      <div className={"card-footer pt-0"}>
                        <div className={"d-flex justify-content-around"}>
                          <Button
                            text="Guardar"
                            onClick={() => handleSubmit()}
                          />
                          <Button
                            text="Cancelar"
                            className="btn-danger"
                            onClick={() => doClose(false)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export const ParagraphStyle = styled.p`
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
`;
