import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as moment from "moment";
import styled from "styled-components";

import { Button } from "../../components";
import DeleteModal from "../../common/DeleteModal";
import TableContent from "../tablas/tables";

const ObrasListItem = ({
  item,
  isActive,
  withListado = false,
  doDelete,
  final,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showInfo, setShowInfo] = useState(false);
  const [show, setShow] = useState(false);

  const handleDelete = () => {
    setShowInfo(false);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleConfirm = () => {
    doDelete(item);

    setShow(false);
  };

  const location = window.location.pathname;

  return (
    <>
      <div className="item-list">
        <div className={"row"}>
          <div
            className={
              "col-12 d-flex justify-content-md-end justify-content-center flex-column flex-md-row"
            }
          >
            <div
              className={"col"}
              onClick={() =>
                history.push(
                  final
                    ? `/obras/view/${item.id}`
                    : `/ejecucion/view/${item.id}`
                )
              }
              style={{ cursor: "pointer" }}
            >
              <Nombre>{item.titulo}</Nombre>
            </div>
            {history.location.pathname.includes("ejecucion") ? (
              <>
                <div>
                  <Button
                    text={t("gestionar_proveedores")}
                    className={"btn-outline-primary btn-sm w-100 mb-2"}
                    icon={{ prefix: "far", iconName: "building" }}
                    onClick={() =>
                      history.push(`/ejecucion/detalles/${item.id}`)
                    }
                  />
                </div>
                <div className={"mx-md-1"}>
                  <Button
                    text={"Enviar solicitdes de oferta"}
                    className={"btn-outline-primary btn-sm w-100 mb-2"}
                    icon={{ prefix: "fas", iconName: "paper-plane" }}
                    onClick={() => history.push(`/ejecucion/add/${item.id}`)}
                  />
                </div>
                <div className={"d-flex justify-content-between"}>
                  <div className={"col-5 mx-md-1"}>
                    <Button
                      text={""}
                      className={"btn-outline-success btn-sm w-100 mb-2"}
                      icon={{ prefix: "far", iconName: "edit" }}
                      onClick={() => history.push(`/ejecucion/edit/${item.id}`)}
                    />
                  </div>
                  <div className={"col-5 mx-md-1"}>
                    <Button
                      text={""}
                      className={"btn-outline-danger btn-sm w-100 mb-2 min-47"}
                      icon={{ prefix: "fas", iconName: "trash" }}
                      onClick={() => handleDelete(item.id)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {location.includes('/obras') ? (
                  <div className={"mx-md-2"}>
                    <TableContent
                      className={"btn-sm w-100 mb-2"}
                      datos={item}
                      obra={item}
                      allDataToUsersDuty={item}
                    />
                  </div>
                ) : ''}
                <div className={"mx-md-2"}>
                  <Button
                    text={t("gestionar_proveedores")}
                    className={"btn-outline-primary btn-sm w-100 mb-2"}
                    icon={{ prefix: "far", iconName: "building" }}
                    onClick={() => history.push(`/obras/detalles/${item.id}`)}
                  />
                </div>
                <div className={"d-flex justify-content-between"}>
                  <div className={"col-5 mx-md-1"}>
                    <Button
                      text={""}
                      className={"btn-outline-success btn-sm w-100 mb-2"}
                      icon={{ prefix: "far", iconName: "edit" }}
                      onClick={() => history.push(`/obras/edit/${item.id}`)}
                    />
                  </div>

                  <div className={"col-5 mx-md-1"}>
                    <Button
                      text={""}
                      className={"btn-outline-danger btn-sm w-100 mb-2 min-47"}
                      icon={{ prefix: "fas", iconName: "trash" }}
                      onClick={() => handleDelete(item.id)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="col-12 col-md">
              <Valor>
                <span className="table-item-title">
                  {item.constructora || "-"}
                </span>
              </Valor>
              <Etiqueta>
                <span className="table-item-subtitle">{t("constructora")}</span>
              </Etiqueta>
            </div>
            <div className="col-12 col-md">
              <Valor>
                <span className="table-item-title">
                  {item.provincia || "-"}
                </span>
              </Valor>
              <Etiqueta>
                <span className="table-item-subtitle">{t("provincia")}</span>
              </Etiqueta>
            </div>
            <div className="col-12 col-md">
              <Valor>
                <span className="table-item-title">
                  {item.municipio || "-"}
                </span>
              </Valor>
              <Etiqueta>
                <span className="table-item-subtitle">{t("municipio")}</span>
              </Etiqueta>
            </div>
            <div className="col-12 col-md">
              <Valor>
                <span className="table-item-title">
                  {(item.fecha_final &&
                    moment(item.fecha_final).format("YYYY")) ||
                    "-"}
                </span>
              </Valor>
              <Etiqueta>
                <span className="table-item-subtitle">{t("año_final")}</span>
              </Etiqueta>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={show}
        body={`${t("estas_seguro_de_borrar")} ${t("obra")}`}
        doClose={handleClose}
        doConfirm={handleConfirm}
      />
    </>
  );
};

const Etiqueta = styled.div`
  font-size: 10px;
  padding: 0;
  color: ${(props) => props.theme.colors.grey};
  margin: 0;
  font-weight: 200 !important;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Valor = styled.div`
  font-size: 16px;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.palegrey}`};
  width: 100%;
  padding: 0;
  margin: 0;
  font-weight: 300 !important;
  color: black;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Nombre = styled.div`
  font-size: 1.3rem;
  font-weight: 900;
  color: ${(props) => props.theme.colors.primary};
  width: 100%;
  padding: 0 0px 10px;
`;

export default ObrasListItem;
