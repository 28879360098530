import React from "react";
import {Box} from "reflexbox";
import {getValoracionScore} from "../logic/logic";

const ValoracionScore = ({score, small = false}) => {
    const value = score ? parseFloat(score).toFixed(2) : "-";
    return <Box
        flex={1}
        sx={{
            display: "inline-block",
            color: "white",
            bg: `${score === null ? "transparent" : score === 0 ? "gray" : getValoracionScore(score)}`,
            px: small ? 3 : 2,
            py: small ? 1 : 2,
            borderRadius: 9999,
            textAlign: "center",
        }}
    >
        {small ? (
            <div style={{color: "white", marginBottom: 0}}>
                {value}
            </div>
        ) : (
            <h5 style={{color: "white", marginBottom: 0}}>
                {value}
            </h5>
        )}
    </Box>
};

export default ValoracionScore;
