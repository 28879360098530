import React from 'react';
import Select from "react-select";

export default React.forwardRef(({
     onChange = null,
     onBlur = null,
     defaultValue = null,
     options,
     placeholder,
     isMulti,
     register,
     name,
     required,
     isDisabled,
 }, ref) =>
    <Select
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
        options={options}
        placeholder={placeholder}
        isMulti={isMulti}
        isDisabled={isDisabled}
    />
);
