import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Box, Flex } from "reflexbox";
import Select from "react-select";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import Switch from "react-switch";

import ObrasCapitulosListItem from "./ObrasCapitulosListItem";
import useAppContext from "../../data/store";

import appServices from "../../logic/services";

import { getWorkById } from "../../services/works.service";

const ObrasCapitulosList = ({
  isLogged,
  final,
  obra,
  setAllDataToUsersDuty,
  handleSetCategory,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const [{ app }, dispatch] = useAppContext();

  const [modal, setModal] = React.useState(false);
  const [datos, setDatos] = useState([]);
  const [auxData, setAuxData] = useState([]);

  const [categorias, setCategorias] = useState([]);
  const [categoria, setCategoria] = useState(null);
  const [changeFilter, setChangeFilter] = useState('');
  const [hiredFilter, setHiredFilter] = useState(false);

  useEffect(() => {
    const getCategorias = async () => {
      try {
        const categorias = await appServices.getCategorias();
        const categoriasTengo = datos.map((d) => d.categoria.id);
        const categoriasFiltradas = categorias.json
          .filter((c) => !categoriasTengo.includes(c.id))
          .map((c) => ({ label: c.nombre, value: c.id }));
        setCategorias(categoriasFiltradas);
      } catch (err) {
        toast.error(`${t("error")}`);
      }
    };
    getCategorias();
  }, [datos]);

  const handleChangeFilter = (event) => {
    const needle = event.target.value;
    setChangeFilter(needle);
    setDatos(auxData);
    if (needle.length > 0) {
      const filtered = auxData.filter((d) =>
        d.categoria.nombre.includes(needle.toUpperCase())
      );
      setDatos(filtered);
    }
  };

  const handleResetChangeFilter = () => {
    setChangeFilter('');
    setDatos(auxData);
  }

  const getData = async (obra) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/capitulos?_sort=created_at:DESC&obra=${obra}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        throw Error("Bad request");
      }
      const data = await res.json();
      setDatos(data);
      setAuxData(data);
    } catch (err) {
      console.log("err :>> ", err);
    }
  };

  useEffect(() => {
    const id = params.id;
    getData(id);
  }, []);

  // ************************** FUNCTIONS
  const toggle = () => setModal(!modal);

  const handleAddCategoria = async () => {
    const newData = { categoria: categoria.value, obra };
    const res = await fetch(`${process.env.REACT_APP_API_URL}/capitulos`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    });
    if (res.status !== 200) {
      throw Error("Bad request");
    }
    const data = await res.json();
    dispatch({
      origin: "app",
      type: "SET_CAPITULO",
      capitulo: data,
    });
    setCategoria(null);
    getData(obra);
  };

  useEffect(() => {
    if (categoria) {
      handleAddCategoria();
    }
  }, [categoria]);

  const handleDelete = async (id) => {
    // borra los filtros de la categoria en la obra
    const filtro = await fetch(
      `${process.env.REACT_APP_API_URL}/filtros?capitulo=${id}&obra=${obra}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    const filtroData = await filtro.json();
    console.log("filtroData :>> ", filtroData);
    await fetch(
      `${process.env.REACT_APP_API_URL}/filtros/${filtroData[0].id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
          "Content-Type": "application/json",
        },
      }
    );

    // borra la categoria
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/capitulos/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (res.status !== 200) {
      throw Error("Bad request");
    }
    dispatch({
      origin: "app",
      type: "SET_CAPITULO",
      capitulo: null,
    });

    setCategoria(null);
    getData(obra);
  };

  const [dutyManager, setDutyManager] = useState();

  const handleGetWork = async () => {
    const workId = obra;
    try {
      const work = await getWorkById(workId);
      const usersCollaborators = work?.colaboradores;
      // este es todo el array con los encargados de la obra en general
      setAllDataToUsersDuty(work);
      const user = work?.user;
      const users = [user, ...usersCollaborators];
      console.log('users: ', users);
      const usersDuty = processLabelsToSelect(users);
      setDutyManager(usersDuty);
    } catch (error) {
      throw error;
    }
  };

  const processLabelsToSelect = (item) => {
    const aux = [];
    let values = item.map((i) => {
      if (!aux.includes(i.id)) {
        aux.push(i.id);
        return { label: i.nombre, value: i.id };
      }
      return undefined;
    });
    values = values.filter((v) => v !== undefined);
    return values;
  };

  useEffect(() => {
    handleGetWork();
  }, []);

  const excludeHired = (value) => {
    if (value) {
      const data = datos.map((d) => ({...d, hired: d.ofertas.length > 0 ? d.ofertas.map((o) => o.contratado !== null ? true : false).includes(true) : false}));
      const dataFiltered = data.filter((d) => d.hired === false);
      setDatos(dataFiltered);
      setHiredFilter(true);
    }

    if (!value) {
      setDatos(auxData);
      setHiredFilter(false);
    }
  };

  // ************************** FUNCTIONS

  if (!datos) {
    return <div>Loading...</div>;
  }
  if (datos) {
    return (
      <div className="item-list">
        <div className={"category-box is-small"}>1. Categorías</div>
        <Select
          placeholder="Añadir categoría a la lista..."
          options={categorias}
          isSearchable={true}
          isClearable={true}
          value={categoria ? categoria : ""}
          onChange={(option) => {
            setCategoria(option);
          }}
        />
        <hr />
        <div style={{ position: "relative" }}>
          <div>
            <Form.Control
              name="filter"
              type="text"
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              value={changeFilter}
              placeholder={t("searchAlreadyInsertedCategory")}
              style={{ borderWidth: "2px", borderColor: "rgb(54 48 48 / 82%)" }}
            />
          </div>
          <div style={{ position: "absolute", top: "10%", left: "87%" }}>
            <X
              width="35px"
              height="35px"
              fill="gray"
              onClick={() => {
                handleResetChangeFilter();
              }}
            />
          </div>
        </div>
        <hr />
        <Box>
          <Flex>
            <label className="w-100 d-flex justify-content-between">
              <span>Filtrar sin contratar</span>
              <Switch
                onChange={() => excludeHired(!hiredFilter)}
                checked={hiredFilter}
                id="normal-switch"
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={40}
              />
            </label>
          </Flex>
        </Box>
        <hr />
        <div>
          <p className={"fw-bold"}>Selecciona una categoría</p>
          {datos.length > 0 && dutyManager && dutyManager.length > 0 ? (
            datos.map((d, i) => (
              <ObrasCapitulosListItem
                key={d.id + "-" + i}
                item={d}
                doDelete={handleDelete}
                usersDuty={dutyManager}
                handleSetCategory={handleSetCategory}
              />
            ))
          ) : (
            <p>Agrega una categoría</p>
          )}
        </div>
      </div>
    );
  }
};

export default ObrasCapitulosList;
