import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button, Card, Modal, Form } from "react-bootstrap";
import { Box, Flex } from "reflexbox";
import EdicionCategoriasListItem from "./EdicionCategoriasListItem";

import { toast } from "react-toastify";
import SearchBar from "../../common/SearchBar";
import { MainTitle } from "../../common/StyledComponents";

const UsuariosList = () => {
  const { t } = useTranslation();
  const [datos, setDatos] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [modalText, setModalText] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getData();
  }, [filtro]);

  const getData = async () => {
    const token = localStorage.getItem("jdo-userToken");
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/categorias?_limit=-1&_sort=nombre:ASC`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status !== 200) {
        throw Error("Bad request");
      }
      const data = await res.json();

      setDatos(data);
      setDatosFiltrados(data);
      if (filtro != "") {
        let filtrados = datos.filter((el) =>
          el.nombre.toLowerCase().includes(filtro.toLowerCase())
        );
        setDatosFiltrados(filtrados);
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };

  // ************************** TABLE PARAMS

  // ************************** FUNCTIONS

  const handleSave = async () => {
    const token = localStorage.getItem("jdo-userToken");

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/categorias?_sort=nombre:ASC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },

          method: "POST",
          body: JSON.stringify({ nombre: modalText }),
        }
      );
      if (res.status !== 200) {
        throw Error("Forbidden");
      }

      getData();
    } catch (err) {
      toast.error(`${t("error")}`);
    }
    setModalText("");
    handleClose();
  };

  const handleGetData = () => {
    getData();
  };

  const handleSearch = (busca) => {
    setFiltro(busca);
  };

  if (!datosFiltrados) {
    return <div>Loading...</div>;
  }

  if (datosFiltrados) {
    return (
    <div className="container">
        <div className="containers">
          <Flex width="100%" alignItems="center">
            <Box flex="1">
              <Flex width="100%" alignItems="center">
                <Box flex="1">
                  <MainTitle>{t("edicion_categorias")}</MainTitle>
                </Box>

                <Button my={20} variant="primary" onClick={handleShow}>
                  {t("nueva_categoria")}
                </Button>
              </Flex>
              <SearchBar doSearch={(busca) => handleSearch(busca)} />

              <Card variant="sombra">
                {datosFiltrados.map((d) => (
                  <EdicionCategoriasListItem
                    key={d.id}
                    item={d}
                    doGetData={handleGetData}
                  />
                ))}
              </Card>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>{t("nueva_categoria")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group controlId="nombre">
                    <Form.Control
                      placeholder={t("nombre_categoria")}
                      name="nombre"
                      type="text"
                      value={modalText || ""}
                      onChange={(event) => setModalText(event.target.value)}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    {t("cerrar")}
                  </Button>
                  <Button variant="primary" onClick={handleSave}>
                    {t("guardar_cambios")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </Box>
          </Flex>
        </div>
      </div>
    );
  }
};

export default UsuariosList;
