export const englishTranslationObject = {
  // Globals

  // Basics
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",

  desde_aqui: "From Here",

  contents: 'Contenido del email para ofertas',
};
