import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import Button from "../../../components/button";

const Registro = () => {
  const history = useHistory();
  return (
    <section className={"registro"}>
      <Container>
        <Row>
          <Col xs={12} className={"text-center"}>
            <span className={"subtitulo"}>Descubre las empresas subcontratistas mejor valoradas por los jefes de obra.</span>
          </Col>
          <Col xs={12} className={"text-center mt-5"}>
            <Button
              text={"Regístrate"}
              className={"btn-primary btn-lg"}
              icon={{ prefix: "fas", iconName: "user-edit" }}
              onClick={() => history.push('/registro')}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Registro;
