import styled from "styled-components";

export const Crud = styled.div`
  margin-top: 30px;
`;
export const Bloque = styled.div`
  @media print {
    display: block;
    break-inside: avoid;
    margin-top: 1cm;
  }
`;
export const CabeceraPagina = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: stretch;
  padding: 10px;
  margin-top: 15px;
`;
export const BuqueZone = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
`;

export const MainTitle = styled.h2`
  color: ${(props) => props.theme.colors.primary};
`;

export const Title = styled.h6`
  color: ${(props) => props.theme.colors.primary};
  margin: 20px 0px;
`;

export const Etiqueta = styled.div`
  font-size: 12px;
  padding: 0px;
  color:  #000;
  margin:0px;
  // font-weight: 200 !important;
`;

export const Valor = styled.div`
  font-size: 16px;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.palegrey}`};
  width: 100%;
  padding: 0px;
  margin: 0px;
  // font-weight: 300 !important;
  color: black;
`;

export const Nombre = styled.div`
  font-size: 24px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.primary};
  padding: 10px 0px;
`;

export const BotonFiltro = styled.button`
  cursor: pointer;
  height: 42px;
  background-color: ${(props) =>
    props.activo ? "transparent" : "transparent"};
  color: ${(props) =>
    props.activo === true
      ? props.theme.colors.danger
      : props.theme.colors.secondary};
  padding: 8px 24px;
  border-radius: 40px;
  border: 1px solid;
  border-color: ${(props) =>
    props.activo === true
      ? props.theme.colors.danger
      : props.theme.colors.secondary}};
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  margin-right: 4px;
  margin-bottom: 4px;
  outline: none;
  &:focus {
    outline: none;
  }
`;
