import React, { createContext, useContext, useReducer } from "react";

const AppContext = createContext();

export const AppProvider = ({ reducer, initialState, children }) => {
  return (
    <AppContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);
export default useAppContext;
