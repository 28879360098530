import React from "react";
import { useHistory } from "react-router-dom";
import ValoracionScore from "../../common/ValoracionScore";
import { Button } from "../../components";

const ObrasOfertasAceptada = ({
  reference,
  obra,
  categoria,
  participante,
  proveedor,
  valoracion,
  doDelete,
}) => {
  const history = useHistory();
  const score = valoracion && valoracion.score ? valoracion.score : null;
  return (
    <tr key={reference}>
      <td>{proveedor}</td>
      <td>{categoria}</td>
      <td>
        <ValoracionScore score={score} small={true} />
      </td>
      <td>
        <div className={"d-flex"}>
          <Button
            text={"Valorar"}
            // icon={{ prefix: "fas", iconName: "star" }}
            className={"btn-outline-primary btn-sm"}
            mx={"mx-2"}
            type="button"
            onClick={() => {
              history.location.pathname.includes("obras")
                ? history.push(
                    `/obras/${obra}/participante/${participante}/valorar/${
                      valoracion?.id || ""
                    }`
                  )
                : history.push(
                    `/ejecucion/${obra}/participante/${participante}/valorar/${
                      valoracion?.id || ""
                    }`
                  );
            }}
          />
          <Button
            text={""}
            className={"btn-outline-danger btn-sm"}
            icon={{ prefix: "fas", iconName: "trash" }}
            onClick={() => doDelete(participante)}
          />
        </div>
      </td>
    </tr>
  );
};

export default ObrasOfertasAceptada;
