import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Box, Flex } from "reflexbox";
import { Formik } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, Modifier, convertToRaw, ContentState } from 'draft-js';
import { getStatusWork } from "../obras/status.work.service";
import { Row, Col, Form } from 'react-bootstrap';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as _ from "lodash";
import Button from "../button";
import draftToHtml from 'draftjs-to-html';
// import Error from "../../common/Error";
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import React, { Component, useState, useEffect } from 'react';
// import Select from "react-select";


class CustomOption extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  addChapterName = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{{ChapterName}}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  addBuilder = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{{Builder}}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  addTitle = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{{Title}}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  addProvince = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{{Province}}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  addAddress = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{{Address}}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  addEpigraphs = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{{Epigraphs}}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  addBlueprints = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{{Blueprints}}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  addManager = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{{Manager}}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  addPhoneManager = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '{{PhoneManager}}',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  render() {
    return (
      <>
        <div onClick={this.addChapterName}>{`{Nombre del capítulo}`}</div>
        &nbsp;
        <div onClick={this.addBuilder}>{`{Constructora}`}</div>
        &nbsp;
        <div onClick={this.addTitle}>{`{Titulo}`}</div>
        &nbsp;
        <div onClick={this.addProvince}>{`{Provincia}`}</div>
        &nbsp;
        <div onClick={this.addAddress}>{`{Dirección}`}</div>
        &nbsp;
        <div onClick={this.addEpigraphs}>{`{Epígrafes}`}</div>
        &nbsp;
        <div onClick={this.addBlueprints}>{`{Planos}`}</div>
        &nbsp;
        <div onClick={this.addManager}>{`{Encargado}`}</div>
        &nbsp;
        <div onClick={this.addPhoneManager}>{`{Teléfono Encargado}`}</div>
      </>
    );
  }
}

const initial = {
  contents: '',
};

const CorreoOfertaForm = ({ doSave, getInfo }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [statusWorkSelect, setStatusWorkSelect] = useState([]);
  const [statusWork, setStatusWork] = useState([]);
  const [content, setContent] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const history = useHistory();

  const initial = {
    contents: '',
  };

  const getWorkState = async () => {
    try {
      const response = await getStatusWork();

      setStatusList(response);

      const eachStatus = response
        .map((status) => ({
          label: status.name,
          value: status.id,
        }))
        .filter((f) => f.value != null && f.value !== "");

      const statusSort = _.sortBy(eachStatus, (o) => {
        const label = _.deburr(o.label);
        return label;
      });

      setStatusWork(statusSort);
    } catch (err) {
      toast.error(`${t("error")}`);
    }

  };

  const onSubmit = (datos) => {
    const newData = cleanForm(datos);
    doSave(newData);
  };

  const cleanForm = (datos) => {
    const newData = {};
    newData.contents = content;
    newData.state_work = statusWorkSelect;
    return newData;
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  useEffect(() => {
    let mount = true;

    if (mount) {
      getWorkState();
    }

    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    handleCallContent();
  }, []);

  const handleCallContent = () => {
    getInfo().then((response) => {
      const { contents } = response;
      const blocksFromHtml = htmlToDraft(contents);
      if (blocksFromHtml) {
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
      item.id = response.id ?? null;
      item.contents = response.contents;
      item.state_work = response.state_work
        ? { label: response.state_work.name, value: response.state_work.id } : '';
    });
  }

  if (!isLoading) {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={item?.id ? item : initial}
          onSubmit={onSubmit}
          validateOnMount={false}
          validateOnBlur={true}
          validateOnChange={false}
        >
          {({
            setFieldValue,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => {
            console.log('values :>> ', values);
            // console.log('errors :>> ', errors);
            // console.log('touched :>> ', touched);
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    {/*
                    <ControlledEditor content={content} setContent={setContent} />
                    */}
                    <Editor
                      editorState={editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={onEditorStateChange}
                      toolbarCustomButtons={[<CustomOption />]}
                    />
                    <Form.Control
                      name='contents'
                      type='hidden'
                      value={values.contents || ''}
                      onChange={handleChange}
                    />
                    {/* end field */}
                    {/*
                    <Row>
                      <Col md={12}>
                      */}
                    {/* field
                        <Row>
                        */}
                    {/* field
                          <Col md={4}>
                            <Form.Group controlId="provincia">
                              <Form.Label>{t("forWorks")}*</Form.Label>
                              <Error
                                touched={touched.state_work}
                                message={errors.state_work}
                              />
                              <Select
                                value={values.state_work}
                                isClearable
                                id="tags"
                                onChange={(option) => {
                                    handleCallContent( option.value );
                                    setStatusWorkSelect( option.value );
                                    setFieldValue("state_work", option);
                                  }
                                }
                                options={statusWork}
                              />
                              <Form.Text className="text-muted"></Form.Text>
                            </Form.Group>
                          </Col>
                            */}
                    {/*
                        </Row>
                        end field */}
                    {/*
                      </Col>
                    </Row>
                      */}
                    {/* end field */}
                    <Flex justifyContent="flex-end">
                      <Box mr={2}>
                        <Button my={"my-3"} text={"Cancelar"} className={"btn-secondary"} onClick={() => history.goBack()} />
                      </Box>
                      <Box mr={2}>
                        <Button
                          text={"Guardar"}
                          className={"btn-primary"}
                          type={"submit"}
                          my={"my-3"}
                        />
                      </Box>
                    </Flex>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default CorreoOfertaForm;
