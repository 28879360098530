import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

const Loading = () => {
  const variants = {
    start: {
      transition: {
        staggerChildren: 0.2,
      },
    },
    end: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const dotVariants = {
    start: {
      y: "0%",
    },
    end: {
      y: "100%",
    },
  };

  const circleTransition = {
    duration: 0.6,
    yoyo: Infinity,
    ease: "easeInOut",
  };

  return (
    <div className={"loading-content"}>
      <Container className={"loading"} variants={variants} initial="start" animate="end">
        <Dot variants={dotVariants} transition={circleTransition} />
        <Dot variants={dotVariants} transition={circleTransition} />
        <Dot variants={dotVariants} transition={circleTransition} />
        <Dot variants={dotVariants} transition={circleTransition} />
        <Dot variants={dotVariants} transition={circleTransition} />
      </Container>
    </div>
  );
};

const Container = styled(motion.div)`
  width: 8rem;
  height: 4rem;
  display: flex;
  justify-content: space-around;
`;

const Dot = styled(motion.span)`
  display: block;
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
`;

export default Loading;
