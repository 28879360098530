import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form } from "react-bootstrap";

import Button from "../../../components/button";
import Error from "../../../common/Error";

const initial = {
  email: null,
  password: null,
};

const validacion = Yup.object().shape({
  email: Yup.string().email().typeError("valor-email").required("obligatorio"),
  password: Yup.string().typeError("valor-texto").required("obligatorio"),
});

const LoginForm = ({ doLogin }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState();

  const onSubmit = async (data) => {
    setIsLoading(true);
    await doLogin(data);
    setIsLoading(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initial}
      validationSchema={validacion}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>{t("email")}</Form.Label>
            <Error touched={touched.email} message={errors.email} />
            <Form.Control
              name="email"
              type="text"
              value={values.email || ""}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>{t("password")}</Form.Label>
            <Error
              touched={touched.email_profesional}
              message={errors.email_profesional}
            />
            <Form.Control
              name="password"
              type="password"
              value={values.password || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Row>
            <Col className={"text-end"}>
              <Button
                  text={"Inicia sesión"}
                  className={"btn-primary"}
                  type={"submit"}
                  disabled={isLoading}
                  my={"my-3"}
              />
            </Col>
          </Row>
          <hr />
        </Form>
      )}
    </Formik>
  );
};


export default LoginForm;
