import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box, Flex } from "reflexbox";

import useAppContext from "../../data/store";
import DeleteModal from "../../common/DeleteModal";
import { getOffersByProvider } from "../../services/offers.service";

const ObrasEstudiosProveedoresSeleccionItem = ({
  item,
  isActive,
  doDelete
}) => {
  const { t } = useTranslation();
  const [{ app }, dispatch] = useAppContext();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [response, setResponse] = useState(0);

  const handleConfirm = () => {
    dispatch({
      origin: "app",
      type: "SET_OFERTA",
      oferta: null,
    });
    setShow(false);
    doDelete(item.id);
  };

  useEffect(() => {
    handleGetOffersByProvider(item.id);

    const competitor = item.participantes;
    const sum = competitor.reduce(
      (s, i) => (s = s + (i.valoracion ? i.valoracion.score : 0)),
      0
    );
    const offersCounts = competitor.filter((i) => i.valoracion).length;
    if (!item.score) {
      item.score = (sum / offersCounts);
    }
    if (isNaN(item.score)) {
      item.score = '';
    }
  }, []);

  const handleGetOffersByProvider = async (ID) => {
    const response = await getOffersByProvider({ providerID: ID });
    let received = 0;
    let done = 0;
    if (response.length > 0) {
      response.forEach((offer) => {
        if (offer?.realizacion) {
          done += 1;
        }
        if (offer?.recepcion) {
          received += 1;
        }
      });
    }
    let percentage = (received / done) * 100;
    if (Number.isNaN(percentage)) {
      percentage = 0;
    }
    setResponse(percentage);
  };

  const handleOferta = () => {
    // dispatch({
    //   origin: "app",
    //   type: "SET_OFERTA",
    //   oferta: item,
    // });
  };

  const processScore = (value) => {
    let color = "";
    if (value >= 0 && value <= 1.99) {
      color = "red";
    } else if (value >= 2 && value <= 2.5) {
      color = "green";
    } else if (value >= 2.51 && value <= 3.0) {
      color = "blue";
    } else if (value >= 3.0 && value <= 4.0) {
      color = "blue";
    }
    return <span style={{ color: color, fontWeight: "bold" }}>{value}</span>;
  };

  const processResponse = (value) => {
    let result = value.toFixed(2);
    return <span style={{ color: "gray", fontWeight: "bold" }}>{result}%</span>;
  };

  return (
    <>
      <div
        style={{
          cursor: "pointer",
          backgroundColor:
            app && app.oferta?.id === item.id ? "#ADD8E6" : "white",
          borderBottom: "1px solid #ADD8E6",
        }}
        // onClick={handleOferta}
      >
        <div className="containers" style={{ backgroundColor: item?.search }}>
          <Flex alignItems="center">
            <Box flex="1" onClick={handleOferta}>
              <div>
                <small style={item?.blocked ? {color: "gray" } : {}}>{(item && item.nombre) || "-"}</small>
                &nbsp;
                <small style={{ color: "red", fontWeight: "bold" }}>{ item?.blocked ? 'Bloqueado por Dep. Compras' : ''}</small>
              </div>
              <div>
                <span style={{ color: "gray", fontSize: "10px" }}>
                  {(item && item?.provincia?.toUpperCase()) || "-"}
                  {" / "}
                  {(item &&
                    item.tipo_proveedor &&
                    item?.tipo_proveedor?.nombre?.toUpperCase()) ||
                    "-"}
                  {" / "}
                  {(item && item?.ambito?.toUpperCase()) || "-"}
                  {" / "}
                  {(item && processScore(item.score)) || "-"}
                  {" / "}
                  {(item && processResponse(response)) || "-"}
                </span>
              </div>
            </Box>
          </Flex>
        </div>
      </div>

      <DeleteModal
        show={show}
        body={`${t("estas_seguro_de_borrar")} ${t("proveedor")}`}
        doClose={handleClose}
        doConfirm={handleConfirm}
      />
    </>
  );
};

const Etiqueta = styled.div`
  font-size: 10px;
  padding: 0px;
  color: ${(props) => props.theme.colors.grey};
  margin: 0px;
  font-weight: 200 !important;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Valor = styled.div`
  font-size: 16px;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.palegrey}`};
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-weight: 300 !important;
  color: black;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Nombre = styled.div`
  font-size: 24px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.primary};
  width: 100%;
  padding: 10px 0px;
`;

export default ObrasEstudiosProveedoresSeleccionItem;
