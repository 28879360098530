export const spanishTranslatorObject = {
  a_obra: 'Añadir a obra...',
  aceptacion: 'Aceptación',
  add_proveedores: 'Empresas participantes',
  add: 'Añadir',
  added_error: 'Error al añadir',
  added_ok: 'Añadido correctamente',
  ambito: 'Ámbito',
  añadir_capitulo: 'Añadir categoría',
  añadir_nuevo: 'Añadir nuevo',
  añadir_proveedor: 'Añadir proveedor',
  añadir: 'Añadir',
  año_final: 'Año final',
  borrar_categoria: 'Borrar categoría',
  borrar: 'Borrar',
  cancalar: 'Cancelar',
  cancelar: 'Cancelar',
  capitulos: 'Seleccionar categoría:',
  categoria: 'Categoria',
  categorias_de_actuacion: 'Categorias de actuación',
  categorias: 'Categorías',
  cerrar: 'Cerrar',
  cif: 'Cif',
  codigo: 'Código',
  comunidad: 'Comunidad',
  confirmado: 'Confirmado',
  confirmar_borrar: 'Confirmar borrar',
  constructora: 'Constructora',
  contacto: 'Contacto',
  contents: 'Contenido del email para ofertas',
  coordinador_seguridad: 'Coordinador seguridad',
  cp: 'Cp',
  delete_ok: 'Borrado correctamente',
  detalles_del_profesional: 'Detalles del profesional',
  detalles_del_proveedor: 'Detalles del proveedor ',
  detalles_obra_en_curso: 'Detalles de obra en curso',
  detalles_obra: 'Detalles de obra',
  detalles: 'Detalles',
  direccion_facultativa: 'Dirección facultativa',
  direccion: 'Dirección',
  director_ejecucion: 'Director ejecución',
  director_obra_1: 'Director obra 1',
  director_obra_2: 'Director obra 2',
  edicion_categorias: 'Edición categorías',
  edicion_patrocinadores: 'Edición de patrocinadores',
  edicion_tipo_proveedores: 'Edición tipo de proveedores',
  editar_categoria: 'Editar categoría',
  editar_obra: 'Editar obra',
  editar: 'Editar',
  ejecucion: 'Ejecución',
  email_contacto: 'Email de empresa',
  email_personal: 'Email personal',
  email_profesional: 'Email profesional',
  email: 'Email de Contacto',
  empresa_coordinacion_ss: 'Empresa coordinación SS',
  empresa_direccion_instalaciones: 'Empresa dirección instalaciones',
  empresa_proveedora: 'Empresa proveedora',
  empresa: 'Empresa',
  enviada_propuesta: 'Enviada propuesta por email en fecha:',
  enviar_oferta: 'Enviar solicitudes',
  enviar: 'Enviar',
  epigrafes_para_ofertas: 'Epigrafes para ofertas',
  error: 'Error',
  escoge_capitulo: 'Seleccionar una categoría para ver los proveedores...',
  escoger_proveedor: 'Seleccionar un proveedor para ver sus detalles...',
  estas_seguro_de_borrar: 'Confirmar borrar ',
  etiquetas: 'Etiquetas',
  fecha_final_obras_curso: 'Fecha limite presentación de oferta provedores',
  fecha_final: 'Fecha final',
  fecha_inicio_obras_curso: 'Fecha presentación de oferta',
  fecha_inicio: 'Fecha inicio',
  fecha_presentacion_de_oferta: 'Fecha presentación de oferta',
  fecha_prevista_fin: 'Fecha prevista de finalización',
  forWorks: 'Para obras',
  gestionar_proveedores: 'Empresas participantes',
  guardar_cambios: 'Guardar cambios',
  home: 'Inicio',
  linkedin: 'LinkedIn',
  listado_proveedores: 'Listado proveedores',
  login_error: 'Error de acceso',
  login: 'Login',
  logout: 'Salir',
  mis_ejecucion: 'Obras en Curso',
  mis_obras_ejecutadas: 'Obras Ejecutadas',
  mis_obras_en_ejecucion: 'Obras en Curso',
  mis_obras_estudios: 'Estudios de Obras',
  mis_obras: 'Obras Ejecutadas',
  mis_proveedores: 'Tus proveedores',
  municipio: 'Municipio',
  no_confirmado: 'No confirmado',
  no_data: 'Aún no hay datos. Añade nuevos registros.',
  no_hay_destinatarios: 'No hay destinatarios',
  nombre_categoria: 'Nombre categoría',
  nombre_tipo_proveedor: 'Nombre tipo de proveedor',
  nombre: 'Nombre',
  not_auth: 'No autenticado',
  nueva_categoria: 'Nueva categoría',
  nueva_estudio_de_obras: 'Nuevo Estudio de Obras',
  nueva_obra: 'Nueva obra',
  nuevo_proveedor: 'Nuevo proveedor',
  nuevo_tipo_proveedor: 'Nuevo tipo de proveedor',
  obigatorio: 'Obligatorio',
  obra: 'Obra',
  ofertas_proveedores: 'Enviar solicitudes de oferta a proveedores',
  password_changed: "Contraseña cambiada. Puedes hacer login",
  password_confirmation: 'Confirmación de contraseña',
  password: 'Contraseña',
  pem: 'PEM',
  planos: 'Planos',
  propiedad: 'Propiedad',
  proveedor_cif_existe: 'Ya existe un proveedor con ese CIF',
  proveedor: 'Proveedor',
  proveedores_para_valorar: 'Empresas participantes',
  proveedores: 'Proveedores',
  provincia: 'Provincia',
  pulsar_para_mas_detalles: 'Pulsa para mas detalles',
  realizada: 'Realizada',
  recibida: 'Recibida',
  registrarse_para_mas_info: 'Registrate para mas información',
  registrate: 'Regístrate en',
  register: 'Registro',
  registro_error: 'Error de registro',
  registro_ok: 'Registrado correctamente',
  seleccionar_capitulos: '1. Seleccionar una categoria o añadir una nueva',
  seleccionar_proveedores: '2. Seleccionar un proveedor o añadir uno nuevo',
  sin_datos: 'Aún no hay datos. Añade nuevos registros.',
  tags: 'Palabras clave',
  tecnico_instalaciones: 'Técnico instalaciones',
  tel1: 'Teléfono Contacto',
  tel2: 'Teléfono 2',
  tipo_proveedor: 'Tipo de proveedor',
  tipo: 'Tipo',
  titulo: 'Título',
  updated_ok: 'Actualizado correctamente',
  users: 'Usuarios',
  valoracion: 'Valoración',
  valorar: 'Valorar',
  visible: 'Visible en listado de obras en curso',
  password_confirmation: 'Confirmación de contraseña',
  password_changed: "Contraseña cambiada. Puedes hacer login",

  contents: 'Contenido del email para ofertas',

  forWorks: 'Para obras',
  worksExecutionList: 'Listado de obras en curso',
  builder: 'Constructora',
  property: 'Propiedad',
  province: 'Provincia',
  projectManagement: 'Dirección Facultativa',
  pemRange: 'PEM (rango de busqueda <1 mil ó > 1mil€)',
  workName: 'Nombre de la obra',
  searchAlreadyInsertedCategory: 'Buscar categoría ya insertada',
  findProvider: 'Buscar proveedor',
  responsible: 'Responsable',
  comment: 'Comentario',
  plusCode: 'Coordenadas Google Maps PLUS CODE',
  web: 'Web',
  selectTypeOfProfile: 'Selecciona tu tipo de perfil',
};
