import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { getRole } from '../../services/roles.service';

import { Row, Col, Container } from 'react-bootstrap';

import PerfiForm from './PerfilForm';
import { useHistory } from 'react-router-dom';

const Perfil = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [me, setMe] = React.useState(null);

  const [role, setRole] = React.useState([]);

  React.useEffect(() => {
    getPerfil();
  }, []);

  const getPerfil = async () => {
    const roles = await getRole(true);
    setRole(roles);
    const URL = process.env.REACT_APP_API_URL;
    const user =
      !_.isNull(localStorage.getItem('jdo-userData')) &&
      JSON.parse(localStorage.getItem('jdo-userData')).id;
    try {
      const res = await fetch(`${URL}/users/${user}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jdo-userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      if (!res.ok) {
        toast.error(`${t('login_error')}`);
      } else {
        const soy_yo = await res.json();
        setMe(soy_yo);
      }
    } catch (error) {
      toast.error(`${t('login_error')}`);
    }
  };

  const handleSave = async (newData) => {
    const user =
      !_.isNull(localStorage.getItem('jdo-userData')) &&
      JSON.parse(localStorage.getItem('jdo-userData'));

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${user.id}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jdo-userToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newData),
        }
      );

      if (res.status !== 200) {
        throw Error('Bad request');
      }
      const new_user = await res.json();
      localStorage.setItem('jdo-userData', JSON.stringify({ ...new_user }));
      toast.info('Operación exitosa');
      history.goBack();
      history.push('/ejecucion');
    } catch (err) {
      toast.error(`${t('error')}`);
    }
  };

  return (
    <section className={"buscador add-footer"}>
      <Container>
        <Row className={"mt-5 justify-content-center"}>
          <Col xxl={"4"} xl={"3"} lg={"4"} md={"6"} sm={"6"} xs={"11"}>
            <div className="card mb-3">
              <div className="card-body">
                <div className="card-text">
                  <h5 className={"text-center"}>Mi perfil</h5>
                  <PerfiForm doSave={handleSave} item={me} role={role} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Perfil;
