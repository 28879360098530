 // call status of work for search

export const getStatusWork = async (search, page, size) => {
  try {
    const res = await fetch(
      `${ process.env.REACT_APP_API_URL }/estado-de-la-obras`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jdo-userToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status !== 200) {
      throw Error("Bad request");
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.log("err :>> ", err);
  }
};
