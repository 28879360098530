import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../components";

const DeleteModal = ({
  title = "Borrar",
  show,
  body,
  doClose,
  doConfirm,
  textBtnClose = "Cancelar",
  textBtnConfirm = "Confirmar",
  colorBtnClose = "btn-outline-secondary",
  colorBtnConfirm = "btn-outline-danger",
  icon = { prefix: "fas", iconName: "trash" },
}) => {
  return (
    <Modal show={show} onHide={doClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button
          text={textBtnClose}
          className={colorBtnClose + " btn-sm w-100 mb-2"}
          onClick={doClose}
        />
        <Button
          text={textBtnConfirm}
          className={colorBtnConfirm + " btn-sm w-100 mb-2 min-47"}
          icon={icon}
          onClick={doConfirm}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
