import React, { useEffect, useState, useRef } from "react";
import { ToastContainer } from "react-toastify";
import Footer from "./Footer";
import NavBar from "./NavBar";
import * as _ from "lodash";
import useAppContext from "../../data/store";

const AppContainer = ({ children }) => {

  const [{ user }] = useAppContext();

  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const logged = !_.isNull(localStorage.getItem('jdo-userToken')) || user.logged;
    setIsLogged(logged);
  }, [user]);

  const headerContainer = useRef();
  const mainContainer = useRef();
  const footerContainer = useRef();

  const [dynamicHeight, setDynamicHeight] = useState();

  useEffect(() => {
    const headerHeight = headerContainer.current.clientHeight;
    const footerHeight = footerContainer.current.clientHeight;
    const parentHeight = footerContainer.current.parentElement.clientHeight;

    const rest = parentHeight - (headerHeight + footerHeight);

    setDynamicHeight(rest);

  }, [mainContainer]);

  return (
    <>
      <header ref={headerContainer}>
        <NavBar />
      </header>
      <div ref={mainContainer} className={`${(isLogged && 'panel-in ')}content`} style={{height: '100%'}}>
        {children}
      </div>
      <footer ref={footerContainer}>
        <Footer />
      </footer>
      <ToastContainer />
    </>
  )
};

export default AppContainer;
