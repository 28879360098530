export const userReducer = (state, action) => {
  if (action.origin === "user") {
    switch (action.type) {
      case "LOGGED":
        return {
          ...state,
          logged: action.isLogged
        };

      case "UPDATE_USER":
        return {
          ...state,
          user: action.user
        };

      case "changeName":
        return {
          ...state,
          username: action.newName
        };

      case "setUser":
        return {
          ...state,
          ...action.setUser
        };
      default:
        return state;
    }
  }
  return state;
};
