import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Button, Input } from "../../components";
import { useForm } from "react-hook-form";

export const ModalSelectFields = (props) => {
  const { show, doClose, doAccept, datos, fullscreen, categoria, title, toShow } =
    props;
  const { register } = useForm();
  const [headers, setHeaders] = useState([]);
  const [toShowHeaders, setToShowHeaders] = useState([]);

  const handleChangeSelection = (item) => {
    let items = headers;
    if (headers.includes(item)) {
      items = headers.filter((header) => header !== item );
    }
    if (!headers.includes(item)) {
      items.push(item);
    }
    setHeaders(items);
  };

  useEffect(() => {
    const items = datos.map((item) => item.group);
    const itemsToShow = toShow.map((item) => item.key);
    setHeaders(items);
    setToShowHeaders(itemsToShow);
  }, [datos]);

  return (
    <Modal show={show} fullscreen="true" onHide={doClose}>
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body className="modal-dialog-scrollable">
        <Container>
          <Row>
            <Col xs={12}>
              <div className={"card p-3"}>
                <div className={"card-body"}>
                  <div>
                    {toShow.length > 0 && headers.length > 0 ? (
                      <>
                        {toShow.map((item) => (
                          <div
                            className="divider-custom"
                            key={item.key}
                            >
                            <Input
                              type={"checkbox"}
                              name={"headers"}
                              register={register}
                              value={item.key}
                              onChange={() => handleChangeSelection(item.group)}
                              checked={toShowHeaders.includes(item.key)}
                            />
                            <label
                              className="form-check-label mx-1"
                              htmlFor="inlineCheckbox1"
                            >
                              {item.label}
                            </label>
                          </div>
                        ))}
                      </>
                    ) : (
                      "No encontrado"
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className={"card-footer pt-0"}>
          <div className={"d-flex justify-content-around"}>
            <Button text="Aceptar" className="btn-primary" onClick={() => doAccept(headers)} />
            <Button text="Cancelar" className="btn-danger" onClick={doClose} />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
