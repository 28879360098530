import * as _ from "lodash";
import {toast} from "react-toastify";


export const getProvincias = async () => {

    try {
        const res = await fetch(
            `${process.env.REACT_APP_API_URL}/provincias?_limit=-1`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        const provinciasProveedores = await res.json();

        const cadaProvincia = provinciasProveedores
            .map((proveedor) => ({
                label: proveedor.nombre,
                value: proveedor.nombre,
            }))
            .filter((f) => f.value != null && f.value !== '');

        const provinciasOrden = _.sortBy(cadaProvincia, (o) => {
            const label = _.deburr(o.label);
            return label;
        });

        return [provinciasOrden, provinciasProveedores];
    } catch (err) {
        toast.error(err);
    }
};

export const getAllCategorias = async () => {
    try {
        const res = await fetch(
            `${process.env.REACT_APP_API_URL}/categorias?_limit=-1`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        const categoriasList = await res.json();
        const categorias = categoriasList
            .map((i) => ({
                label: i.nombre,
                value: i.id,
            }))
            .filter((f) => f.value != null);

        return _.sortBy(categorias, ['label']);
    } catch (err) {
        toast.error(err);
    }
};

export const getListAllCategoriesForSelect = async ({limit = 20, start = 0}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const res = await fetch(
      `${ API_URL }/categorias?_limit=${limit}&_start=${start}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status !== 200) {
      throw Error("Bad request");
    }
    const categoriasList = await res.json();
    const categorias = categoriasList
        .map((i) => ({
            label: i.nombre,
            value: i.id,
        }))
        .filter((f) => f.value != null);

    return _.sortBy(categorias, ['label']);
  } catch (err) {
    console.log("err :>> ", err);
  }
};
