import React from 'react';
import Contacto from "./components/Contacto";
import { Col, Container, Row } from "react-bootstrap";


const AboutUs = () => {

  return (
    <>
      <section className={"about-us page-section"}>
        <Container>
          <Row>
            <Col xs={"12"} className={"text-center my-5"}>
              <h4>Quiénes somos</h4>
              <h5 className={"my-2"}>Encuentra a tu proveedor ideal en nuestra web</h5>
            </Col>
            <Col xs={"12"} className={"mb-5"}>
              <Row className={"mb-5"}>

                <Col xs={"12"} md={{ span: 8, offset: 2 }} className={"align-self-center"}>
                  <p>
                    Somos dos jefes de obra con décadas de experiencia en la construcción y contratación de subcontratistas  
                    y proveedores para cada fase de nuestras obras. Con frecuencia, nos enfrentamos a un proceso tedioso y  
                    largo al buscar nuevas empresas, generalmente optando por aquellas con las que ya hemos trabajado o que nos  
                    recomendaron colegas, pero siempre son necesarias más opciones.
                  </p>
                  <p>
                    De ahí surgió la idea de calificar y evaluar las empresas con las que colaboramos, creando un registro 
                    con valoraciones reales que sirviera como referencia para futuras contrataciones. Durante mucho tiempo, 
                    hemos construido y actualizado esta base de datos, incorporando nuevas empresas cada año.
                  </p>
                  <p>
                    La pandemia nos brindó la oportunidad de utilizar toda esa información acumulada y convertirla en una herramienta  
                    útil. Decidimos crear un espacio colaborativo donde otros jefes de obra pudieran contribuir con sus 
                    valoraciones. Un lugar donde los usuarios pudieran agregar también sus valoraciones creando una gran red 
                    en toda España donde puedas ver las empresas de tu zona valoradas por los técnicos de obra que realmente las conocen.
                  </p>
                </Col>
              </Row>
            </Col>

          </Row>
        </Container>
      </section>
      <Contacto />
    </>
  );
};

export default AboutUs;
