import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FullProfesionalDetail from './FullProfesionalDetail';
import Loading from '../../common/Loading';
import { toast } from 'react-toastify';
import DelegacionesFullList from '../delegaciones/DelegacionesFullList';
import { Col, Container, Row } from "react-bootstrap";
import { MainTitle } from "../../common/StyledComponents";
import { searchBlockedProviders, updatedBlockedProviders, createdBlockedProviders } from "../../services/blocked.providers.service";
import { Button } from "../../components";


const FullDetails = ({ row }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [proveedorId, setProveedorId] = useState(null);
  const [listBlockedProviders, setListBlockedProviders] = useState([]);
  const [role, setRole] = useState(null);

  const handleLoadBlockedProviders = async () => {
    const jdoUserData = JSON.parse(localStorage.getItem('jdo-userData'));
    const { empresa } = jdoUserData;
    const blockedProviders = await searchBlockedProviders(empresa);
    const list = blockedProviders?.[0]?.proveedors?.map((p) => {
      return `${p.id}`;
    });
    setListBlockedProviders(list);
  }

  useEffect(() => {
    const { id } = params;

    setProveedorId(id);
    //
    const getData = async (id) => {
      setIsLoading(true);
      const token = localStorage.getItem('jdo-userToken');
      let headers =  {
        "Content-Type": "application/json",
      };

      if (token !== undefined && token !== null) {
        headers =  {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/proveedors/${id}`,
          {
            method: 'GET',
            headers,
          }
        );
        if (res.status !== 200) {
          throw Error('Bad request');
        }
        const data = await res.json();
        delete data.ofertas;
        delete data.users;
        if (data.categorias) {
          const tm = data.categorias.map((i) => ({
            value: i.id,
            label: i.nombre,
          }));
          data.categoriasValores = [...tm];
          delete data.categorias;
        }

        setData(data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error(`${t('error')}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      getData(id);
      handleLoadBlockedProviders();
    }
  }, []);

  const handleBlockProvider = async () => {
    const { id } = params;
    if (listBlockedProviders?.includes(`${id}`)) {
      return;
    }
    const jdoUserData = JSON.parse(localStorage.getItem('jdo-userData'));
    const { empresa } = jdoUserData;
    const blockedProviders = await searchBlockedProviders(empresa);
    const list = blockedProviders?.[0]?.proveedors?.map((p) => {
      return `${p.id}`;
    });

    if (
      blockedProviders !== null &&
      blockedProviders !== undefined &&
      blockedProviders.length > 0
      ) {
      const blockedId = blockedProviders?.[0]?.id;
      list.push(`${id}`);
      const data = {
        ...blockedProviders?.[0],
        proveedors: list
      };
      await updatedBlockedProviders(blockedId, data);
    }

    if (
      blockedProviders === null ||
      blockedProviders === undefined ||
      blockedProviders.length === 0) {
      const data = { business: empresa, proveedors: [`${id}`]};
      await createdBlockedProviders(data);
    }
    window.location.reload();
  }

  const handleUnblockedProvider = async () => {
    const { id } = params;
    if (!listBlockedProviders.includes(`${id}`)) {
      return;
    }
    const jdoUserData = JSON.parse(localStorage.getItem('jdo-userData'));
    const { empresa } = jdoUserData;
    const blockedProviders = await searchBlockedProviders(empresa);
    const list = blockedProviders?.[0]?.proveedors?.map((p) => {
      return `${p.id}`;
    });

    if (
      blockedProviders !== null &&
      blockedProviders !== undefined &&
      blockedProviders.length > 0
      ) {
      const blockedId = blockedProviders?.[0]?.id;
      const providers = list?.filter((p) => p !== id);
      const data = {
        ...blockedProviders?.[0],
        proveedors: providers
      };
      await updatedBlockedProviders(blockedId, data);
    }
    window.location.reload();
  }

  const allowRoles = ["jefe_de_compras"];

  useEffect(() => {
    const jdoUserData = JSON.parse(localStorage.getItem('jdo-userData'));
    const role = jdoUserData?.role?.type;
    setRole(role)
  }, [proveedorId]);

  if (!data) {
    return <Loading />;
  } else {
    const { id } = params;
    return (
      <Container>
        <Row>
          <Col xs={"12"}>
            <div className={"card p-3"}>
              <div className={"card-title"}>
                <div className={"d-flex justify-content-between"}>
                  <MainTitle>Detalle del proveedor</MainTitle>
                </div>
              </div>
              <div className={"card-body pt-0"}>
                <Row>
                  <Col xs={"12"} md={"8"}>
                    <div className="item-list">
                      <div className={"category-box is-medium"}>
                        {data.nombre}
                      </div>
                      <table className="table table-striped">
                        <tbody>
                          <FullProfesionalDetail
                            name='cif'
                            value={data.cif}
                          />
                          <FullProfesionalDetail
                            name='email'
                            value={data.email}
                            type={"email"}
                          />
                          <FullProfesionalDetail
                            name='contacto'
                            value={data.contacto}
                          />
                          <FullProfesionalDetail
                            name='email_contacto'
                            value={data.email_contacto}
                            type={"email"}
                          />
                          <FullProfesionalDetail
                            name='tel1'
                            value={data.tel1}
                            type={"tel"}
                          />
                          <FullProfesionalDetail
                            name='tel2'
                            value={data.tel2}
                            type={"tel"}
                          />
                          <FullProfesionalDetail
                            name='direccion'
                            value={data.direccion}
                          />
                          <FullProfesionalDetail
                            name='cp'
                            value={data.cp}
                          />
                          <FullProfesionalDetail
                            name='municipio'
                            value={data.municipio}
                          />
                          <FullProfesionalDetail
                            name='provincia'
                            value={data.provincia}
                          />
                          <FullProfesionalDetail
                            name='comunidad'
                            value={data.comunidad}
                          />
                          <FullProfesionalDetail
                            name='web'
                            value={data.web}
                            type={"link"}
                          />
                          <FullProfesionalDetail
                            name='ambito'
                            value={data.ambito}
                          />
                          <FullProfesionalDetail
                            name='valoracion'
                            value={data.score}
                            type={"score"}
                          />
                          <FullProfesionalDetail
                            name='tipo'
                            value={data.tipo_proveedor && data.tipo_proveedor.nombre}
                          />
                          <FullProfesionalDetail
                            name='etiquetas'
                            value={data.tags}
                          />
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Col xs={"12"} md={"4"}>
                    <div className="item-list">
                      <div className={"category-box"}>
                        {t('categorias_de_actuacion')}
                      </div>
                      {data.categoriasValores.map((c, index) => (
                        <p key={index}>{c.label ? c.label : '-'}</p>
                      ))}
                      {allowRoles.includes(role) && (
                        <>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            marginBottom: '1rem',
                          }}>
                            <Button
                              text={listBlockedProviders?.includes(`${id}`) ? 'Bloqueado' : 'Bloquear'}
                              className={listBlockedProviders?.includes(`${id}`) ? "btn-danger width-100 radius-2": "btn-danger width-100 radius-2 margin-left-0"}
                              onClick={() => handleBlockProvider()}
                              styleCon={{
                                width: '10rem',
                              }}

                            />
                          </div>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                          }}>
                            <Button
                              text={'Desbloquear'}
                              className={listBlockedProviders?.includes(`${id}`) ? "btn-success width-100 radius-2": "btn-secondary width-100 radius-2"}
                              onClick={() => handleUnblockedProvider()}
                              styleCon={{
                                width: '10rem',
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>

          {proveedorId && (
            <Col xs={"12"} className={"mt-2"}>
              <DelegacionesFullList proveedor_id={proveedorId} />
            </Col>
          )}

        </Row>
      </Container>
    );
  }
};

export default FullDetails;
