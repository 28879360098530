export const appReducer = (state, action) => {
  if (action.origin === "app") {
    switch (action.type) {
      case "TOGGLE_APPNAME":
        return {
          ...state,
          appName: action.name,
        };
      case "SET_OBRA":
        return {
          ...state,
          obra: action.obra,
        };
      case "SET_CAPITULO":
        return {
          ...state,
          capitulo: action.capitulo,
        };
      case "SET_OFERTA":
        return {
          ...state,
          oferta: action.oferta,
        };
      default:
        return state;
    }
  }
  return state;
};
