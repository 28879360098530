import React from "react";
import { AppProvider } from "./data/store";
import { initialState, mainReducer } from "./data/reducers";
import { theme } from "./theme";

import Routes from "./Routes";
import { ThemeProvider } from "styled-components";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider initialState={{ ...initialState }} reducer={mainReducer}>
        <Routes />
      </AppProvider>
    </ThemeProvider>
  );
};

export default App;
